import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

// Slices
import {
  toggleModal,
  selectContentModal,
} from "../../../../redux/modal/modal.slice";
import { selectMedecinInfos } from "../../../../redux/utilisateur/utilisateur.slice";

// Components
import EditButton from "../../../atoms/buttons/EditButton";
import TitleIcon from "../../../atoms/text/TitleIcon";
import FormSignature from "../../FormSignature";

const Section = styled("div")`
  margin-bottom: 24px;
  padding: 24px 7px 24px 49px;
  border-bottom: 1px solid #f4f4f4;
  &:last-child {
    border: none;
  }
`;

const HeaderSection = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const NormalText = styled("p")`
  font-size: 1.4rem;
  font-weight: 500;
  color: #3a3d4b;
`;

const ProfilItemInfos = () => {
  const dispatch = useDispatch();
  const medecin = useSelector(selectMedecinInfos);
  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);

  const handleClick = () => {
    dispatch(toggleModal());
    setContentModal(<FormSignature medecin={medecin} />);
  };

  return (
    <Section>
      <HeaderSection>
        <TitleIcon title="Signature" icon="signature" />
        <EditButton label="Modifier" handleClick={handleClick} />
      </HeaderSection>
      <NormalText>
        {medecin?.sSignature
          ? "Votre signature est enregistrée, vous pouvez valider vos renouvellements"
          : "Votre signature n'est pas enregistrée, vous ne pouvez pas valider vos renouvellements"}
      </NormalText>
    </Section>
  );
};

export default ProfilItemInfos;
