import { Checkbox, Row, DatePicker } from "antd";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import moment from "moment";
import { ArrowRightOutlined } from "@ant-design/icons";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

import {
  CheckboxComplete,
  CheckboxCompleteDate,
  CheckboxCompleteInput,
  CheckboxCompleteLabel,
  CheckboxGroup,
  CheckboxGroupRow,
  DEPStyledFormItem,
  DEPStyledFormItemWithoutHeight,
  DEPStyledFormItemWithoutWidth,
  DEPStyledFormItemWithoutWidthC,
  DefaultInput,
  StyledSpan,
} from "../../elements/DEPinput.elements";
import { Title, Wrapper } from "../../elements/sections.elements";
import { Indication } from "../../elements/input.elements";
import type IDEP from "../../../../interfaces/dep.interface";
import { IPrescriptionRenouvellement } from "../../../../interfaces/prescription.interface";

const Body = styled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-areas:
    "assure malade"
    "sign sign";
  grid-template-columns: 1fr 1fr;
`;

const GridInput = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
`;

const NoInput = styled(DefaultInput)`
  margin-right: 2px;
`;

const NoSecuInput = styled.div`
  display: grid;
  grid-template-columns: 150px auto 30px;
  height: 22px;
  margin: 0;
  padding-bottom: 1px;
`;

const Label = styled.label`
  font-size: 12px;
`;

const LargeInput = styled(DefaultInput)`
  grid-column: span 2;
`;

const NomInput = styled(DefaultInput)`
  grid-row: span 2;
  height: 22px;
`;

const NomPatronymique = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  padding-bottom: 1px;
`;

const Section = styled.div`
  border-radius: 12px;
  background-color: lightgray;
  padding: 2px;
`;

const SectionAssure = styled(Section)`
  grid-area: assure;
`;

const SectionMalade = styled(Section)`
  grid-area: malade;
`;

const SectionSignature = styled(Section)`
  display: flex;
  grid-area: sign;
`;

const SectionSignatureLeft = styled.div`
  margin-right: 2px;
  width: 50%;
`;

const SectionSignatureRight = styled.div`
  width: 50%;
`;

const Signature = styled.div`
  align-items: center;
  display: flex;
`;

interface AssureSectionProps {
  justificatif?: IDEP;
  onChange: (e: ChangeEvent) => void;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}

type ChangeEvent = CheckboxChangeEvent | React.ChangeEvent<HTMLInputElement>;

export default function AssureSection({
  onChange,
  prescription,
  setPrescription,
  justificatif,
}: AssureSectionProps) {
  const newPrescription = prescription;
  return (
    <Wrapper>
      <Body>
        <SectionAssure>
          <Title>Renseignements concernant l'assuré(e)</Title>

          <NoSecuInput>
            <Label htmlFor="sNoSecuriteSociale">No D’IMMATRICULATION :</Label>

            <NoInput
              type={"number"}
              maxLength={15}
              value={justificatif?.sNoSecuriteSociale}
              name="sNoSecuriteSociale"
              onChange={onChange}
            />

            <DefaultInput
              value={justificatif?.sCleSecuriteSociale}
              type={"number"}
              maxLength={2}
              name="sCleSecuriteSociale"
              onChange={onChange}
            />
          </NoSecuInput>

          <NomPatronymique>
            <Label htmlFor="sNomAssure">NOM PATRONYMIQUE :</Label>

            <NomInput
              value={justificatif?.sNomAssure}
              name="sNomAssure"
              onChange={onChange}
            />

            <Indication>(Suivi, s'il y a lieu, du nom d'usage)</Indication>
          </NomPatronymique>

          <DEPStyledFormItem label="PRÉNOM">
            <DefaultInput
              value={justificatif?.sPrenomAssure}
              name="sPrenomAssure"
              onChange={onChange}
            />
          </DEPStyledFormItem>

          <GridInput>
            <Label htmlFor="sAdresseAssure">ADRESSE :</Label>

            <DefaultInput
              value={justificatif?.sAdresseAssureRue}
              name="sAdresseAssureRue"
              onChange={onChange}
            />

            <LargeInput
              value={justificatif?.sAdresseAssureCPVille}
              name="sAdresseAssureCPVille"
              onChange={onChange}
            />
          </GridInput>

          <GridInput>
            <Label htmlFor="sRO">ORGANISME D’AFFILIATION :</Label>

            <DefaultInput
              value={justificatif?.sNomRO}
              name="sNomRO"
              onChange={onChange}
            />

            <LargeInput
              value={justificatif?.sAdresseRO}
              name="sAdresseRO"
              onChange={onChange}
              maxLength={60}
            />
          </GridInput>

          <Title>Situation de l'assuré(e) à la date de la demande</Title>

          <CheckboxGroup>
            <Checkbox
              checked={justificatif?.sCocheSituationSalarie === "X"}
              name="sCocheSituationSalarie"
              onChange={onChange}
            >
              ACTIVITÉ SALARIÉE OU ARRÊT DE TRAVAIL
            </Checkbox>

            <Checkbox
              checked={justificatif?.sCocheSituationNonSalarie === "X"}
              name="sCocheSituationNonSalarie"
              onChange={onChange}
            >
              ACTIVITÉ NON SALARIÉE
            </Checkbox>

            <CheckboxComplete>
              <Checkbox
                checked={justificatif?.sCocheSituationSans === "X"}
                name="sCocheSituationSans"
                onChange={onChange}
              />
              <CheckboxCompleteLabel>SANS EMPLOI</CheckboxCompleteLabel>{" "}
              <ArrowRightOutlined />
              <CheckboxCompleteDate>
                Date de cessation d'activité
              </CheckboxCompleteDate>
              <DatePicker
                value={
                  justificatif?.sDateSituationAssure
                    ? moment(justificatif?.sDateSituationAssure)
                    : undefined
                }
                placeholder="jj/mm/aaaa"
                name="sDateSituationAssure"
                format="DD/MM/YYYY"
                locale={locale}
                onChange={(momentDate) => {
                  if (momentDate) {
                    newPrescription!.oJustificatifs!.dep!.sDateSituationAssure =
                      momentDate?.format("YYYY-MM-DD");
                    setPrescription({ ...newPrescription, bUpdated: true });
                  } else {
                    newPrescription!.oJustificatifs!.dep!.sDateSituationAssure =
                      "";
                    setPrescription({ ...newPrescription, bUpdated: true });
                  }
                }}
              />
            </CheckboxComplete>

            <Checkbox
              checked={justificatif?.sCocheSituationPension === "X"}
              name="sCocheSituationPension"
              onChange={onChange}
            >
              PENSIONNÉ(E)
            </Checkbox>

            <CheckboxComplete>
              <Checkbox
                checked={justificatif?.sCocheSituationAutre === "X"}
                name="sCocheSituationAutre"
                onChange={onChange}
              />

              <CheckboxCompleteLabel>AUTRE CAS, lequel</CheckboxCompleteLabel>

              <CheckboxCompleteInput
                value={justificatif?.sSituationAutre}
                name="sSituationAutre"
                onChange={onChange}
              />
            </CheckboxComplete>
          </CheckboxGroup>
        </SectionAssure>

        <SectionMalade>
          <Title>Renseignements concernant le malade</Title>

          <Row>
            <DEPStyledFormItemWithoutWidthC label="S'agit-il d’un accident ?">
              <Checkbox
                checked={justificatif?.sCocheAccidentOui === "X"}
                name="sCocheAccidentOui"
                onChange={onChange}
              >
                OUI
              </Checkbox>

              <Checkbox
                checked={justificatif?.sCocheAccidentNon === "X"}
                name="sCocheAccidentNon"
                onChange={onChange}
              >
                NON
              </Checkbox>
            </DEPStyledFormItemWithoutWidthC>

            <DEPStyledFormItemWithoutWidth label="Date de cet accident">
              <DatePicker
                value={
                  justificatif?.sDateAccident
                    ? moment(justificatif?.sDateAccident)
                    : undefined
                }
                placeholder="jj/mm/aaaa"
                name="sDateAccident"
                format="DD/MM/YYYY"
                locale={locale}
                onChange={(momentDate) => {
                  if (momentDate) {
                    newPrescription!.oJustificatifs!.dep!.sDateAccident =
                      momentDate?.format("YYYY-MM-DD");
                    setPrescription({ ...newPrescription, bUpdated: true });
                  } else {
                    newPrescription!.oJustificatifs!.dep!.sDateAccident = "";
                    setPrescription({ ...newPrescription, bUpdated: true });
                  }
                }}
              />
            </DEPStyledFormItemWithoutWidth>
          </Row>

          <StyledSpan>
            Si le malade est PENSIONNÉ(E) DE GUERRE et si la demande concerne
            l’affection pour laquelle il est pensionné, cocher cette case
          </StyledSpan>

          <Checkbox
            checked={justificatif?.sCochePensionneGuerre === "X"}
            name="sCochePensionneGuerre"
            onChange={onChange}
          />

          <Title>Si le malade n'est pas l'assuré(e)</Title>

          <DEPStyledFormItem label="NOM">
            <DefaultInput
              value={justificatif?.sNomPatient}
              name="sNomPatient"
              onChange={onChange}
            />
          </DEPStyledFormItem>

          <DEPStyledFormItem label="PRÉNOM">
            <DefaultInput
              value={justificatif?.sPrenomPatient}
              name="sPrenomPatient"
              onChange={onChange}
            />
          </DEPStyledFormItem>

          <Row>
            <DEPStyledFormItemWithoutWidthC label="Sexe">
              <Checkbox
                checked={justificatif?.sCocheSexeM === "X"}
                name="sCocheSexeM"
                onChange={onChange}
              >
                M
              </Checkbox>

              <Checkbox
                checked={justificatif?.sCocheSexeF === "X"}
                name="sCocheSexeF"
                onChange={onChange}
              >
                F
              </Checkbox>
            </DEPStyledFormItemWithoutWidthC>

            <DEPStyledFormItemWithoutWidth label="Date de naissance">
              <DatePicker
                value={
                  justificatif?.dDateNaissance
                    ? moment(justificatif?.dDateNaissance)
                    : undefined
                }
                name="dDateNaissance"
                placeholder="jj/mm/aaaa"
                format="DD/MM/YYYY"
                locale={locale}
                onChange={(momentDate) => {
                  if (momentDate) {
                    newPrescription!.oJustificatifs!.dep!.dDateNaissance =
                      new Date(momentDate.format("YYYY-MM-DD"));
                    setPrescription({ ...newPrescription, bUpdated: true });
                  } else {
                    newPrescription!.oJustificatifs!.dep!.dDateNaissance =
                      undefined;
                    setPrescription({ ...newPrescription, bUpdated: true });
                  }
                }}
              />
            </DEPStyledFormItemWithoutWidth>
          </Row>

          <Row>
            <DEPStyledFormItemWithoutHeight label="Lien avec l'assuré(e)">
              <CheckboxGroupRow>
                <Checkbox
                  checked={justificatif?.sCocheLienConjoint === "X"}
                  name="sCocheLienConjoint"
                  onChange={onChange}
                >
                  Conjoint
                </Checkbox>

                <Checkbox
                  checked={justificatif?.sCocheLienEnfant === "X"}
                  name="sCocheLienEnfant"
                  onChange={onChange}
                >
                  Autre membre de la famille
                </Checkbox>

                <Checkbox
                  checked={justificatif?.sCocheLienAutre === "X"}
                  name="sCocheLienAutre"
                  onChange={onChange}
                >
                  Enfant
                </Checkbox>

                <Checkbox
                  checked={justificatif?.sCocheLienMari === "X"}
                  name="sCocheLienMari"
                  onChange={onChange}
                >
                  Personne vivant maritalement avec l'assuré(e)
                </Checkbox>
              </CheckboxGroupRow>
            </DEPStyledFormItemWithoutHeight>
          </Row>

          <Row>
            <StyledSpan>
              Exerce-t-il habituellement une activité professionnelle ou est-il
              titulaire d’une pension ?
            </StyledSpan>

            <Checkbox
              checked={justificatif?.sCocheActiviteOui === "X"}
              name="sCocheActiviteOui"
              onChange={onChange}
            >
              OUI
            </Checkbox>

            <Checkbox
              checked={justificatif?.sCocheActiviteNon === "X"}
              name="sCocheActiviteNon"
              onChange={onChange}
            >
              NON
            </Checkbox>
          </Row>
        </SectionMalade>

        <SectionSignature>
          <SectionSignatureLeft>
            La loi rend passible d’amende et/ou d’emprisonnement quiconque se
            rend coupable de fraudes ou de fausses déclarations (art. L 377-1 du
            Code de la Sécurité Sociale, 441-1 du Code Pénal).
          </SectionSignatureLeft>

          <SectionSignatureRight>
            J’atteste sur l’honneur l’exactitude des renseignements portés
            ci-dessus.
            <Signature>
              <Label>Signature de l'assuré(e)</Label>
              <ArrowRightOutlined />
              <TextArea disabled rows={1} />
            </Signature>
          </SectionSignatureRight>
        </SectionSignature>
      </Body>
    </Wrapper>
  );
}
