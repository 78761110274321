import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Auth, Identite } from "../../interfaces/utilisateur.interface";
import type { Store } from "../../interfaces/store.interface";
import type Medecin from "../../interfaces/medecin.interface";

const initialState: Auth = {
  bConnecte: false,
  identite: undefined,
  medecinInfos: undefined,
  sErreur: undefined,
};

export const slice = createSlice({
  name: "utilisateur",
  initialState,
  reducers: {
    connexionErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.bConnecte = false;
      state.identite = initialState.identite;
      state.sErreur = payload.sErreur;
      state.medecinInfos = undefined;
    },
    connexionSucces: (
      state,
      { payload }: PayloadAction<{ user: Identite; token: string }>
    ) => {
      state.bConnecte = true;
      state.identite = payload.user;
      state.sErreur = undefined;
      state.medecinInfos = undefined;
    },
    deconnexionSucces: (state) => {
      state.bConnecte = false;
      state.identite = initialState.identite;
      state.sErreur = undefined;
      state.medecinInfos = undefined;
    },
    setMedecinInfos: (
      state,
      { payload }: PayloadAction<{ medecinInfos?: Medecin }>
    ) => {
      state.medecinInfos = payload.medecinInfos;
    },
  },
});

export const {
  connexionSucces,
  connexionErreur,
  deconnexionSucces,
  setMedecinInfos,
} = slice.actions;
export const selectConnecte = (state: Store) => state.utilisateur.bConnecte;
export const selectMedecinInfos = (state: Store) =>
  state.utilisateur.medecinInfos;
export const selectStoreErreur = (state: Store) => state.utilisateur.sErreur;
export const selectUtilisateur = (state: Store) => state.utilisateur.identite;

export default slice.reducer;
