import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TextIcon from "../atoms/text/TextIcon";
// Interfaces
import {
  NewModeleTexte,
  UpdateModeleTexte,
} from "../../interfaces/modeleTexte.interface";
// Slices
import { toggleModal } from "../../redux/modal/modal.slice";

import { PrimaryButton, FormItemWrapper, Textarea, Label } from "@ads/ui";
import ModeleTexte from "../../interfaces/modeleTexte.interface";

import { selectUtilisateur } from "../../redux/utilisateur/utilisateur.slice";
import { notification } from "antd";
import { useCallback, useState } from "react";
import {
  addModeleTexte,
  deleteModeleTexte,
  updateModeleTexte,
  getModelesTexte,
} from "../../api/modelesTexte.api";

import BlackModal from "../atoms/modals/BlackModal";
import { StyledInput } from "../atoms/input/InputStyled";

const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 55px 0 0 0;
`;

const WrapperModal = styled.div`
  padding: 5.5rem 15rem;
`;

interface PropsFormModele {
  modele: ModeleTexte;
  setModeles: React.Dispatch<React.SetStateAction<ModeleTexte[]>>;
}

interface PropsNewFormModele {
  setModeles: React.Dispatch<React.SetStateAction<ModeleTexte[]>>;
}

export const FormModele = ({ modele: m, setModeles }: PropsFormModele) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUtilisateur);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isLoading, _setIsLoading] = useState(false);
  const [modele, setModele] = useState(m);

  console.log(modele);
  console.log(m);

  const handleTab = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const textarea = e.target as HTMLTextAreaElement;
      const value = textarea.value;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const newValue =
        value.substring(0, selectionStart) +
        "  " +
        value.substring(selectionEnd);

      // setValue("sTexte", newValue);
      setModele({ ...modele, sTexte: newValue });
      // TO DO find a solution without setTimeout
      setTimeout(() => {
        textarea.value = newValue;
        textarea.selectionStart = textarea.selectionEnd = selectionStart + 2;
      }, 100);
    }
  };

  const handleCancel = () => {
    dispatch(toggleModal());
  };

  const updateModele = useCallback(
    async (iPKModeleTexte: number, newModeleData: UpdateModeleTexte) => {
      try {
        if (!user) return;
        await updateModeleTexte(
          user.iFKAnnuaire!,
          iPKModeleTexte,
          newModeleData
        ); // FIXME: user.iFKAnnuaire!

        notification.success({ message: "Modèle mis à jour" });
        setModeles(await getModelesTexte(user.iFKAnnuaire!)); // FIXME: user.iFKAnnuaire!
      } catch (error: any) {
        notification.error({ message: error.message });
      }
    },
    [setModeles, user]
  );

  const deleteModeles = useCallback(
    async (iPKModeleTexte: number) => {
      try {
        if (!user) return;
        await deleteModeleTexte(user.iFKAnnuaire!, iPKModeleTexte); // FIXME: user.iFKAnnuaire!
        notification.success({ message: "Modèle supprimé" });
        setModeles(await getModelesTexte(user.iFKAnnuaire!)); // FIXME: user.iFKAnnuaire!
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
        dispatch(toggleModal());
      }
    },
    [dispatch, setModeles, user]
  );

  const onSubmit = async (data: NewModeleTexte) => {
    await updateModele(modele.iPKModeleTexte, data);
    dispatch(toggleModal());
  };

  return (
    <BlackModal>
      <WrapperModal>
        <TextIcon
          size="medium"
          color="white"
          label="Ajouter un modèle de texte"
          icon="signature"
          center
        />
        <FormItemWrapper>
          <Label htmlFor="sLibelle">Titre</Label>
          <StyledInput
            defaultValue={modele?.sLibelle}
            id="sLibelle"
            name="sLibelle"
            type="text"
            onChange={(e) => {
              if (modele) {
                setModele({ ...modele, sLibelle: e.target.value });
              }
            }}
          />
        </FormItemWrapper>

        <FormItemWrapper>
          <Label htmlFor="sTexte">Contenu</Label>
          <Textarea
            value={modele?.sTexte}
            minWidth="495px"
            id="sTexte"
            name="sTexte"
            handleKeyDown={handleTab}
            onChange={(e: any) => {
              if (modele) {
                setModele({ ...modele, sTexte: e.target.value });
              }
            }}
          />
        </FormItemWrapper>

        <WrapperButtons>
          <PrimaryButton
            label="Annuler"
            as="button"
            size="S"
            buttonStyle="danger"
            type="reset"
            minWidth="157px"
            onClick={handleCancel}
          />
          {modele && (
            <PrimaryButton
              as="button"
              label="Supprimer"
              size="S"
              buttonStyle="danger"
              type="button"
              minWidth="157px"
              onClick={() => deleteModeles(modele.iPKModeleTexte)}
            />
          )}
          <PrimaryButton
            as="button"
            label={isLoading ? "Envois en cours" : "Valider"}
            size="S"
            buttonStyle={isLoading ? "neutral" : "primary"}
            type="submit"
            minWidth="157px"
            disabled={isLoading}
            onClick={() => {
              if (modele) {
                onSubmit(modele);
              }
            }}
          />
        </WrapperButtons>
      </WrapperModal>
    </BlackModal>
  );
};

export const FormNewModele = ({ setModeles }: PropsNewFormModele) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUtilisateur);
  const [isLoading, setIsLoading] = useState(false);

  const [modeleNew, setModeleNew] = useState<NewModeleTexte>({
    sLibelle: "",
    sTexte: "",
  });

  const handleTab = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const textarea = e.target as HTMLTextAreaElement;
      const value = textarea.value;
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const newValue =
        value.substring(0, selectionStart) +
        "  " +
        value.substring(selectionEnd);

      setModeleNew({ ...modeleNew, sTexte: newValue });
      // TO DO find a solution without setTimeout
      setTimeout(() => {
        textarea.value = newValue;
        textarea.selectionStart = textarea.selectionEnd = selectionStart + 2;
      }, 100);
    }
  };

  const handleCancel = () => {
    dispatch(toggleModal());
  };

  const addModeles = useCallback(
    async (newModele: NewModeleTexte) => {
      try {
        setIsLoading(true);
        if (!user) return;
        await addModeleTexte(user.iFKAnnuaire!, newModele); // FIXME: user.iFKAnnuaire!
        notification.success({ message: "Modèle ajouté" });
        setModeles(await getModelesTexte(user.iFKAnnuaire!)); // FIXME: user.iFKAnnuaire!
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
        setIsLoading(false);
      }
    },
    [setModeles, user]
  );

  const onSubmit = async (data: NewModeleTexte) => {
    await addModeles(data);
    dispatch(toggleModal());
  };

  return (
    <BlackModal>
      <WrapperModal>
        <TextIcon
          size="medium"
          color="white"
          label="Ajouter un modèle de texte"
          icon="signature"
          center
        />
        <FormItemWrapper>
          <Label htmlFor="sLibelle">Titre</Label>
          <StyledInput
            value={modeleNew.sLibelle}
            id="sLibelle"
            name="sLibelle"
            type="text"
            onChange={(e) => {
              setModeleNew({ ...modeleNew, sLibelle: e.target.value });
            }}
          />
        </FormItemWrapper>

        <FormItemWrapper>
          <Label htmlFor="sTexte">Contenu</Label>
          <Textarea
            minWidth="495px"
            id="sTexte"
            name="sTexte"
            value={modeleNew.sTexte}
            handleKeyDown={handleTab}
            onChange={(e: any) => {
              setModeleNew({ ...modeleNew, sTexte: e.target.value });
            }}
          />
        </FormItemWrapper>

        <WrapperButtons>
          <PrimaryButton
            label="Annuler"
            as="button"
            size="S"
            buttonStyle="danger"
            type="reset"
            minWidth="157px"
            onClick={handleCancel}
          />
          <PrimaryButton
            as="button"
            label={isLoading ? "Envois en cours" : "Valider"}
            size="S"
            buttonStyle={isLoading ? "neutral" : "primary"}
            type="submit"
            minWidth="157px"
            disabled={isLoading}
            onClick={() => {
              onSubmit(modeleNew);
            }}
          />
        </WrapperButtons>
      </WrapperModal>
    </BlackModal>
  );
};
