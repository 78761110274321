import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { notification } from "antd";
import styled from "styled-components";

import TextIcon from "../../atoms/text/TextIcon";
import BarChart from "../../atoms/charts/BarChart";

import { getSynthesePatients } from "../../../api/syntheses.api";

import { selectUtilisateur } from "../../../redux/utilisateur/utilisateur.slice";

import type { Identite } from "../../../interfaces/utilisateur.interface";
import type { SynthesePatient } from "../../../interfaces/synthese.interface";

const errorMessages = {
  genericDescription:
    "Nous rencontrons une erreur de chargement de vos informations, veuillez contacter votre administrateur",
  personnalInformations: "Echec du chargement de vos informations",
  renouvellements: "Echec du chargement du tableau des renouvellements",
  synthesePatients: "Echec du chargement de la synthèse des patients",
  syntheseRenouvellements:
    "Echec du chargement de la synthèse des renouvellements",
} as const;

const WrapperTitle = styled("div")`
  padding-left: 62px;
`;

const PrescriptionEnCours = () => {
  const identity = useSelector(selectUtilisateur);

  const [synthesePatients, setSynthesePatients] = useState<SynthesePatient[]>();

  const getSynthesePatientsFromIdentity = async (identity: Identite) => {
    try {
      const synthesePatients = await getSynthesePatients(identity.iFKAnnuaire!); // FIXME: iFKAnnuaire!
      setSynthesePatients(synthesePatients);
    } catch (error) {
      notification.error({
        description: errorMessages.genericDescription,
        message: errorMessages.synthesePatients,
      });
    }
  };

  useEffect(() => {
    if (identity) {
      getSynthesePatientsFromIdentity(identity);
    }
  }, [identity]);

  return (
    <div>
      <WrapperTitle>
        <TextIcon
          label="Prescriptions en cours"
          icon="prescEnCours"
          size="medium"
          color="white"
        />
      </WrapperTitle>
      {synthesePatients && <BarChart datas={synthesePatients} />}
    </div>
  );
};

export default PrescriptionEnCours;
