import { Checkbox, Input } from "antd";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SendOutlined } from "@ant-design/icons";
import FormItem from "antd/lib/form/FormItem";
import React from "react";
import styled from "styled-components";

import {
  CheckboxItem,
  CheckboxList,
  Indication,
  StyledCheckbox,
} from "../../elements/input.elements";
import { DefaultInput } from "../../elements/DEPinput.elements";
import { Header } from "../../elements/header.elements";
import type IDAP from "../../../../interfaces/dap.interface";

const Body = styled.div``;

const CheckboxSection = styled.div`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper {
    margin-left: 18px;
  }
`;

const Icon = styled.div`
  display: flex;

  > span {
    padding-right: 10px;
  }
`;

const IconExamen = styled(Icon)`
  font-weight: 700;

  .ant-checkbox-wrapper {
    margin-left: 18px;
  }
`;

const Label = styled.div`
  font-weight: 700;
  margin-right: 5px;
`;

const LargeIndication = styled(Indication)`
  font-size: unset;
`;

const Section = styled.div`
  padding: 5px 0px 5px 0px;
`;

const SectionRefus = styled(Section)`
  display: flex;
  justify-content: space-between;
  margin-right: 200px; // UGLY
`;

const StyledInput = styled(Input)`
  width: max-content;
`;

const SubSection = styled.div`
  padding: 5px 25px 5px 25px;
`;

const WeightIndication = styled(Indication)`
  font-weight: 700;
  text-align: center;
  font-size: unset;
`;

interface ElementsSectionProps {
  justificatif: IDAP;
  onChange: (e: ChangeEvent) => void;
}

type ChangeEvent = CheckboxChangeEvent | React.ChangeEvent<HTMLInputElement>;

export default function ElementsSection({
  justificatif,
  onChange,
}: ElementsSectionProps) {
  return (
    <>
      <Header>Eléments d’ordre médical</Header>

      <Body>
        <WeightIndication>
          (Partie confidentielle réservée à l'information du médecin conseil)
        </WeightIndication>

        <Section>
          <Icon>
            <SendOutlined />
            <Label>Prescription initiale</Label>
          </Icon>

          <SubSection>
            <Section>
              <Icon>
                <SendOutlined />
                <Label>Symptômes cliniques et pathologies :</Label>
              </Icon>

              <CheckboxSection>
                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  name="bPathoSomnolenceDiurne"
                  onChange={onChange}
                  checked={justificatif.bPathoSomnolenceDiurne}
                >
                  Somnolence diurne
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  checked={justificatif.bPathoRonflements}
                  name="bPathoRonflements"
                  onChange={onChange}
                >
                  Ronflements sévères et quotidiens
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  checked={justificatif.bPathoCephaleesMatinales}
                  name="bPathoCephaleesMatinales"
                  onChange={onChange}
                >
                  Céphalées matinales
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  checked={justificatif.bPathoFatigueDiurne}
                  name="bPathoFatigueDiurne"
                  onChange={onChange}
                >
                  Fatigue diurne
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  checked={justificatif.bPathoNycturie}
                  name="bPathoNycturie"
                  onChange={onChange}
                >
                  Nycturie
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  checked={justificatif.bPathoEtouffementSommeil}
                  name="bPathoEtouffementSommeil"
                  onChange={onChange}
                >
                  Sensation d’étouffement ou de suffocation pendant le sommeil
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  checked={justificatif.bPathoSomnolenceDiurneSevere}
                  name="bPathoSomnolenceDiurneSevere"
                  onChange={onChange}
                >
                  Somnolence diurne sévère et/ou risque accidentel
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  checked={justificatif.bPathoComorbiditeCardio}
                  name="bPathoComorbiditeCardio"
                  onChange={onChange}
                >
                  Comorbidité cardio-vasculaire associée grave
                </StyledCheckbox>

                <FormItem
                  label="à préciser"
                  style={{ marginLeft: "40px" } /* UGLY */}
                >
                  <DefaultInput
                    disabled={
                      !justificatif.bPPC ||
                      !justificatif.sCochePPRInitiale ||
                      !justificatif.bPathoComorbiditeCardio
                    }
                    value={justificatif.sPathoComorbiditeCardioPrecision}
                    name="sPathoComorbiditeCardioPrecision"
                    onChange={onChange}
                  />
                </FormItem>

                <StyledCheckbox
                  disabled={
                    !justificatif.bPPC || !justificatif.sCochePPRInitiale
                  }
                  checked={justificatif.bPathoComorbiditeRespiratoire}
                  name="bPathoComorbiditeRespiratoire"
                  onChange={onChange}
                >
                  Comorbidité respiratoire associée grave
                </StyledCheckbox>

                <FormItem
                  label="à préciser"
                  style={{ marginLeft: "40px" } /* UGLY */}
                >
                  <DefaultInput
                    disabled={
                      !justificatif.bPPC ||
                      !justificatif.sCochePPRInitiale ||
                      !justificatif.bPathoComorbiditeRespiratoire
                    }
                    value={justificatif.sPathoComorbiditeRespiratoirePrecision}
                    name="sPathoComorbiditeRespiratoirePrecision"
                    onChange={onChange}
                  />
                </FormItem>
              </CheckboxSection>
            </Section>

            <Section>
              <IconExamen>
                <SendOutlined />

                <Label>Examen réalisé :</Label>

                <StyledCheckbox
                  disabled={!justificatif.sCochePPRInitiale}
                  name="bExamenPolygraphie"
                  checked={justificatif.bExamenPolygraphie}
                  onChange={onChange}
                >
                  polygraphie
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={!justificatif.sCochePPRInitiale}
                  checked={justificatif.bExamenPolysomnographie}
                  name="bExamenPolysomnographie"
                  onChange={onChange}
                >
                  polysomnographie
                </StyledCheckbox>
              </IconExamen>

              <SubSection>
                <Label>
                  Renseigner l’indice d apnées –hypopnées (IHA) par heure
                  correspondant à l’examen réalisé :
                </Label>

                <StyledCheckbox
                  disabled={
                    !justificatif.sCochePPRInitiale ||
                    (!justificatif.bExamenPolygraphie &&
                      !justificatif.bExamenPolysomnographie)
                  }
                  checked={justificatif.bIAHSup30}
                  name="bIAHSup30"
                  onChange={onChange}
                >
                  IAH ≥ 30/h
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.sCochePPRInitiale ||
                    (!justificatif.bExamenPolygraphie &&
                      !justificatif.bExamenPolysomnographie)
                  }
                  checked={justificatif.bIAHEntre15Et30}
                  name="bIAHEntre15Et30"
                  onChange={onChange}
                >
                  {"IAH<30/h et ≥ 15/h"}
                </StyledCheckbox>

                <StyledCheckbox
                  disabled={
                    !justificatif.sCochePPRInitiale ||
                    (!justificatif.bExamenPolygraphie &&
                      !justificatif.bExamenPolysomnographie)
                  }
                  checked={justificatif.bIAHInf15}
                  name="bIAHInf15"
                  onChange={onChange}
                >
                  {"IAH <15/h"}
                </StyledCheckbox>

                <LargeIndication>
                  (Joindre le compte rendu de l’examen)
                </LargeIndication>
              </SubSection>
            </Section>
          </SubSection>
        </Section>

        <Section>
          <Icon>
            <SendOutlined />
            <Label>
              Prescription du 1er renouvellement à 4 mois après la prescription
              initiale du traitement par PPC :
            </Label>
          </Icon>

          <CheckboxList>
            <CheckboxItem>
              {"Indiquer l’indice d’AH/h sous traitement : "}
              <StyledInput
                value={justificatif.rIAH4Mois}
                disabled={!justificatif.sCochePPR4Mois}
                name="rIAH4Mois"
                onChange={onChange}
                min={0}
                type="number"
              />
            </CheckboxItem>

            <CheckboxItem>
              {"Amélioration de la symptomatologie : "}

              <Checkbox
                disabled={!justificatif.sCochePPR4Mois}
                checked={justificatif.sCocheAmelioSymptoIRELM4MoisOui === "X"}
                name="sCocheAmelioSymptoIRELM4MoisOui"
                onChange={onChange}
              >
                oui
              </Checkbox>

              <Checkbox
                disabled={!justificatif.sCochePPR4Mois}
                checked={justificatif.sCocheAmelioSymptoIRELM4MoisNon === "X"}
                name="sCocheAmelioSymptoIRELM4MoisNon"
                onChange={onChange}
              >
                non
              </Checkbox>
            </CheckboxItem>

            <CheckboxItem>
              {"Refus du relevé d’observance par le patient : "}
              <Checkbox
                checked={justificatif.sCocheRefusIRELM4Mois === "X"}
                disabled={!justificatif.sCochePPR4Mois}
                name="sCocheRefusIRELM4Mois"
                onChange={onChange}
              >
                oui
              </Checkbox>
            </CheckboxItem>
          </CheckboxList>
        </Section>

        <Section>
          <Icon>
            <SendOutlined />
            <Label>
              Prescription de renouvellement annuel du traitement par PPC :
            </Label>
          </Icon>

          <CheckboxList>
            <CheckboxItem>
              {"Indiquer l’indice d’AH/h sous traitement : "}
              <StyledInput
                value={justificatif.rIAHRenouv}
                disabled={!justificatif.sCochePPRRenouv}
                name="rIAHRenouv"
                onChange={onChange}
                min={0}
                type="number"
              />
            </CheckboxItem>

            <CheckboxItem>
              {"Amélioration de la symptomatologie : "}

              <Checkbox
                disabled={!justificatif.sCochePPRRenouv}
                checked={justificatif.sCocheAmelioSymptoIRELMRenouvOui === "X"}
                name="sCocheAmelioSymptoIRELMRenouvOui"
                onChange={onChange}
              >
                oui
              </Checkbox>

              <Checkbox
                disabled={!justificatif.sCochePPRRenouv}
                checked={justificatif.sCocheAmelioSymptoIRELMRenouvNon === "X"}
                name="sCocheAmelioSymptoIRELMRenouvNon"
                onChange={onChange}
              >
                non
              </Checkbox>
            </CheckboxItem>

            <CheckboxItem>
              {"Refus du relevé d’observance par le patient : "}
              <Checkbox
                checked={justificatif.sCocheRefusIRELMRenouv === "X"}
                disabled={!justificatif.sCochePPRRenouv}
                name="sCocheRefusIRELMRenouv"
                onChange={onChange}
              >
                oui
              </Checkbox>
            </CheckboxItem>
          </CheckboxList>
        </Section>

        <Section>
          <Icon>
            <SendOutlined />
            <Label>
              Prescription de renouvellement du traitement par OAM :
            </Label>
          </Icon>

          <CheckboxList>
            <CheckboxItem>
              {"Indiquer l’indice d’AH/h sous traitement : "}
              <StyledInput
                value={justificatif.rIAHOAM}
                disabled={!justificatif.bOAM}
                name="rIAHOAM"
                onChange={onChange}
                min={0}
                type="number"
              />
            </CheckboxItem>

            <CheckboxItem>
              {"Amélioration de la symptomatologie : "}

              <Checkbox
                disabled={!justificatif.bOAM}
                checked={justificatif.sCocheAmelioSymptoOAMOui === "X"}
                name="sCocheAmelioSymptoOAMOui"
                onChange={onChange}
              >
                oui
              </Checkbox>

              <Checkbox
                disabled={!justificatif.bOAM}
                checked={justificatif.sCocheAmelioSymptoOAMNon === "X"}
                name="sCocheAmelioSymptoOAMNon"
                onChange={onChange}
              >
                non
              </Checkbox>

              <LargeIndication>
                (Joindre le compte rendu de l’examen)
              </LargeIndication>
            </CheckboxItem>
          </CheckboxList>
        </Section>

        <SectionRefus>
          <Icon>
            <SendOutlined />
            <Label>{"Refus ou intolérance de la PPC "}</Label>
            <Checkbox
              disabled={!justificatif.bPPC}
              checked={justificatif.bRefusPPC}
              name="bRefusPPC"
              onChange={onChange}
            />
          </Icon>

          <Icon>
            <SendOutlined />
            <Label>{"Refus ou intolérance de l’OAM "}</Label>
            <Checkbox
              disabled={!justificatif.bOAM}
              checked={justificatif.bRefusOAM}
              name="bRefusOAM"
              onChange={onChange}
            />
          </Icon>
        </SectionRefus>
      </Body>
    </>
  );
}
