import React from "react";
import { MouseEventHandler } from "react";
import styled from "styled-components";
import add from "../../../assets/icons/add.svg";

const StyledAddButton = styled("button")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 7px 17px;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: bold;
  color: #3c4450;
  border: 1px solid #3c4450;
  cursor: pointer;
  border-radius: 20px;
  background: none;
  transition: all 0.15s linear;
  &:before {
    content: "";
    display: inline-block;
    background-image: url(${add});
    width: 8px;
    height: 8px;
    background-size: cover;
  }
  &:hover {
    background: #f4f4f4;
  }
`;

interface Props {
  label: string;
  handleClick?: MouseEventHandler<HTMLButtonElement> | any;
}

const AddButton = ({ label, handleClick }: Props) => (
  <StyledAddButton onClick={handleClick}>{label}</StyledAddButton>
);

export default AddButton;
