import { Select } from "antd";
import { FiltersPrescriptionsService } from "../hooks/useFiltersPrescriptions";
import { v4 as uuidv4 } from "uuid";

export function SelectLieuxExercice(controler: FiltersPrescriptionsService) {
  if (controler.medecin?.tLieuxExercice) {
    return (
      <Select
        allowClear
        autoClearSearchValue
        mode="multiple"
        placeholder="Lieux d'exercice"
        onChange={(value) => controler.addFilter("tLieuxExercice", value)}
        showSearch
        value={
          controler.options.find((o) => o.filter === "tLieuxExercice")?.value
        }
      >
        {controler.medecin?.tLieuxExercice.map((lieuxExercice) => (
          <Select.Option
            key={uuidv4()}
            value={lieuxExercice.iPKMedecinExercice}
          >
            {lieuxExercice.sLibelle}
          </Select.Option>
        ))}
      </Select>
    );
  } else {
    return (
      <Select
        allowClear
        autoClearSearchValue
        mode="multiple"
        placeholder="Lieux d'exercice"
        disabled
      />
    );
  }
}
