import axios from 'axios';
import config from '../config';

export async function deleteSignature(iPKAnnuaire: number) {
  await axios.put(
    `${config.serverURL}/medecins/${iPKAnnuaire}/signature`,
    { sSignature: null },
    { withCredentials: true }
  );
}

export async function upsertSignature(iPKAnnuaire: number, dataUrlSignature: string) {
  await axios.put(
    `${config.serverURL}/medecins/${iPKAnnuaire}/signature`,
    { sSignature: dataUrlSignature },
    { withCredentials: true }
  );
}

export async function upsertSignatureOtp(iPKAnnuaire: number, otp: string, dataUrlSignature: string) {
  await axios.put(
    `${config.serverURL}/medecins/${iPKAnnuaire}/signature/${otp}`,
    { sSignature: dataUrlSignature },
  );
}
