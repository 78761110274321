import React from "react";
import { Checkbox as CheckboxANTD, Row, DatePicker } from "antd";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";

import moment from "moment";
import type { ChangeEvent as ReactChangeEvent } from "react";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import styled from "styled-components";
import TextArea from "antd/lib/input/TextArea";

import {
  CheckboxComplete,
  CheckboxCompleteInput,
  CheckboxCompleteLabel,
  CheckboxGroup,
  CheckboxGroupRowAlign,
  DEPStyledFormItem,
  DEPStyledFormItemTextArea,
  DEPStyledFormItemWithoutWidth,
  DEPStyledFormItemWithoutWidthC,
  DefaultInput,
  SmallInput,
  StyledSpan,
  DefaultBigInput,
} from "../../elements/DEPinput.elements";
import { Wrapper } from "../../elements/sections.elements";
import type IDEP from "../../../../interfaces/dep.interface";
import { IPrescriptionRenouvellement } from "../../../../interfaces/prescription.interface";
import { CheckboxOrdo as Checkbox } from "../../../../components/atoms/buttons/CheckboxOrdo";

const ActeRow = styled.div`
  display: inline-flex;
  height: 20px !important;
  width: 100%;

  .ant-checkbox-wrapper {
    font-size: 12px !important;
    width: 50%;
  }
`;

const Body = styled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 1fr 1fr;
`;

const CheckboxAutre = styled(CheckboxGroup)`
  grid-area: checkboxautre;

  .ant-checkbox-wrapper {
    font-size: 12px !important;
  }
`;

const CheckboxBloc = styled.div`
  grid-area: check;
`;

const GridInput = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  width: 100%;
`;

const GridLine = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  justify-content: space-between;

  .ant-checkbox-wrapper {
    font-size: 12px !important;
    padding-top: unset;
  }
`;

const Identification = styled.div`
  display: grid;
  grid-template-areas:
    "id sign"
    "check check";
`;

const IdentificationBloc = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: id;
  justify-content: center;
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
`;

const Label = styled.label`
  font-size: 12px;
`;

const LargeInput = styled(DefaultInput)`
  grid-column: span 2;
`;

const ListeCheckbox = styled.div`
  display: flex;
  width: 100%;

  .ant-checkbox-wrapper {
    display: unset;
  }
`;

const ListeLabel = styled.span`
  display: flex;
  font-size: 12px !important;
  justify-content: center;
  width: 100%;
`;

const ListeTitle = styled.li`
  margin-left: 15px;
`;

const PrincSec = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Section = styled.div`
  background-color: lightgray;
  border-radius: 12px;
  padding: 2px;
`;

const SignatureBloc = styled.div`
  grid-area: sign;
`;

const StyledInput = styled(DefaultInput)`
  height: 20px !important;
`;

const TD = styled.td`
  font-size: 10px;
  width: 180px;
`;

const THead = styled.th`
  background-color: black;
  border: 1px solid white;
  color: white;
  font-size: 9px;
  font-weight: 200;
  height: 20px;
  text-align: center;
`;

const TRow = styled.tr`
  height: 20px;
`;

const StyledDatePicker = styled.div`
  width: 123px;
`;

const TableauMateriel = styled.table``;

const Title = styled.h5`
  text-transform: uppercase;
`;
const TdTest = styled.td`
  /*border-color: black;*/
  /*border-style: solid;*/
  border-width: 1px;
  overflow: hidden;
  font-size: 10px;
  /* padding: 10px 0px;/
  /*padding: 15px 14px;*/
  word-break: normal;
  border-color: inherit;
  text-align: left;
  vertical-align: top;
`;
const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
`;

interface MedecinTraitantSectionProps {
  bDateSignature: boolean;
  justificatif?: IDEP;
  onChange: (e: ChangeEvent) => void;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}

export type ChangeEvent =
  | CheckboxChangeEvent
  | ReactChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export default function MedecinTraitantSection({
  bDateSignature,
  prescription,
  setPrescription,
  onChange,
  justificatif,
}: MedecinTraitantSectionProps) {
  const newPrescription = prescription;

  return (
    <Wrapper>
      <Body>
        <Section>
          <Title>TRAITEMENT PRESCRIT</Title>

          <span>Est-il en rapport avec une affection de longue durée ?</span>

          <Row>
            <DEPStyledFormItemWithoutWidthC label="reconnue sur liste">
              <Checkbox
                children={"OUI"}
                value={justificatif?.sCocheALDListeOui}
                name="sCocheALDListeOui"
                onChange={onChange}
              />

              <Checkbox
                name="sCocheALDListeNon"
                value={justificatif?.sCocheALDListeNon}
                onChange={onChange}
              >
                NON
              </Checkbox>
            </DEPStyledFormItemWithoutWidthC>

            <DEPStyledFormItemWithoutWidthC label="reconnue hors liste">
              <Checkbox
                value={justificatif?.sCocheALDHorsListeOui}
                name="sCocheALDHorsListeOui"
                onChange={onChange}
              >
                OUI
              </Checkbox>

              <Checkbox
                value={justificatif?.sCocheALDHorsListeNon}
                name="sCocheALDHorsListeNon"
                onChange={onChange}
              >
                NON
              </Checkbox>
            </DEPStyledFormItemWithoutWidthC>
          </Row>

          <Table>
            <colgroup>
              <col style={{ width: "40px" }} />
              <col style={{ width: "382px" }} />
              <col style={{ width: "109px" }} />
              <col style={{ width: "135px" }} />
              <col style={{ width: "132px" }} />
              <col style={{ width: "127px" }} />
            </colgroup>
            <thead>
              <tr>
                <TdTest style={{ borderRight: "none" }}></TdTest>
                <TdTest
                  style={{
                    borderBottom: "none",

                    borderLeft: "none",
                  }}
                ></TdTest>
                <THead>Poste fixe</THead>
                <THead>Déambulation (1)</THead>
                <THead>Durée (h/j)</THead>
                <THead>Débit (l/mn)</THead>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpOXYC}
                    name="sCocheTpOXYC"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                    borderRight: "none",
                  }}
                >
                  Oxygénothérapie par concentrateur
                </TdTest>
                <TdTest style={{ border: "none" }}>
                  <Checkbox
                    value={justificatif?.sCocheTpPosteFixeOXYC}
                    name="sCocheTpPosteFixeOXYC"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest style={{ border: "none" }}>
                  <Checkbox
                    value={justificatif?.sCocheTpDeambulationOXYC}
                    name="sCocheTpDeambulationOXYC"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDureeOXYC}
                    name="rDureeOXYC"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDebitOXYC}
                    name="rDebitOXYC"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpOXYG}
                    name="sCocheTpOXYG"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                    borderRight: "none",
                  }}
                >
                  Oxygénothérapie gazeuse par bouteille
                </TdTest>
                <TdTest style={{ border: "none" }}>
                  <Checkbox
                    value={justificatif?.sCocheTpPosteFixeOXYG}
                    name="sCocheTpPosteFixeOXYG"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest style={{ border: "none" }}>
                  <Checkbox
                    value={justificatif?.sCocheTpDeambulationOXYG}
                    name="sCocheTpDeambulationOXYG"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDureeOXYG}
                    name="rDureeOXYG"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDebitOXYG}
                    name="rDebitOXYG"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpOXYL}
                    name="sCocheTpOXYL"
                    onChange={onChange}
                  />{" "}
                </TdTest>
                <TdTest
                  style={{
                    borderBottom: "none",
                    borderTop: "none",
                    borderRight: "none",
                  }}
                >
                  Oxygénothérapie liquide
                </TdTest>
                <TdTest style={{ border: "none" }}>
                  <Checkbox
                    value={justificatif?.sCocheTpPosteFixeOXYL}
                    name="sCocheTpPosteFixeOXYL"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest style={{ border: "none" }}>
                  <Checkbox
                    value={justificatif?.sCocheTpDeambulationOXYL}
                    name="sCocheTpDeambulationOXYL"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDureeOXYL}
                    name="rDureeOXYL"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDebitOXYL}
                    name="rDebitOXYL"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpVENEMBUC}
                    name="sCocheTpVENEMBUC"
                    onChange={onChange}
                  />{" "}
                </TdTest>
                <TdTest
                  colSpan={3}
                  style={{ borderBottom: "none", borderTop: "none" }}
                >
                  Ventilation mécanique à l'embout buccal
                </TdTest>

                <TdTest>
                  <StyledInput
                    value={justificatif?.rDureeVENEMBUC}
                    name="rDureeVENEMBUC"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDebitVENEMBUC}
                    name="rDebitVENEMBUC"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpVENMANAS}
                    name="sCocheTpVENMANAS"
                    onChange={onChange}
                  />{" "}
                </TdTest>
                <TdTest
                  colSpan={3}
                  style={{ borderBottom: "none", borderTop: "none" }}
                >
                  Ventilation mécanique par masque nasal
                </TdTest>

                <TdTest>
                  <StyledInput
                    value={justificatif?.rDureeVENMANAS}
                    name="rDureeVENMANAS"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDebitVENMANAS}
                    name="rDebitVENMANAS"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpVENTRACHEO}
                    name="sCocheTpVENTRACHEO"
                    onChange={onChange}
                  />{" "}
                </TdTest>
                <TdTest
                  colSpan={3}
                  style={{ borderBottom: "none", borderTop: "none" }}
                >
                  Ventilation mécanique par masque trachéotomie
                </TdTest>

                <TdTest>
                  <StyledInput
                    value={justificatif?.rDureeVENTRACHEO}
                    name="rDureeVENTRACHEO"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDebitVENTRACHEO}
                    name="rDebitVENTRACHEO"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpVENPROTHES}
                    name="sCocheTpVENPROTHES"
                    onChange={onChange}
                  />{" "}
                </TdTest>
                <TdTest
                  colSpan={3}
                  style={{ borderBottom: "none", borderTop: "none" }}
                >
                  Ventilation mécanique par prothèse extra-thoracique
                </TdTest>

                <TdTest>
                  <StyledInput
                    value={justificatif?.rDureeVENPROTHES}
                    name="rDureeVENPROTHES"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDebitVENPROTHES}
                    name="rDebitVENPROTHES"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpVENPPC}
                    name="sCocheTpVENPPC"
                    onChange={onChange}
                  />{" "}
                </TdTest>
                <TdTest
                  colSpan={3}
                  style={{ borderBottom: "none", borderTop: "none" }}
                >
                  Ventilation mécanique par pression positive continue
                </TdTest>

                <TdTest>
                  <StyledInput
                    value={justificatif?.rDureeVENPPC}
                    name="rDureeVENPPC"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
                <TdTest>
                  <StyledInput
                    value={justificatif?.rDebitVENPPC}
                    name="rDebitVENPPC"
                    onChange={onChange}
                    type="number"
                  />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpASP}
                    name="sCocheTpASP"
                    onChange={onChange}
                  />{" "}
                </TdTest>
                <TdTest
                  colSpan={3}
                  style={{ borderBottom: "none", borderTop: "none" }}
                >
                  Aspiration sans ventilation sur trachéotomie
                </TdTest>

                <TdTest>
                  <DefaultInput disabled size="small" min={1} max={100000} />
                </TdTest>
                <TdTest>
                  <DefaultInput disabled size="small" min={1} max={100000} />
                </TdTest>
              </tr>
              <tr>
                <TdTest>
                  <Checkbox
                    value={justificatif?.sCocheTpAUTRE}
                    name="sCocheTpAUTRE"
                    onChange={onChange}
                  />
                </TdTest>
                <TdTest
                  colSpan={3}
                  style={{ borderBottom: "none", borderTop: "none" }}
                >
                  Autres :
                </TdTest>
              </tr>
            </thead>
          </Table>

          <CheckboxAutre>
            <DefaultInput
              value={justificatif?.sTPAutre}
              name="sTPAutre"
              onChange={onChange}
            />

            <DefaultInput
              value={justificatif?.sTPInfo}
              name="sTPInfo"
              onChange={onChange}
            />
          </CheckboxAutre>

          <Title>APPAREILLAGE PRESCRIT</Title>

          <CheckboxGroup>
            <ActeRow>
              <CheckboxANTD style={{ width: "60%" }} disabled>
                A l'acte (hors association)
              </CheckboxANTD>
              <StyledInput
                value={justificatif?.sFinessAntenne}
                name="sFinessAntenne"
                onChange={onChange}
                style={{ visibility: "hidden" }}
              />
            </ActeRow>

            <Row>
              <CheckboxComplete>
                <Checkbox
                  value={justificatif?.sCocheAssociation}
                  name="sCocheAssociation"
                  onChange={onChange}
                />

                <CheckboxCompleteLabel>Association :</CheckboxCompleteLabel>

                <StyledSpan>Nom</StyledSpan>

                <CheckboxCompleteInput
                  value={justificatif?.sNomAssociation}
                  name="sNomAssociation"
                  onChange={onChange}
                  style={{ height: "20px" }}
                />
              </CheckboxComplete>
            </Row>

            <GridInput>
              <Label htmlFor={"sAdresseAssociation"}>ADRESSE :</Label>

              <DefaultInput
                value={justificatif?.sAdresseAssociationRue}
                name={"sAdresseAssociationRue"}
                onChange={onChange}
              />

              <LargeInput
                value={justificatif?.sAdresseAssociationCPVille}
                name={"sAdresseAssociationCPVille"}
                onChange={onChange}
              />
            </GridInput>

            <DEPStyledFormItem label="FORFAIT SOINS No">
              <SmallInput
                value={justificatif?.sFKCodePrescription}
                onChange={onChange}
                name="sFKCodePrescription"
              />
            </DEPStyledFormItem>
          </CheckboxGroup>

          <TableauMateriel>
            <thead>
              <TRow>
                <THead>MATÉRIEL</THead>
                <THead>Concentrateur</THead>
                <THead>Respirateur (1)</THead>
                <THead>Mat. O2 liquide</THead>
                <THead>Autres</THead>
              </TRow>
            </thead>
            <tbody>
              <TRow>
                <THead>MARQUE</THead>
                <td>
                  <StyledInput
                    value={justificatif?.sMarqueConcentrateur}
                    name="sMarqueConcentrateur"
                    onChange={onChange}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.sMarqueRespirateur}
                    name="sMarqueRespirateur"
                    onChange={onChange}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.sMarqueMatOXYL}
                    name="sMarqueMatOXYL"
                    onChange={onChange}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.sMarqueAutre}
                    name="sMarqueAutre"
                    onChange={onChange}
                  />
                </td>
              </TRow>

              <TRow>
                <THead>TYPE</THead>
                <td>
                  <StyledInput
                    value={justificatif?.sTypeConcentrateur}
                    name="sTypeConcentrateur"
                    onChange={onChange}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.sTypeRespirateur}
                    name="sTypeRespirateur"
                    onChange={onChange}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.sTypeMatOXYL}
                    name="sTypeMatOXYL"
                    onChange={onChange}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.sTypeAutre}
                    name="sTypeAutre"
                    onChange={onChange}
                  />
                </td>
              </TRow>
            </tbody>
          </TableauMateriel>

          <Identification>
            <IdentificationBloc>
              <StyledSpan>Identification du prescripteur</StyledSpan>

              <DefaultInput
                value={justificatif?.sNomPrescripteur}
                name="sNomPrescripteur"
                onChange={onChange}
              />

              <DefaultInput
                value={justificatif?.sFiness}
                name="sFiness"
                onChange={onChange}
              />

              <DefaultBigInput
                value={justificatif?.sAdressePrescripteur}
                name="sAdressePrescripteur"
                onChange={onChange}
                rows={4}
              />
            </IdentificationBloc>

            <SignatureBloc>
              <DEPStyledFormItemWithoutWidth label="Date">
                <DatePicker
                  disabled={!bDateSignature}
                  value={
                    prescription
                      ? moment(prescription.dDateSignature)
                      : moment(new Date())
                  }
                  name="dDateSignature"
                  format="DD/MM/YYYY"
                  locale={locale}
                  onChange={(momentDate) => {
                    if (momentDate)
                      setPrescription({
                        ...prescription,
                        dDateSignature: new Date(
                          momentDate.format("YYYY-MM-DD")
                        ),
                        bUpdated: true,
                      });
                  }}
                />
              </DEPStyledFormItemWithoutWidth>

              <DEPStyledFormItemTextArea label="Signature">
                <TextArea cols={3} disabled rows={2} />
              </DEPStyledFormItemTextArea>
            </SignatureBloc>

            <CheckboxBloc>
              <CheckboxGroupRowAlign>
                <Checkbox
                  value={justificatif?.sCochePneumologue}
                  name="sCochePneumologue"
                  onChange={onChange}
                >
                  Pneumologue
                </Checkbox>

                <Checkbox
                  value={justificatif?.sCocheReanimateur}
                  name="sCocheReanimateur"
                  onChange={onChange}
                >
                  Réanimateur
                </Checkbox>

                <Checkbox
                  value={justificatif?.sCocheGeneraliste}
                  name="sCocheGeneraliste"
                  onChange={onChange}
                >
                  Généraliste
                </Checkbox>

                <CheckboxComplete>
                  <Checkbox
                    value={justificatif?.sCocheAutreSpecialite}
                    name="sCocheAutreSpecialite"
                    onChange={onChange}
                  />
                  <CheckboxCompleteLabel>Autre</CheckboxCompleteLabel>

                  <SmallInput
                    value={justificatif?.sAutreSpecialite}
                    name="sAutreSpecialite"
                    onChange={onChange}
                  />
                </CheckboxComplete>
              </CheckboxGroupRowAlign>
            </CheckboxBloc>
          </Identification>
        </Section>

        <Section>
          <Title>
            CONFIDENTIEL - Réservé à l’information du Contrôle Médical
          </Title>

          <ListeTitle>ÉTIOLOGIE DE L’INSUFFISANCE RESPIRATOIRE (1)</ListeTitle>

          <PrincSec>
            <span>Principale (1 choix possible)</span>
            <span>Secondaire (2 choix possibles)</span>
          </PrincSec>

          <CheckboxGroup>
            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincBRONCHITE}
                name="sCochePrincBRONCHITE"
                onChange={onChange}
              />

              <ListeLabel>
                Bronchite chronique (avec ou sans emphysème)
              </ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecBRONCHITE}
                name="sCocheSecBRONCHITE"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincLESION}
                name="sCochePrincLESION"
                onChange={onChange}
              />
              <ListeLabel>
                Lésions mutilantes, post-tuberculeuses et autres
              </ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecLESION}
                name="sCocheSecLESION"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincASTHME}
                name="sCochePrincASTHME"
                onChange={onChange}
              />

              <ListeLabel>Asthme à dyspnée continue</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecASTHME}
                name="sCocheSecASTHME"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincFIBROSE}
                name="sCochePrincFIBROSE"
                onChange={onChange}
              />

              <ListeLabel>Fibroses et processus interstitiels</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecFIBROSE}
                name="sCocheSecFIBROSE"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincSCOLIOSE}
                name="sCochePrincSCOLIOSE"
                onChange={onChange}
              />

              <ListeLabel>Cypho-scoliose</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecSCOLIOSE}
                name="sCocheSecSCOLIOSE"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincEMPHYSEME}
                name="sCochePrincEMPHYSEME"
                onChange={onChange}
              />

              <ListeLabel>Emphysème primitif</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecEMPHYSEME}
                name="sCocheSecEMPHYSEME"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincDILATBR}
                name="sCochePrincDILATBR"
                onChange={onChange}
              />

              <ListeLabel>Dilatations étendues des bronches</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecDILATBR}
                name="sCocheSecDILATBR"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincMYOPATHE}
                name="sCochePrincMYOPATHE"
                onChange={onChange}
              />

              <ListeLabel>Myopathies</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecMYOPATHE}
                name="sCocheSecMYOPATHE"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincNEUROMUSC}
                name="sCochePrincNEUROMUSC"
                onChange={onChange}
              />
              <ListeLabel>Autres atteintes neuro-musculaires</ListeLabel>
              <Checkbox
                value={justificatif?.sCocheSecNEUROMUSC}
                onChange={onChange}
                name="sCocheSecNEUROMUSC"
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincPNEUMO}
                name="sCochePrincPNEUMO"
                onChange={onChange}
              />

              <ListeLabel>Pneumoconioses</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecPNEUMO}
                name="sCocheSecPNEUMO"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincAPNEE}
                name="sCochePrincAPNEE"
                onChange={onChange}
              />
              <ListeLabel>Apnées du sommeil</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecAPNEE}
                name="sCocheSecAPNEE"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincMUCO}
                name="sCochePrincMUCO"
                onChange={onChange}
              />
              <ListeLabel>Mucoviscidose</ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecMUCO}
                name="sCocheSecMUCO"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincDYSPLASIE}
                name="sCochePrincDYSPLASIE"
                onChange={onChange}
              />

              <ListeLabel>
                Dysplasie broncho-pulmonaire du nourrisson
              </ListeLabel>

              <Checkbox
                value={justificatif?.sCocheSecDYSPLASIE}
                name="sCocheSecDYSPLASIE"
                onChange={onChange}
              />
            </ListeCheckbox>

            <ListeCheckbox>
              <Checkbox
                value={justificatif?.sCochePrincAUTRE}
                name="sCochePrincAUTRE"
                onChange={onChange}
              />

              <ListeLabel>Autre :</ListeLabel>

              <DefaultInput
                value={justificatif?.sAutreEtiologie}
                name="sAutreEtiologie"
                onChange={onChange}
              />

              <Checkbox
                value={justificatif?.sCocheSecAUTRE}
                name="sCocheSecAUTRE"
                onChange={onChange}
              />
            </ListeCheckbox>
          </CheckboxGroup>

          <GridLine>
            <ListeTitle>TABAC</ListeTitle>

            <CheckboxGroupRowAlign>
              <Checkbox
                value={justificatif?.sCocheTabacJamais}
                name="sCocheTabacJamais"
                onChange={onChange}
              >
                N’a jamais fumé
              </Checkbox>

              <Checkbox
                value={justificatif?.sCocheTabacNon}
                name="sCocheTabacNon"
                onChange={onChange}
              >
                Ne fume plus
              </Checkbox>

              <Checkbox
                value={justificatif?.sCocheTabacOui}
                name="sCocheTabacOui"
                onChange={onChange}
              >
                Fume
              </Checkbox>
            </CheckboxGroupRowAlign>
          </GridLine>

          <GridLine>
            <ListeTitle>ÉTAT CLINIQUE</ListeTitle>

            <CheckboxGroup>
              <Checkbox
                value={justificatif?.sCocheIVDAntecedent}
                name="sCocheIVDAntecedent"
                onChange={onChange}
              >
                Antécédent d’I.V.D.
              </Checkbox>

              <Checkbox
                value={justificatif?.sCocheIVDActuelle}
                name="sCocheIVDActuelle"
                onChange={onChange}
              >
                I.V.D. actuelle
              </Checkbox>
            </CheckboxGroup>
          </GridLine>

          <GridLine>
            <ListeTitle>SPIROGRAPHIE (1)</ListeTitle>

            <DEPStyledFormItemWithoutWidth label="Date">
              <DatePicker
                value={
                  justificatif?.sDateSpiro
                    ? moment(justificatif?.sDateSpiro)
                    : undefined
                }
                name="sDateSpiro"
                format="DD/MM/YYYY"
                locale={locale}
                placeholder="jj/mm/aaaa"
                onChange={(momentDate) => {
                  if (momentDate) {
                    newPrescription!.oJustificatifs!.dep!.sDateSpiro =
                      momentDate?.format("YYYY-MM-DD");
                    setPrescription({ ...newPrescription, bUpdated: true });
                  } else {
                    newPrescription!.oJustificatifs!.dep!.sDateSpiro = " ";
                    setPrescription({ ...newPrescription, bUpdated: true });
                  }
                }}
              />
            </DEPStyledFormItemWithoutWidth>
          </GridLine>

          <InputGrid>
            <div>
              Poids
              <SmallInput
                value={justificatif?.sPoids}
                name="sPoids"
                onChange={onChange}
                min={0}
                type="number"
              />
              kg
            </div>

            <div>
              Capacité vitale
              <SmallInput
                value={justificatif?.sCapaciteVitale}
                name="sCapaciteVitale"
                onChange={onChange}
                min={0}
                type="number"
              />
              ml
            </div>

            <div>
              VEMS
              <SmallInput
                value={justificatif?.sVEMS}
                name="sVEMS"
                onChange={onChange}
                min={0}
                type="number"
              />
              ml
            </div>

            <div>
              Taille
              <SmallInput
                value={justificatif?.sTaille}
                name="sTaille"
                onChange={onChange}
                min={0}
                type="number"
              />
              cm
            </div>

            <div>
              Capacité totale
              <SmallInput
                value={justificatif?.sCapaciteTotale}
                name="sCapaciteTotale"
                onChange={onChange}
                min={0}
                type="number"
              />
              ml
            </div>
          </InputGrid>

          <GridLine>
            <ListeTitle>GAZ DU SANG ARTÉRIEL (1) (2) :</ListeTitle>

            <CheckboxGroupRowAlign>
              <Checkbox
                value={justificatif?.sCocheGazkPa}
                name="sCocheGazkPa"
                onChange={onChange}
              >
                kPa ou
              </Checkbox>

              <Checkbox
                value={justificatif?.sCocheGazmmHg}
                name="sCocheGazmmHg"
                onChange={onChange}
              >
                mmHg
              </Checkbox>
            </CheckboxGroupRowAlign>
          </GridLine>

          <TableauMateriel>
            <thead>
              <TRow>
                <THead>DATES</THead>
                <THead> </THead>
                <THead>pH</THead>
                <THead>PaCO2</THead>
                <THead>PaO2</THead>
                <THead>SaO2</THead>
              </TRow>
            </thead>
            <tbody>
              <TRow>
                <td>
                  <StyledDatePicker>
                    <DatePicker
                      value={
                        justificatif?.sDateGazRA1
                          ? moment(justificatif?.sDateGazRA1)
                          : undefined
                      }
                      name="sDateGazRA1"
                      format="DD/MM/YYYY"
                      locale={locale}
                      placeholder="jj/mm/aaaa"
                      onChange={(momentDate) => {
                        if (momentDate) {
                          newPrescription!.oJustificatifs!.dep!.sDateGazRA1 =
                            momentDate?.format("YYYY-MM-DD");
                          setPrescription({
                            ...newPrescription,
                            bUpdated: true,
                          });
                        } else {
                          newPrescription!.oJustificatifs!.dep!.sDateGazRA1 =
                            " ";
                          setPrescription({
                            ...newPrescription,
                            bUpdated: true,
                          });
                        }
                      }}
                    />
                  </StyledDatePicker>
                </td>

                <TD>REPOS EN AIR (1re mesure)</TD>

                <td>
                  <StyledInput
                    value={justificatif?.rPHRA1}
                    name="rPHRA1"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rPaCO2RA1}
                    name="rPaCO2RA1"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rPaO2RA1}
                    name="rPaO2RA1"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rSaO2RA1}
                    name="rSaO2RA1"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>
              </TRow>

              <TRow>
                <td>
                  <DatePicker
                    value={
                      justificatif?.sDateGazRA2
                        ? moment(justificatif?.sDateGazRA2)
                        : undefined
                    }
                    name="sDateGazRA2"
                    format="DD/MM/YYYY"
                    locale={locale}
                    placeholder="jj/mm/aaaa"
                    onChange={(momentDate) => {
                      if (momentDate) {
                        newPrescription!.oJustificatifs!.dep!.sDateGazRA2 =
                          momentDate?.format("YYYY-MM-DD");
                        setPrescription({
                          ...newPrescription,
                          bUpdated: true,
                        });
                      } else {
                        newPrescription!.oJustificatifs!.dep!.sDateGazRA2 = " ";
                        setPrescription({
                          ...newPrescription,
                          bUpdated: true,
                        });
                      }
                    }}
                  />
                </td>

                <TD>REPOS EN AIR (2e mesure)</TD>

                <td>
                  <StyledInput
                    value={justificatif?.rPHRA2}
                    name="rPHRA2"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rPaCO2RA2}
                    name="rPaCO2RA2"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rPaO2RA2}
                    name="rPaO2RA2"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rSaO2RA2}
                    name="rSaO2RA2"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>
              </TRow>

              <TRow>
                <td>
                  <DatePicker
                    value={
                      justificatif?.sDateGazRO2
                        ? moment(justificatif?.sDateGazRO2)
                        : undefined
                    }
                    name="sDateGazRO2"
                    format="DD/MM/YYYY"
                    locale={locale}
                    placeholder="jj/mm/aaaa"
                    onChange={(momentDate) => {
                      if (momentDate) {
                        newPrescription!.oJustificatifs!.dep!.sDateGazRO2 =
                          momentDate?.format("YYYY-MM-DD");
                        setPrescription({
                          ...newPrescription,
                          bUpdated: true,
                        });
                      } else {
                        newPrescription!.oJustificatifs!.dep!.sDateGazRO2 = " ";
                        setPrescription({
                          ...newPrescription,
                          bUpdated: true,
                        });
                      }
                    }}
                  />
                </td>

                <TD>REPOS SOUS O2 ET/OU VENTILATION (1)</TD>

                <td>
                  <StyledInput
                    value={justificatif?.rPHRO2}
                    name="rPHRO2"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rPaCO2RO2}
                    onChange={onChange}
                    name="rPaCO2RO2"
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rPaO2RO2}
                    name="rPaO2RO2"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rSaO2RO2}
                    onChange={onChange}
                    name="rSaO2RO2"
                    min={0}
                    type={"number"}
                  />
                </td>
              </TRow>

              <TRow>
                <td>
                  <DatePicker
                    value={
                      justificatif?.sDateGazEffort
                        ? moment(justificatif?.sDateGazEffort)
                        : undefined
                    }
                    name="sDateGazEffort"
                    format="DD/MM/YYYY"
                    locale={locale}
                    placeholder="jj/mm/aaaa"
                    onChange={(momentDate) => {
                      if (momentDate) {
                        newPrescription!.oJustificatifs!.dep!.sDateGazEffort =
                          momentDate?.format("YYYY-MM-DD");
                        setPrescription({
                          ...newPrescription,
                          bUpdated: true,
                        });
                      } else {
                        newPrescription!.oJustificatifs!.dep!.sDateGazEffort =
                          " ";
                        setPrescription({
                          ...newPrescription,
                          bUpdated: true,
                        });
                      }
                    }}
                  />
                </td>

                <TD>A L’EFFORT EN AIR (1)</TD>

                <td>
                  <StyledInput
                    value={justificatif?.rPHEffort}
                    name="rPHEffort"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rPaCO2Effort}
                    name="rPaCO2Effort"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rPaO2Effort}
                    name="rPaO2Effort"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>

                <td>
                  <StyledInput
                    value={justificatif?.rSaO2Effort}
                    name="rSaO2Effort"
                    onChange={onChange}
                    min={0}
                    type={"number"}
                  />
                </td>
              </TRow>
            </tbody>
          </TableauMateriel>

          <GridLine>
            <ListeTitle>HEMATOCRITE :</ListeTitle>

            <div>
              <SmallInput
                value={justificatif?.rTauxHematocrite}
                name="rTauxHematocrite"
                onChange={onChange}
                min={0}
                type={"number"}
              />
              %
            </div>
          </GridLine>

          <GridInput>
            <Label>CAS PARTICULIERS (1) :</Label>

            <DefaultInput
              value={justificatif?.sCasParticulier}
              name="sCasParticulier"
              onChange={onChange}
            />

            <LargeInput
              value={justificatif?.sInformation}
              name="sInformation"
              onChange={onChange}
            />
          </GridInput>
        </Section>
      </Body>
    </Wrapper>
  );
}
