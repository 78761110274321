import { useBarcode } from 'react-barcodes';
import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

interface BarCodeProps {
  value: string; // Beware: value.length > 0
}

export default function Barcode({ value }: BarCodeProps) {
  const { inputRef } = useBarcode({
    options: {
      background: 'transparent',
      displayValue: false,
      format: 'code128',
      height: 20,
      margin: 0,
      width: 1
    },
    value
  });

  return <Wrapper>
    <svg ref={inputRef} />
    {value}
  </Wrapper>;
}
