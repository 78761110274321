export function displayFormatImmatriculation(sNoSecuriteSociale?: string) {
  if (!sNoSecuriteSociale) return;
  const sexe = sNoSecuriteSociale[0];
  const anneeNaissance = sNoSecuriteSociale.slice(1, 3);
  const moisNaissance = sNoSecuriteSociale.slice(3, 5);
  const departementNaissance = sNoSecuriteSociale.slice(5, 7);
  const codeCommune = sNoSecuriteSociale.slice(7, 10);
  const numeroOrdre = sNoSecuriteSociale.slice(10, 13);
  const cle = sNoSecuriteSociale.slice(13);
  return `${sexe} ${anneeNaissance} ${moisNaissance} ${departementNaissance} ${codeCommune} ${numeroOrdre} / ${cle}`;
}

export function displayFormatNumAdeli(sNumAdeli: string) {
  if (!sNumAdeli) return;
  const dep = sNumAdeli.slice(0, 2);
  const codeProf = sNumAdeli.slice(2, 4);
  const num = sNumAdeli.slice(4, 8);
  const cle = sNumAdeli.slice(8);
  return `${dep} ${codeProf} ${num} ${cle}`;
}

export function displayFormatPhone(sTel?: string) {
  if (!sTel) return;
  const tel1 = sTel.slice(0, 2);
  const tel2 = sTel.slice(2, 4);
  const tel3 = sTel.slice(4, 6);
  const tel4 = sTel.slice(6, 8);
  const tel5 = sTel.slice(8);
  return `${tel1}.${tel2}.${tel3}.${tel4}.${tel5}`;
}
