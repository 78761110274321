import axios from 'axios';
import config from '../config';
import type Totp from '../interfaces/totp.interface';

export async function getTotp(iPKAnnuaire: number): Promise<Totp> {
  const { data } = await axios.get(
    `${config.serverURL}/totp/${iPKAnnuaire}`,
    { withCredentials: true }
  );
  return data;
}
