import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import styled from "styled-components";
// Slices
import { toggleModal } from "../../redux/modal/modal.slice";
import {
  selectMedecinInfos,
  setMedecinInfos,
} from "../../redux/utilisateur/utilisateur.slice";
import { selectCivilites } from "../../redux/config/config.slice";
// design
import { Label, PrimaryButton, FormItemWrapper } from "@ads/ui";
import { Select } from "antd";
import TextIcon from "../atoms/text/TextIcon";
import { updateInfosMedecins } from "../../api/medecins.api";
import { DataPersMed } from "../../interfaces/medecin.interface";
import BlackModal from "../atoms/modals/BlackModal";
import FormSelectWrapper from "../atoms/form/FormSelectWrapper";
import { StyledInput } from "../atoms/input/InputStyled";

const Grid = styled("div")`
  display: grid;
  grid-template-columns: 250px 250px;
  grid-gap: 43px;
  margin-top: 55px;
`;

const WrapperTitle = styled("div")`
  margin-top: 55px;
`;

const WrapperForm = styled("div")`
  padding: 5.5rem 15rem;
`;

const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 55px 0 0 0;
`;

const FormInfosPerso = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const civilites = useSelector(selectCivilites);
  const medecinFromBase = useSelector(selectMedecinInfos);
  const [medecin, setMedecinData] = useState<DataPersMed>({
    sCodeCivilite: medecinFromBase?.sCodeCivilite,
    sMail: medecinFromBase?.sMail,
    sNom: medecinFromBase?.sNom,
    sPrenom: medecinFromBase?.sPrenom,
    sTelGsm: medecinFromBase?.sTelGsm,
  });

  const handleCancel = () => {
    dispatch(toggleModal());
  };

  const updateCoordonnees = useCallback(
    async (newMedecinData: DataPersMed) => {
      console.log(newMedecinData);

      try {
        if (!medecin || !medecinFromBase) return;
        setIsLoading(true);
        await updateInfosMedecins(medecinFromBase.iFKAnnuaire, medecin);
        dispatch(
          setMedecinInfos({
            medecinInfos: {
              ...medecinFromBase,
              sCodeCivilite: medecin?.sCodeCivilite,
              sMail: medecin?.sMail,
              sNom: medecin?.sNom,
              sPrenom: medecin?.sPrenom,
              sTelGsm: medecin?.sTelGsm,
            },
          })
        );
        notification.success({
          message: "Information personnelles mises à jour",
        });
      } catch (error) {
        notification.error({
          description: `Nous rencontrons une erreur lors de l'actualisation de vos coordonnées, veuillez contacter votre administrateur`,
          message: `Echec de l'actualisation de vos coordonnées`,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, medecin, medecinFromBase]
  );

  const onSubmit = async (newMedecinData: DataPersMed) => {
    await updateCoordonnees(newMedecinData);
    dispatch(toggleModal());
  };

  return (
    <BlackModal>
      <WrapperTitle>
        <TextIcon
          size="medium"
          color="white"
          label="Modifications informations personnelles"
          icon="profil"
          center
        />
      </WrapperTitle>
      <WrapperForm>
        <Grid>
          <div className="col-gauche">
            <FormItemWrapper>
              <Label htmlFor="civilite">Civilite</Label>
              <FormSelectWrapper name="Civilite">
                <Select
                  defaultValue={medecin?.sCodeCivilite}
                  options={civilites?.map((c) => {
                    return {
                      label: c.sLibelle,
                      value: c.sCode,
                    };
                  })}
                  onChange={(value, option) => {
                    console.log(value, option);
                    let optionChoose = option as { label: any; value: any };
                    if (medecin)
                      setMedecinData({
                        ...medecin,
                        sCodeCivilite: optionChoose.label as string,
                      });
                  }}
                />
              </FormSelectWrapper>
            </FormItemWrapper>
            <FormItemWrapper>
              <Label htmlFor="sNom">Nom</Label>
              <StyledInput
                defaultValue={medecin?.sNom}
                id="sNom"
                name="sNom"
                type="text"
                onChange={(e) => {
                  if (medecin)
                    setMedecinData({ ...medecin, sNom: e.target.value });
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <Label htmlFor="portable">Téléphone portable</Label>
              <StyledInput
                id="sTelGsm"
                type="text"
                name="sTelGsm"
                defaultValue={medecin?.sTelGsm}
                onChange={(e) => {
                  if (medecin)
                    setMedecinData({ ...medecin, sTelGsm: e.target.value });
                }}
              />
            </FormItemWrapper>
          </div>
          <div className="col-droite">
            <FormItemWrapper>
              <Label htmlFor="mel">Mél</Label>
              <StyledInput
                defaultValue={medecin?.sMail}
                id="sMail"
                name="sMail"
                type="email"
                onChange={(e) => {
                  if (medecin)
                    setMedecinData({ ...medecin, sMail: e.target.value });
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper>
              <Label htmlFor="prénom">Prénom</Label>
              <StyledInput
                defaultValue={medecin?.sPrenom}
                id="sPrenom"
                name="sPrenom"
                type="text"
                onChange={(e) => {
                  if (medecin)
                    setMedecinData({ ...medecin, sPrenom: e.target.value });
                }}
              />
            </FormItemWrapper>
          </div>
        </Grid>
        <WrapperButtons>
          <PrimaryButton
            label="Annuler"
            as="button"
            size="S"
            buttonStyle="danger"
            type="reset"
            minWidth="157px"
            onClick={handleCancel}
          />
          <PrimaryButton
            as="button"
            label={isLoading ? "Envois en cours" : "Valider"}
            size="S"
            buttonStyle={isLoading ? "neutral" : "primary"}
            type="submit"
            minWidth="157px"
            disabled={isLoading}
            onClick={() => {
              if (medecin) {
                onSubmit(medecin);
              }
            }}
          />
        </WrapperButtons>
      </WrapperForm>
    </BlackModal>
  );
};

export default FormInfosPerso;
