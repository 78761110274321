import { IOrdonnancePPC } from "../../../../../../interfaces/ordonnance.interface";
import { Checkbox, Input } from "antd";
import { useRef, KeyboardEvent } from "react";
import FormItem from "antd/lib/form/FormItem";
import {
  CheckboxList,
  CheckboxItem,
  StyledCheckboxGroup as LabeledCheckboxGroup,
} from "../../../../elements/input.elements";
import { CheckboxForm } from "../traitementSection.elements";
import { TextAreaRef } from "antd/lib/input/TextArea";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import styled from "styled-components";
import { mainColor } from "../../../ordonnance.style";
import { HeaderAlair } from "../../header.elements";

type ChangeEvent =
  | CheckboxChangeEvent
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export const TitleAlairOrdo = styled.h2`
  padding: 0;
  padding-bottom: 1rem;
  margin: 0;
  color: ${mainColor};
`;

export const DivTitleAlairOrdo = styled.div`
  width: 100%;
  align-items: center;
  color: ${mainColor};
  text-align: center;
  border-top: 1px solid ${mainColor};
  border-bottom: 1px solid ${mainColor};
  padding: 1rem 4rem;
  margin: 2rem auto;
`;
interface PrescriptionProps {
  ordonnance: IOrdonnancePPC;
  onChange: (event: ChangeEvent) => void;
}
export function PrescriptionAlair(props: PrescriptionProps) {
  if (props.ordonnance.sType === "ppc") {
    return (
      <>
        <HeaderAlair
          dDateNaissancePatient={props.ordonnance.dDateNaissancePatient}
          sAdeliPrescripteur={props.ordonnance.sAdeliPrescripteur}
          sAdressePrescripteur={props.ordonnance.sAdressePrescripteur}
          sCiviliteNomPrenomPatient={props.ordonnance.sCiviliteNomPrenomPatient}
          sCiviliteNomPrenomPrescripteur={
            props.ordonnance.sCiviliteNomPrenomPrescripteur
          }
          sRPPSPrescripteur={props.ordonnance.sRPPSPrescripteur}
          sAdressePatient={props.ordonnance.sAdressePatient}
        />
        <div>
          <DivTitleAlairOrdo>
            <TitleAlairOrdo>
              Prescriptions relatives au traitement de l'affection de longue
              durèe reconnue (liste ou hors liste)
            </TitleAlairOrdo>
            <span>(AFFECTION EXONERANTE)</span>
          </DivTitleAlairOrdo>
          {props.ordonnance.bALDVrai && <ContentPF09 {...props} />}
          <DivTitleAlairOrdo>
            <TitleAlairOrdo>
              Prescriptions SANS RAPPORT avec l'affection de longue durée
            </TitleAlairOrdo>
            <span> (MALADIES INTERCURRENTES)</span>
          </DivTitleAlairOrdo>
          {props.ordonnance.bALDFaux && <ContentPF09 {...props} />}
        </div>
      </>
    );
  } else {
    return (
      <>
        <HeaderAlair
          dDateNaissancePatient={props.ordonnance.dDateNaissancePatient}
          sAdeliPrescripteur={props.ordonnance.sAdeliPrescripteur}
          sAdressePrescripteur={props.ordonnance.sAdressePrescripteur}
          sCiviliteNomPrenomPatient={props.ordonnance.sCiviliteNomPrenomPatient}
          sCiviliteNomPrenomPrescripteur={
            props.ordonnance.sCiviliteNomPrenomPrescripteur
          }
          sRPPSPrescripteur={props.ordonnance.sRPPSPrescripteur}
          sAdressePatient={props.ordonnance.sAdressePatient}
        />
        <div>
          <DivTitleAlairOrdo>
            <TitleAlairOrdo>
              Prescriptions relatives au traitement de l'affection de longue
              durèe reconnue (liste ou hors liste)
            </TitleAlairOrdo>
            <span>(AFFECTION EXONERANTE)</span>
          </DivTitleAlairOrdo>
          {props.ordonnance.bALDVrai && <ContentOrdo {...props} />}
          <DivTitleAlairOrdo>
            <TitleAlairOrdo>
              Prescriptions SANS RAPPORT avec l'affection de longue durée
            </TitleAlairOrdo>
            <span> (MALADIES INTERCURRENTES)</span>
          </DivTitleAlairOrdo>
          {props.ordonnance.bALDFaux && <ContentOrdo {...props} />}
        </div>
      </>
    );
  }
}

function ContentPF09(props: PrescriptionProps) {
  const textAreaEl = useRef<TextAreaRef>(null);
  const handleTab = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { textArea } = textAreaEl.current!.resizableTextArea!;

    if (e.key === "Tab") {
      const { selectionStart, selectionEnd } = textArea;
      const contentAfter = textArea.value.slice(selectionEnd);
      const contentBefore = textArea.value.slice(0, selectionStart);
      const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        "value"
      )?.set;
      e.preventDefault();
      // React library overrides input value setter
      nativeTextAreaValueSetter?.call(
        textArea,
        `${contentBefore}\t${contentAfter}`
      );
      textArea.dispatchEvent(new Event("input", { bubbles: true }));

      textArea.selectionEnd = selectionStart + 1;
      textArea.selectionStart = selectionStart + 1;
    }
  };
  return (
    <>
      <Input.TextArea
        onKeyDown={handleTab}
        rows={15}
        cols={5}
        wrap={"hard"}
        name={"sTextSpecifique"}
        ref={textAreaEl}
        bordered={false}
        onChange={props.onChange}
        value={props.ordonnance.sTextSpecifique}
      />

      <FormItem label="Type d'appareil PPC :">
        <Input
          value={props.ordonnance.sAppareil}
          name="sAppareil"
          onChange={props.onChange}
        />
      </FormItem>
      <CheckboxForm layout="vertical">
        <FormItem>
          <LabeledCheckboxGroup>
            <CheckboxList>
              <CheckboxItem>
                <Checkbox
                  checked={props.ordonnance.bPPCModeAuto}
                  onChange={props.onChange}
                  name="bPPCModeAuto"
                >
                  PPC autopilotée
                </Checkbox>
              </CheckboxItem>

              <CheckboxItem>
                <Checkbox
                  checked={props.ordonnance.bPPCModeFixe}
                  onChange={props.onChange}
                  name="bPPCModeFixe"
                >
                  PPC à pression fixe
                </Checkbox>
              </CheckboxItem>

              <CheckboxItem>
                <Checkbox
                  checked={props.ordonnance.bPPCModeDouble}
                  onChange={props.onChange}
                  name="bPPCModeDouble"
                >
                  à double niveau de pression
                </Checkbox>
              </CheckboxItem>
            </CheckboxList>
            <>{props.ordonnance.sReglagePression}</>
          </LabeledCheckboxGroup>
        </FormItem>
      </CheckboxForm>

      <FormItem label="IAH résiduel par heure :">
        <Input
          value={props.ordonnance.rIAHParJour3M}
          name="rIAHParJour3M"
          onChange={props.onChange}
        />
        (du {props.ordonnance.dDebut3M} au {props.ordonnance.dFin3M})
      </FormItem>

      <FormItem label="Observance moyenne :">
        <Input
          value={props.ordonnance.sObservanceMoyenneParJour}
          name="sObservanceMoyenneParJour"
          onChange={props.onChange}
        />
        (du {props.ordonnance.dDebut3M} au {props.ordonnance.dFin3M})
      </FormItem>
      <FormItem label="Commentaire :">
        <Input.TextArea
          onKeyDown={handleTab}
          rows={10}
          cols={5}
          wrap={"hard"}
          name="sContenu"
          ref={textAreaEl}
          bordered={false}
          onChange={props.onChange}
          value={props.ordonnance.sContenu}
        />{" "}
      </FormItem>
    </>
  );
}

function ContentOrdo(props: PrescriptionProps) {
  const textAreaEl = useRef<TextAreaRef>(null);
  const handleTab = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { textArea } = textAreaEl.current!.resizableTextArea!;

    if (e.key === "Tab") {
      const { selectionStart, selectionEnd } = textArea;
      const contentAfter = textArea.value.slice(selectionEnd);
      const contentBefore = textArea.value.slice(0, selectionStart);
      const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        "value"
      )?.set;
      e.preventDefault();
      // React library overrides input value setter
      nativeTextAreaValueSetter?.call(
        textArea,
        `${contentBefore}\t${contentAfter}`
      );
      textArea.dispatchEvent(new Event("input", { bubbles: true }));

      textArea.selectionEnd = selectionStart + 1;
      textArea.selectionStart = selectionStart + 1;
    }
  };
  return (
    <FormItem label="">
      <Input.TextArea
        onKeyDown={handleTab}
        rows={25}
        cols={5}
        wrap={"hard"}
        name="sContenu"
        ref={textAreaEl}
        bordered={false}
        onChange={props.onChange}
        defaultValue={props.ordonnance.sContenu}
      />{" "}
    </FormItem>
  );
}
