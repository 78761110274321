import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ModeleTexte from "../../../interfaces/modeleTexte.interface";
import {
  selectContentModal,
  toggleModal,
} from "../../../redux/modal/modal.slice";
import EditButton from "../../atoms/buttons/EditButton";
import { FormModele } from "../FormModele";

const Section = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 29px 0 29px 51px;
  border-bottom: 1px solid #ebebeb;
  &:last-child {
    border: none;
  }
`;

const TitleItem = styled("h3")`
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: bold;
  color: #3c4450;
`;

const DescriptionItem = styled("p")`
  display: block;
  max-width: 316px;
  font-size: 13px;
  margin-bottom: 8px;
  color: #3c4450;
  font-weight: medium;
`;

interface Props {
  modele: ModeleTexte;
  setModeles: React.Dispatch<React.SetStateAction<ModeleTexte[]>>;
}

const ModeleItem = ({ modele, setModeles }: Props) => {
  const dispatch = useDispatch();

  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);

  const handleClick = () => {
    dispatch(toggleModal());
    setContentModal(<FormModele modele={modele} setModeles={setModeles} />);
  };

  return (
    <Section>
      <div>
        <TitleItem>{modele.sLibelle}</TitleItem>
        <DescriptionItem> {modele.sTexte}</DescriptionItem>
      </div>
      <div>
        <EditButton label="Modifier" handleClick={handleClick} />
      </div>
    </Section>
  );
};

export default ModeleItem;
