import { useState, FunctionComponent } from "react";
import styled from "styled-components";
import { Text } from "@ads/ui";
import Back from "../../../assets/icons/fleche_blanche.svg";
import edit from "../../../assets/icons/edit-white.svg";

const StyledLayout = styled.a`
  position: relative;
  padding: 20px 37px 26px 25px;
  display: block;
  background: ${(props) => props.theme.colors.neutral[170]};
  border: unset;
  outline-style: none;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
  text-decoration: none;
`;

const StyledDiv = styled.div`
  display: block;
`;
interface StyledSVG {
  hover?: boolean;
}

const StyledSvg = styled.div<StyledSVG>`
  display: flex;
  gap: 4px;
  margin-top: 0px;
  transform: ${(props) => {
    if (props.hover) {
      return `
          translateX(0%);
        `;
    }
    return `
          translateX(10%);
        `;
  }};
  transition: all 0.3s ease-in-out;
`;

const StyledDivIcon = styled.img`
  position: absolute;
  right: calc(22px * -1 / 3); //22 px = taille svg
  top: calc(22px * -1 / 3); //22 px = taille svg
  background: ${(props) => props.theme.colors.neutral[190]};
  border-radius: 50%;
`;

const StyledWrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.neutral[190]};
  padding: 20px 20px 0 20px;
`;

const WrapperPicto = styled.div`
  .hover-text {
    position: absolute;
    right: 21px;
    top: -5px;
    display: inherit;
    font-size: 13px;
    color: ${(props) => props.theme.colors.neutral[100]};
    transform: translate(-10%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .hover-text {
      right: 25px;
      top: 2px;
      transform: translate(0);
      opacity: 1;
      font-weight: 600;
    }
  }
`;

interface PropsInsertDocument {
  label: string;
  href?: string;
  hover?: boolean;
  svgLabel: string;
  titleLabel: string;
  onClick: Function;
  editModele: Function;
}

const InsertDocument: FunctionComponent<PropsInsertDocument> = ({
  label,
  href,
  svgLabel,
  titleLabel,
  onClick,
  editModele,
}: PropsInsertDocument) => {
  const [isHover, setIsHover] = useState(false);
  const handleHover = () => {
    setIsHover(() => !isHover);
  };

  return (
    <StyledWrapper>
      <StyledLayout href={href}>
        <WrapperPicto>
          <span className="hover-text">Modifier</span>
          <StyledDivIcon
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              editModele();
            }}
            src={edit}
          />
        </WrapperPicto>
        <StyledDiv
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          onClick={() => onClick()}
        >
          <Text
            label={titleLabel}
            font="primary"
            sColor={{ style: "neutral" }}
            fontSize="1.5rem"
            fontWeight="600"
          />
          <Text
            label={label}
            font="primary"
            sColor={{ style: "neutral" }}
            fontSize="1.2rem"
            lineHeight="2.3rem"
          />
          <StyledSvg style={{ opacity: isHover ? "1" : "0" }} hover={isHover}>
            <img src={Back} alt="Svg" />
            <Text
              label={svgLabel}
              font="primary"
              sColor={{ style: "neutral" }}
              marginText="0"
            />
          </StyledSvg>
        </StyledDiv>
      </StyledLayout>
    </StyledWrapper>
  );
};

export default InsertDocument;
