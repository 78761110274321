import { FunctionComponent } from "react";
import styled from "styled-components";
import { Text } from "@ads/ui";
import check from "../../../assets/icons/check.svg";
import config from "../../../config";
import signature from "../../../assets/icons/signature.svg";

export const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 4px;
  padding-left: 14px;
  gap: 8px;
  border-radius: 20px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s linear;
  border: none;
  &.check {
    background-color: ${(props) => props.theme.colors.primary[100]};
    &:hover {
      background-color: ${(props) => props.theme.colors.primary[110]};
    }
    &:before {
      content: "";
      display: block;
      background-image: url(${check});
      width: 20px;
      height: 16px;
    }
    &:after {
      content: "";
      display: block;
      width: 5px;
    }
  }
  &.delete {
    background-color: ${(props) => props.theme.colors.danger[90]};
    &:hover {
      background-color: ${(props) => props.theme.colors.danger[120]};
    }
    &:after {
      content: "";
      display: block;
      width: 5px;
    }
  }
`;

export const CustomText = styled.div`
  padding: 8px 0px;
`;

const NumbersWrapper = styled.div`
  width: 31px;
  height: 31px;
  background-color: ${(props) => props.theme.colors.primary[80]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.p`
  color: ${(props) => props.theme.colors.neutral[100]};
  font-weight: 500;
  font-size: 11px;
`;

const WrapperChild = styled("div")`
  width: 100%;
  display: flex;
  &.renouvellement {
    justify-content: center;
    width: 33%;
  }

  &.nav {
    justify-content: flex-end;
    gap: 20px;
  }

  @media screen and (max-width: 1790px) {
    width: auto;
  }
`;

const CustomButonSignature = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 25px;
  gap: 10px;
  border-radius: 20px;
  font-weight: 400;
  transition: all 0.2s linear;
  border: none;
  background-color: #1890ff;
  &:hover {
    background-color: #61dafb;
    cursor: pointer;
  }
  &.check {
    &:before {
      content: "";
      display: block;
      background-image: url(${signature});
      width: 30px;
      height: 40px;
    }
  }
`;

interface Props {
  itemSelected: number;
  tabOrdonnance?: string[];
  ordonnances: string[];
  isLoading: boolean;
  handleClick: (value: string) => void;
}

const CheckDocument: FunctionComponent<Props> = ({
  itemSelected,
  ordonnances,
  isLoading,
  handleClick,
}) => {
  return (
    <WrapperChild className="nav">
      <div style={{ display: "flex" }}>
        {(config.oodriveSignatureActivated ||
          config.signatureClassiqueMultiple) && (
          <CustomButonSignature
            onClick={() => {
              handleClick("ajouterAuPanier");
            }}
            className="check"
          >
            <Text
              label="Prêt pour signature"
              sColor={{ style: "neutral", variant: 190 }}
              fontWeight="600"
            />
          </CustomButonSignature>
        )}
      </div>
      <CustomButton
        disabled={isLoading}
        className="check"
        onClick={() => {
          handleClick("signer");
        }}
      >
        <CustomText>
          <Text
            label={isLoading ? "En cours d'envoi" : "Signer et envoyer"}
            sColor={{ style: "neutral", variant: 100 }}
          />
        </CustomText>
        <NumbersWrapper>
          <TextWrapper>
            {itemSelected}/{ordonnances.length}
          </TextWrapper>
        </NumbersWrapper>
      </CustomButton>
    </WrapperChild>
  );
};

export default CheckDocument;
