import styled from "styled-components";
import Referent from "../../../interfaces/referent.interface";
import TextIcon from "../../atoms/text/TextIcon";

const ContactName = styled("p")`
  font-size: 17px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.neutral[100]};
`;

const TextBasic = styled("p")`
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.neutral[100]};
`;
interface PropsReferent {
  referent: Referent;
}

const ContactReferent = ({ referent }: PropsReferent) =>
  referent.sFonction.toLowerCase().indexOf("commercial") !== -1 ? (
    <div>
      <ContactName>
        {referent.sPrenom} {referent.sNom}
      </ContactName>
      <TextIcon label="Spécialiste" icon="contactSpecialite" />
      <TextBasic>{referent.sFKTypePrestation}</TextBasic>
    </div>
  ) : null;

export default ContactReferent;
