import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
// import logger from "redux-logger";

import appReducer from "./app/app.slice";
import configReducer from "./config/config.slice";
import utilisateurReducer from "./utilisateur/utilisateur.slice";
import modalReducer from "./modal/modal.slice";
import filterReducer from "./filter/filter.slice";

export const history = createBrowserHistory();

const reducer = combineReducers({
  app: appReducer,
  utilisateur: utilisateurReducer,
  router: connectRouter(history),
  modal: modalReducer,
  filter: filterReducer,
  config: configReducer

});

const middleware = [...getDefaultMiddleware()];

export default configureStore({
  middleware,
  reducer,
});
