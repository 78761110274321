import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { notification } from "antd";

import { getSynthesePrescriptions } from "../../../api/syntheses.api";

import { selectUtilisateur } from "../../../redux/utilisateur/utilisateur.slice";

import type { Identite } from "../../../interfaces/utilisateur.interface";
import type { SynthesePrestations } from "../../../interfaces/synthese.interface";

import TextIcon from "../../atoms/text/TextIcon";
import DonutChart from "../../atoms/charts/DonutChart";

const Wrapper = styled("div")`
  position: relative;
  display: block;
  padding: 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 20px #0a244343;

  .round {
    position: absolute;
    right: -25px;
    top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    height: 53px;
    background-color: ${(props) => props.theme.colors.primary[100]};
    border-radius: 50%;
    border: 5px solid ${(props) => props.theme.colors.secondary[100]};
    .number {
      font-size: 23px;
      color: ${(props) => props.theme.colors.neutral[100]};
    }
  }
`;

const errorMessages = {
  genericDescription:
    "Nous rencontrons une erreur de chargement de vos informations, veuillez contacter votre administrateur",
  personnalInformations: "Echec du chargement de vos informations",
  renouvellements: "Echec du chargement du tableau des renouvellements",
  synthesePatients: "Echec du chargement de la synthèse des patients",
  syntheseRenouvellements:
    "Echec du chargement de la synthèse des renouvellements",
} as const;

const PrescriptionProfil = () => {
  const identity = useSelector(selectUtilisateur);
  const [synthesePrescriptions, setSynthesePrescriptions] =
    useState<SynthesePrestations[]>();

  const getSynthesePrescriptionsFromIdentity = async (identity: Identite) => {
    try {
      const synthesePrescriptions = await getSynthesePrescriptions(
        identity.iFKAnnuaire!
      ); // FIXME: iFKAnnuaire!
      setSynthesePrescriptions(synthesePrescriptions);
    } catch (error) {
      notification.error({
        description: errorMessages.genericDescription,
        message: errorMessages.syntheseRenouvellements,
      });
    }
  };

  useEffect(() => {
    if (identity) {
      getSynthesePrescriptionsFromIdentity(identity);
    }
  }, [identity]);

  return (
    <Wrapper>
      <div className="round">
        <span className="number">
          {synthesePrescriptions?.reduce(
            (acc, obj) => acc + obj.iNbTypePrestation,
            0
          )}
        </span>
      </div>
      <TextIcon
        size="medium"
        label="Prescriptions en attente"
        icon="prescAttente medium"
        color="black"
      />
      {synthesePrescriptions && <DonutChart datas={synthesePrescriptions} />}
    </Wrapper>
  );
};

export default PrescriptionProfil;
