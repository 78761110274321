import React from "react";
import styled from "styled-components";
// Icons
import iconInfosPersos from "../../../assets/icons/infos_persos.svg";
import signature from "../../../assets/icons/signature.svg";
import place from "../../../assets/icons/place.svg";
import modeles from "../../../assets/icons/modeles_enregistres.svg";
import back from "../../../assets/icons/back.svg";

const StyleTitle = styled("h2")`
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  color: #3a3d4b;
  &:before {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateX(calc(-100% - 7px)) translateY(-50%);
  }
  &.infoPerso {
    &:before {
      content: url(${iconInfosPersos});
    }
  }
  &.signature {
    &:before {
      content: url(${signature});
    }
  }
  &.place {
    &:before {
      content: url(${place});
    }
  }
  &.modeles {
    &:before {
      content: url(${modeles});
    }
  }
  &.back {
    &:before {
      content: url(${back});
    }
  }
`;

interface Props {
  title: string;
  icon: "infoPerso" | "signature" | "place" | "modeles" | "back";
}

const TitleIcon = ({ title, icon }: Props) => {
  return <StyleTitle className={icon}>{title}</StyleTitle>;
};

export default TitleIcon;
