import {
  IPrescriptionDetail,
  IPrescriptionRenouvellement,
} from "../interfaces/prescription.interface";

export function convertPrescriptionToReinitFront(
  prescriptionOrigin: IPrescriptionDetail,
  prescriptionForm: IPrescriptionRenouvellement
) {
  let newPrescription: IPrescriptionRenouvellement = prescriptionOrigin;
  if (prescriptionOrigin.oJustificatifs && prescriptionForm.oJustificatifs)
    newPrescription.oJustificatifs = setPrescriptionToBack(
      prescriptionOrigin?.oJustificatifs,
      prescriptionForm?.oJustificatifs
    );
  return newPrescription;
}

function setPrescriptionToBack(
  prescriptionOriginJusti: object,
  prescriptionFormobJust: object
) {
  let prescriptionToSend = prescriptionOriginJusti;
  for (const [typePrescription, prescription] of Object.entries(
    prescriptionFormobJust
  )) {
    if (
      prescription &&
      typeof prescription === "object" &&
      Object.keys(prescriptionToSend).indexOf(typePrescription) !== -1
    ) {
      for (const [field, value] of Object.entries(prescription)) {
        /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
        const typePrescriptionJusti = (<any>prescriptionToSend)[
          typePrescription
        ];
        const isFieldExist =
          Object.keys(typePrescriptionJusti).indexOf(field) !== -1;
        if (!isFieldExist) {
          console.log(field, value);

          if (field.charAt(0) === "s") {
            typePrescriptionJusti[field] = "";
          }
          if (field.charAt(0) === "b") {
            typePrescriptionJusti[field] = false;
          }
          if (field.charAt(0) === "r" || field.charAt(0) === "d") {
            typePrescriptionJusti[field] = null;
          }
        }
      }
    }
  }
  return prescriptionToSend;
}
