import { useSelector } from "react-redux";
import { selectCodeClient } from "../../../../../../redux/config/config.slice";
import { OrdonnancePPCProps } from "../../ordonnance.ppc";
import { PrescriptionAdene } from "./PrescriptionAdene.element";
import { PrescriptionAlair } from "./PrescriptionAlair.element";

export function TemplatePrescription(props: OrdonnancePPCProps) {
  const codeClient = useSelector(selectCodeClient);

  return (
    <>
      {codeClient === "067" && (
        <PrescriptionAlair
          onChange={props.onChange}
          ordonnance={props.ordonnance}
        />
      )}
      {codeClient === "072" && <PrescriptionAdene {...props} />}
    </>
  );
}
