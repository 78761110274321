import type { Action } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';
import type { Utilisateur } from '../../../interfaces/utilisateur.interface';
import { deconnexionSucces } from '../utilisateur.slice';

export function deconnexionAction(): ThunkAction<void, Utilisateur, unknown, Action<string>> {
  return async (dispatch) => {
    document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    dispatch(deconnexionSucces());
  };
}
