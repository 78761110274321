import { Input, DatePicker } from "antd";
import moment from "moment";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import FormItem from "antd/lib/form/FormItem";
import React from "react";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

import { Indication, StyledFormItem } from "../../elements/input.elements";
import { Header } from "../../elements/header.elements";
import type IDAP from "../../../../interfaces/dap.interface";
import { IPrescriptionRenouvellement } from "../../../../interfaces/prescription.interface";

const Body = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-areas:
    "nomprenom nomprenom adresse adresse"
    "date sign sign sign";
  padding: 5px 0px 5px 0px;
`;

const Adresse = styled.div`
  border: 1px solid lightgray;
  grid-area: adresse;
  padding: 5px;
`;

const NomPrenom = styled.div`
  border: 1px solid lightgray;
  grid-area: nomprenom;
  padding: 5px;
`;

const Signature = styled.div`
  grid-area: sign;
`;

const StyledDate = styled(StyledFormItem)`
  border: none;
  grid-area: date;

  & input {
    width: 140px;
  }
`;
const InputNumber = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface IdentificationSectionProps {
  bDateSignature: boolean;
  justificatif: IDAP;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
  onChange: (e: ChangeEvent) => void;
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export default function IdentificationSection({
  bDateSignature,
  justificatif,
  prescription,
  setPrescription,
  onChange,
}: IdentificationSectionProps) {
  return (
    <>
      <Header>
        Identification du praticien et de la structure dans laquelle il exerce
      </Header>

      <Body>
        <NomPrenom>
          <FormItem label="Nom et prénom">
            <Input
              value={justificatif.sDAPCiviliteNomPrenomPrescripteur}
              name="sDAPCiviliteNomPrenomPrescripteur"
              onChange={onChange}
            />
          </FormItem>

          <FormItem label="Identifiant">
            <Input
              value={justificatif.sDAPRPPSPrescripteur}
              name="sDAPRPPSPrescripteur"
              onChange={onChange}
            />
          </FormItem>
        </NomPrenom>

        <Adresse>
          <FormItem label="Raison sociale/Adresse">
            <TextArea
              value={justificatif.sDAPAdressePrescripteur}
              name="sDAPAdressePrescripteur"
              onChange={onChange}
              rows={4}
            />
          </FormItem>

          <FormItem label="N° structure">
            <InputNumber
              value={justificatif.sDAPAdeliPrescripteur}
              name="sDAPAdeliPrescripteur"
              onChange={onChange}
              type="number"
            />

            <Indication>(AM, FINESS ou SIRET)</Indication>
          </FormItem>
        </Adresse>

        <StyledDate label="Date">
          <DatePicker
            value={
              prescription
                ? moment(prescription.dDateSignature)
                : moment(new Date())
            }
            disabled={!bDateSignature}
            placeholder="jj/mm/aaaa"
            name="dDateSignature"
            format="DD/MM/YYYY"
            locale={locale}
            onChange={(momentDate) => {
              if (prescription && momentDate) {
                setPrescription({
                  ...prescription,
                  dDateSignature: new Date(momentDate.format("YYYY-MM-DD")),
                  bUpdated: true,
                });
              }
            }}
          />
        </StyledDate>
        <Signature>
          <FormItem label="Signature"></FormItem>
        </Signature>
      </Body>
    </>
  );
}
