import type { Action } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import { verify as verifyJwt } from "jsonwebtoken";

import { connexionSucces, connexionErreur } from "../utilisateur.slice";
import type { Identite } from "../../../interfaces/utilisateur.interface";
import config from "../../../config";

export interface Response {
  user: Identite;
  cookie: string;
}

export function connexionAction(
  token: string
): ThunkAction<void, Identite, unknown, Action<string>> {
  return async (dispatch) => {
    if (!token) {
      dispatch(connexionErreur({ sErreur: "Token non trouvé" }));
    } else {
      try {
        const payload = verifyJwt(token, `${config.jwtPUBLIC}`);

        document.cookie = `Authorization=${token}; path=/;`;

        dispatch(connexionSucces({ user: payload as Identite, token }));
      } catch {
        document.cookie =
          "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        dispatch(connexionErreur({ sErreur: "Token invalide" }));
      }
    }
  };
}
