import { FunctionComponent } from "react";
import styled from "styled-components";
import warning from "../../assets/icons/warning.svg";

import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../redux/modal/modal.slice";

import WhiteModal from "../../components/atoms/modals/WhiteModal";
import {
  Text,
  PrimaryButton,
} from "@ads/ui";
import { selectDateSignatureEditable } from "../../redux/config/config.slice";

const Wrapper = styled.div`
  position: relative;
`;

interface PopconfirmTitleProps {
  dDateSignature?: Date;
  dDebut?: Date;
  sendContent: Function;
  messageGlobal: string;
}

const StyledSvg = styled.div`
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${(props) => props.theme.colors.secondary[100]};
  border-radius: 50%;
`;

const Warning = styled.img`
  background-color: ${(props) => props.theme.colors.secondary[100]};
`;

const StyledButton = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 16px;
`;

const ModalConfirm: FunctionComponent<PopconfirmTitleProps> = ({
  dDateSignature,
  dDebut,
  sendContent,
  messageGlobal,
}) => {
  const bDateSignature = useSelector(selectDateSignatureEditable);

  let debutPrescriptionDate = "";
  const existNewDate = () =>
    dDateSignature && dDebut
      ? new Date(dDateSignature).getTime() > new Date(dDebut).getTime()
      : false;

  if (dDebut) {
    debutPrescriptionDate = new Date(dDebut).toLocaleDateString();
  }
  const dispatch = useDispatch();
  const handleClickSend = () => {
    sendContent();
    dispatch(toggleModal());
  };
  const handleClickBack = () => {
    dispatch(toggleModal());
  };
  return (
    <Wrapper>
      <WhiteModal>
        <StyledSvg>
          <Warning src={warning} />
        </StyledSvg>
        <Text
          label={messageGlobal}
          sColor={{ style: "neutral", variant: 170 }}
          fontWeight="600"
          marginText="10px 0 20px 0"
          fontSize="1.6rem"
        />
        {existNewDate() && !bDateSignature && (
          <>
            <Text
              label="Attention !"
              sColor={{ style: "danger", variant: 100 }}
              fontWeight="600"
              marginText="10px 0 20px 0"
              fontSize="1.6rem"
            />
            <Text
              label="La date de début de
              la prescription est antérieure à la date de signature du document."
              sColor={{ style: "neutral", variant: 170 }}
              fontWeight="600"
              textAlign="center"
              marginText="10px"
              fontSize="1.6rem"
            />
            <Text
              label={` Vous pouvez modifier à la date de début de prescription qui est le ${debutPrescriptionDate}.`}
              sColor={{ style: "neutral", variant: 170 }}
              fontWeight="600"
              textAlign="center"
              marginText="10px"
            />
          </>
        )}
        <Text
          label="Voulez-vous continuer ?"
          sColor={{ style: "neutral", variant: 170 }}
          fontWeight="600"
          textAlign="center"
          marginText="10px"
        />
        <StyledButton>
          <PrimaryButton
            as="button"
            size="S"
            label="Annuler"
            buttonStyle="danger"
            type="submit"
            minWidth="200px"
            onClick={() => handleClickBack()}
          />
          <PrimaryButton
            as="button"
            size="S"
            label="Continuer"
            buttonStyle="primary"
            type="submit"
            minWidth="200px"
            onClick={() => handleClickSend()}
          />
        </StyledButton>
      </WhiteModal>
    </Wrapper>
  );
};

export default ModalConfirm;
