import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FormItemWrapper, Label, PrimaryButton, Textarea } from "@ads/ui";
import { notification } from "antd";
// Slices
import { toggleModal } from "../../redux/modal/modal.slice";
import { selectUtilisateur } from "../../redux/utilisateur/utilisateur.slice";
// Design
import BlackModal from "../atoms/modals/BlackModal";
// APi
import { deletePrescription } from "../../api/prescriptions.api";

const WrapperForm = styled("div")`
  padding: 5.5rem 15rem;
`;

const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 55px 0 0 0;
`;

interface Props {
  iPKPrescription: number;
  getPrescriptionsFromIdentity: Function;
}

export default function FormRemovePrescription({
  iPKPrescription,
  getPrescriptionsFromIdentity,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const identity = useSelector(selectUtilisateur);
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(toggleModal());
  };

  const [deleteReason, setDeleteReason] = useState("");

  const onSubmit = async (deleteReason: any) => {
    try {
      setIsLoading(true);
      await deletePrescription(
        identity?.iFKAnnuaire as number,
        iPKPrescription,
        { sRaisonSuppression: deleteReason }
      );
      notification.success({
        description:
          "La suppression de votre renouvellement a bien été prise en compte",
        message: "Succès !",
      });
      dispatch(toggleModal());
      getPrescriptionsFromIdentity(identity);
    } catch (error) {
      notification.error({
        description: `Nous rencontrons une erreur à la suppression de votre renouvellement, veuillez contacter votre administrateur`,
        message: `Echec de l'enregistrement du formulaire`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BlackModal>
      <WrapperForm>
        <FormItemWrapper>
          <Label htmlFor="sRaisonSuppression">
            Raison de votre suppression
          </Label>
          <Textarea
            id="sRaisonSuppression"
            name="sRaisonSuppression"
            value={deleteReason}
            onChange={(e: any) => {
              setDeleteReason(e.target.value);
            }}
          />
        </FormItemWrapper>
        <WrapperButtons>
          <PrimaryButton
            label="Annuler"
            as="button"
            size="S"
            buttonStyle="danger"
            type="reset"
            minWidth="157px"
            onClick={handleCancel}
          />
          <PrimaryButton
            as="button"
            label="Valider"
            size="S"
            buttonStyle="primary"
            type="submit"
            minWidth="157px"
            disabled={isLoading}
            onClick={() => onSubmit(deleteReason)}
          />
        </WrapperButtons>
      </WrapperForm>
    </BlackModal>
  );
}
