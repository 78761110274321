import styled from "styled-components";
import { Text } from "@ads/ui";

export const StyledLayout = styled.div`
  background: ${(props) => props.theme.colors.neutral[110]};
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

export const TitlePanel = styled(Text)`
  margin-top: 2rem;
  padding-left: 2.3rem;
  text-transform: uppercase;
`;

export const ContentCenter = styled.div`
  width: 115rem;
  margin: 0rem auto 10rem auto;
  height: 100%;
`;

export const WrapperTabsHeader = styled.div`
  width: 100%;
  left: 0;
  z-index: 500;
`;

export const WrapperDocument = styled.div`
  max-width: 55rem;
  margin-bottom: 0px;
`;

export const TabsContentWrapper = styled("div")`
  height: 100%;
`;

export const OverFlowWrapper = styled("div")`
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  scrollbar-width: 5px;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${(props) => props.theme.colors.neutral[140]};
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${(props) => props.theme.colors.neutral[120]};
  }
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.neutral[140]}
    ${(props) => props.theme.colors.neutral[120]};
`;

export const FixedLeftWrapper = styled.div`
  position: fixed;
  top: 185px;
  z-index: 1500;
`;

export const FixedRightWrapper = styled.div`
  position: fixed;
  top: 185px;
  right: 0;
  z-index: 1500;
  blur: 1;
`;

export const LeftTextWrapper = styled.div`
  padding: 0 0 20px 22px;
`;

export const RightTextWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 0 12px 0 0;
`;

export const WrapperButton = styled.div`
  position: fixed;
  left: 16%;
  display: block;
  border-radius: 2.3rem;
  background: ${(props) => props.theme.colors.neutral[180]};
  z-index: 999;
`;

export const displayTabContent = (event: any) => {
  // Add active class to current tab header item
  event.target.classList.add("active");

  const allTabContent = document.querySelectorAll(".tab-content");
  allTabContent.forEach((tabElement) => {
    tabElement.classList.remove("active");
  });

  // Display tab content element in target
  const tabContent = document.querySelector(`#${event.target.dataset.target}`);

  tabContent && tabContent.classList.add("active");
};
