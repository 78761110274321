import type { ChangeEvent as ReactChangeEvent } from "react";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";

import type { IOrdonnancePPCRenouvellement } from "../../../../interfaces/ordonnance.interface";
import { Wrapper } from "../ordonnance.style";
import Footer from "../footer.elements";
import { IPrescriptionRenouvellement } from "../../../../interfaces/prescription.interface";
import { TemplatePrescription } from "./form/template/TemplatePrescription";

export interface OrdonnancePPCProps {
  bDateSignature: boolean;
  onChange: (event: ChangeEvent) => void;
  ordonnance: IOrdonnancePPCRenouvellement;
  resetSelectedModele: () => void;
  selectedModele?: string;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}

type ChangeEvent =
  | CheckboxChangeEvent
  | ReactChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export default function OrdonnancePPC({
  bDateSignature,
  onChange,
  ordonnance,
  resetSelectedModele,
  selectedModele,
  prescription,
  setPrescription,
}: OrdonnancePPCProps) {
  return (
    <Wrapper>
      <TemplatePrescription
        {...({
          bDateSignature,
          onChange,
          ordonnance,
          resetSelectedModele,
          selectedModele,
          prescription,
          setPrescription,
        } as OrdonnancePPCProps)}
      />
      <Footer
        bDateSignature={bDateSignature}
        prescription={prescription}
        setPrescription={setPrescription}
      />
    </Wrapper>
  );
}
