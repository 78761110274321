import axios from 'axios';
import config from '../config';

import type { Synthese } from '../interfaces/synthese.interface';

export async function getSynthesePatients(iPKAnnuaire: number): Promise<Synthese[]> {
  const { data } = await axios.get(
    `${config.serverURL}/medecins/${iPKAnnuaire}/patients/synthese`,
    { withCredentials: true }
  );
  return data;
}

export async function getSynthesePrescriptions(iPKAnnuaire: number): Promise<Synthese[]> {
  const { data } = await axios.get(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/synthese`,
    { withCredentials: true }
  );
  return data;
}
