import styled from "styled-components";

const BlackModal = styled("div")`
  /* padding: 55px 150px; */
  border-radius: 20px;
  background: ${(props) => props.theme.colors.neutral[190]};
  box-shadow: 0px 3px 40px #0a244343;

  max-height: 90vh;
  overflow: auto;
  scrollbar-width: thin;
`;

export default BlackModal;
