import { createSlice } from "@reduxjs/toolkit";

export interface ModalState {
  isOpen: Boolean;
  setContentModal: () => void;
}

const initialState: ModalState = {
  isOpen: false,
  setContentModal: () => {},
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleModal(state) {
      state.isOpen = !state.isOpen;
    },
    createSetModalFunction(state, action) {
      state.setContentModal = action.payload;
    },
  },
});

export const { toggleModal, createSetModalFunction } = modalSlice.actions;
export const selectContentModal = (state: any) => state.modal.setContentModal;
export const selectIsModalOpen = (state: any) => state.modal.isOpen;

export default modalSlice.reducer;
