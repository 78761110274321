import { IPrescriptionRenouvellement } from "../interfaces/prescription.interface";

export const newPrescription: IPrescriptionRenouvellement = {
  dDebut: new Date(),
  dDateSignature: new Date(),
  iFKAnnuairePatient: 0,
  iPKprescription: 0,
  oJustificatifs: {},
  sCodePatient: "",
  bAutorizeJointDoc: true,
};

export const newDepData = {
  dDateNaissance: null,
  iPKprescription: null,
  iFKAnnuairePatient: null,
  rDebitOXYC: null,
  rDebitOXYG: null,
  rDebitOXYL: null,
  rDebitVENEMBUC: null,
  rDebitVENMANAS: null,
  rDebitVENPPC: null,
  rDebitVENPROTHES: null,
  rDebitVENTRACHEO: null,
  rDureeOXYC: null,
  rDureeOXYG: null,
  rDureeOXYL: null,
  rDureeVENEMBUC: null,
  rDureeVENMANAS: null,
  rDureeVENPPC: null,
  rDureeVENPROTHES: null,
  rDureeVENTRACHEO: null,
  rPHEffort: null,
  rPHRA1: null,
  rPHRA2: null,
  rPHRO2: null,
  rPaCO2Effort: null,
  rPaCO2RA1: null,
  rPaCO2RA2: null,
  rPaCO2RO2: null,
  rPaO2Effort: null,
  rPaO2RA1: null,
  rPaO2RA2: null,
  rPaO2RO2: null,
  rSaO2Effort: null,
  rSaO2RA1: null,
  rSaO2RA2: null,
  rSaO2RO2: null,
  rTauxHematocrite: null,
  sAdresseAssociationCPVille: null,
  sAdresseAssociationRue: null,
  sAdresseAssureCPVille: "",
  sAdresseAssureRue: "",
  sAdressePrescripteur: null,
  sAdresseRO: null,
  sAutreEtiologie: null,
  sAutreSpecialite: null,
  sCapaciteTotale: null,
  sCapaciteVitale: null,
  sCasParticulier: "",
  sCleSecuriteSociale: null,
  sCocheALDHorsListeNon: null,
  sCocheALDHorsListeOui: null,
  sCocheALDListeNon: null,
  sCocheALDListeOui: "",
  sCocheAccidentNon: "",
  sCocheAccidentOui: "",
  sCocheActiviteNon: "",
  sCocheActiviteOui: "",
  sCocheAssociation: "",
  sCocheAutreSpecialite: null,
  sCocheGazkPa: null,
  sCocheGazmmHg: null,
  sCocheGeneraliste: null,
  sCocheIVDActuelle: "",
  sCocheIVDAntecedent: "",
  sCocheLienAutre: "",
  sCocheLienConjoint: "",
  sCocheLienEnfant: "",
  sCocheLienMari: "",
  sCochePensionneGuerre: "",
  sCochePneumologue: null,
  sCochePresciInit: "X",
  sCochePrescriModif: "",
  sCochePrescriProlong: "",
  sCochePrincAPNEE: null,
  sCochePrincASTHME: null,
  sCochePrincAUTRE: null,
  sCochePrincBRONCHITE: null,
  sCochePrincDILATBR: null,
  sCochePrincDYSPLASIE: null,
  sCochePrincEMPHYSEME: null,
  sCochePrincFIBROSE: null,
  sCochePrincLESION: null,
  sCochePrincMUCO: null,
  sCochePrincMYOPATHE: null,
  sCochePrincNEUROMUSC: null,
  sCochePrincPNEUMO: null,
  sCochePrincSCOLIOSE: null,
  sCocheReanimateur: null,
  sCocheSecAPNEE: null,
  sCocheSecASTHME: null,
  sCocheSecAUTRE: null,
  sCocheSecBRONCHITE: null,
  sCocheSecDILATBR: null,
  sCocheSecDYSPLASIE: null,
  sCocheSecEMPHYSEME: null,
  sCocheSecFIBROSE: null,
  sCocheSecLESION: null,
  sCocheSecMUCO: null,
  sCocheSecMYOPATHE: null,
  sCocheSecNEUROMUSC: null,
  sCocheSecPNEUMO: null,
  sCocheSecSCOLIOSE: null,
  sCocheSexeF: "",
  sCocheSexeM: "",
  sCocheSituationAutre: "",
  sCocheSituationNonSalarie: "",
  sCocheSituationPension: "",
  sCocheSituationSalarie: "",
  sCocheSituationSans: "",
  sCocheTabacJamais: "",
  sCocheTabacNon: "",
  sCocheTabacOui: "",
  sCocheTpASP: null,
  sCocheTpAUTRE: null,
  sCocheTpDeambulationOXYC: null,
  sCocheTpDeambulationOXYG: null,
  sCocheTpDeambulationOXYL: null,
  sCocheTpOXYC: null,
  sCocheTpOXYG: null,
  sCocheTpOXYL: null,
  sCocheTpPosteFixeOXYC: null,
  sCocheTpPosteFixeOXYG: null,
  sCocheTpPosteFixeOXYL: null,
  sCocheTpVENEMBUC: null,
  sCocheTpVENMANAS: null,
  sCocheTpVENPPC: null,
  sCocheTpVENPROTHES: null,
  sCocheTpVENTRACHEO: null,
  sCodePatient: "",
  sDateAccident: "",
  sDateDebut: "",
  sDateFin: "",
  sDateGazEffort: null,
  sDateGazRA1: null,
  sDateGazRA2: null,
  sDateGazRO2: null,
  sDateSituationAssure: "",
  sDateSpiro: null,
  sFKCodePrescription: "",
  sFiness: null,
  sFinessAntenne: null,
  sInformation: null,
  sMarqueAutre: null,
  sMarqueConcentrateur: null,
  sMarqueMatOXYL: null,
  sMarqueRespirateur: null,
  sNoSecuriteSociale: null,
  sNomAssociation: null,
  sNomAssure: "",
  sNomPatient: "",
  sNomPrescripteur: null,
  sNomRO: null,
  sPoids: null,
  sPrenomAssure: "",
  sPrenomPatient: "",
  sSituationAutre: null,
  sTPAutre: null,
  sTPInfo: null,
  sTaille: null,
  sTypeAutre: null,
  sTypeConcentrateur: null,
  sTypeMatOXYL: null,
  sTypeRespirateur: null,
  sVEMS: null,
  sTypeJustificatif: "DEP",
};

export const newDapData = {
  dDateNaissance: null,
  iPKprescription: null,
  iFKAnnuairePatient: null,
  rDebitOXYC: null,
  rDebitOXYG: null,
  rDebitOXYL: null,
  rDebitVENEMBUC: null,
  rDebitVENMANAS: null,
  rDebitVENPPC: null,
  rDebitVENPROTHES: null,
  rDebitVENTRACHEO: null,
  rDureeOXYC: null,
  rDureeOXYG: null,
  rDureeOXYL: null,
  rDureeVENEMBUC: null,
  rDureeVENMANAS: null,
  rDureeVENPPC: null,
  rDureeVENPROTHES: null,
  rDureeVENTRACHEO: null,
  rPHEffort: null,
  rPHRA1: null,
  rPHRA2: null,
  rPHRO2: null,
  rPaCO2Effort: null,
  rPaCO2RA1: null,
  rPaCO2RA2: null,
  rPaCO2RO2: null,
  rPaO2Effort: null,
  rPaO2RA1: null,
  rPaO2RA2: null,
  rPaO2RO2: null,
  rSaO2Effort: null,
  rSaO2RA1: null,
  rSaO2RA2: null,
  rSaO2RO2: null,
  rTauxHematocrite: null,
  sAdresseAssociationCPVille: null,
  sAdresseAssociationRue: null,
  sAdresseAssureCPVille: "",
  sAdresseAssureRue: "",
  sAdressePrescripteur: null,
  sAdresseRO: null,
  sAutreEtiologie: null,
  sAutreSpecialite: null,
  sCapaciteTotale: null,
  sCapaciteVitale: null,
  sCasParticulier: "",
  sCleSecuriteSociale: null,
  sCocheALDHorsListeNon: null,
  sCocheALDHorsListeOui: null,
  sCocheALDListeNon: null,
  sCocheALDListeOui: "",
  sCocheAccidentNon: "",
  sCocheAccidentOui: "",
  sCocheActiviteNon: "",
  sCocheActiviteOui: "",
  sCocheAssociation: "",
  sCocheAutreSpecialite: null,
  sCocheGazkPa: null,
  sCocheGazmmHg: null,
  sCocheGeneraliste: null,
  sCocheIVDActuelle: "",
  sCocheIVDAntecedent: "",
  sCocheLienAutre: "",
  sCocheLienConjoint: "",
  sCocheLienEnfant: "",
  sCocheLienMari: "",
  sCochePensionneGuerre: "",
  sCochePneumologue: null,
  sCochePresciInit: "X",
  sCochePrescriModif: "",
  sCochePrescriProlong: "",
  sCochePrincAPNEE: null,
  sCochePrincASTHME: null,
  sCochePrincAUTRE: null,
  sCochePrincBRONCHITE: null,
  sCochePrincDILATBR: null,
  sCochePrincDYSPLASIE: null,
  sCochePrincEMPHYSEME: null,
  sCochePrincFIBROSE: null,
  sCochePrincLESION: null,
  sCochePrincMUCO: null,
  sCochePrincMYOPATHE: null,
  sCochePrincNEUROMUSC: null,
  sCochePrincPNEUMO: null,
  sCochePrincSCOLIOSE: null,
  sCocheReanimateur: null,
  sCocheSecAPNEE: null,
  sCocheSecASTHME: null,
  sCocheSecAUTRE: null,
  sCocheSecBRONCHITE: null,
  sCocheSecDILATBR: null,
  sCocheSecDYSPLASIE: null,
  sCocheSecEMPHYSEME: null,
  sCocheSecFIBROSE: null,
  sCocheSecLESION: null,
  sCocheSecMUCO: null,
  sCocheSecMYOPATHE: null,
  sCocheSecNEUROMUSC: null,
  sCocheSecPNEUMO: null,
  sCocheSecSCOLIOSE: null,
  sCocheSexeF: "",
  sCocheSexeM: "",
  sCocheSituationAutre: "",
  sCocheSituationNonSalarie: "",
  sCocheSituationPension: "",
  sCocheSituationSalarie: "",
  sCocheSituationSans: "",
  sCocheTabacJamais: "",
  sCocheTabacNon: "",
  sCocheTabacOui: "",
  sCocheTpASP: null,
  sCocheTpAUTRE: null,
  sCocheTpDeambulationOXYC: null,
  sCocheTpDeambulationOXYG: null,
  sCocheTpDeambulationOXYL: null,
  sCocheTpOXYC: null,
  sCocheTpOXYG: null,
  sCocheTpOXYL: null,
  sCocheTpPosteFixeOXYC: null,
  sCocheTpPosteFixeOXYG: null,
  sCocheTpPosteFixeOXYL: null,
  sCocheTpVENEMBUC: null,
  sCocheTpVENMANAS: null,
  sCocheTpVENPPC: null,
  sCocheTpVENPROTHES: null,
  sCocheTpVENTRACHEO: null,
  sCodePatient: "",
  sDateAccident: "",
  sDateDebut: "",
  sDateFin: "",
  sDateGazEffort: null,
  sDateGazRA1: null,
  sDateGazRA2: null,
  sDateGazRO2: null,
  sDateSituationAssure: "",
  sDateSpiro: null,
  sFKCodePrescription: "",
  sFiness: null,
  sFinessAntenne: null,
  sInformation: null,
  sMarqueAutre: null,
  sMarqueConcentrateur: null,
  sMarqueMatOXYL: null,
  sMarqueRespirateur: null,
  sNoSecuriteSociale: null,
  sNomAssociation: null,
  sNomAssure: "",
  sNomPatient: "",
  sNomPrescripteur: null,
  sNomRO: null,
  sPoids: null,
  sPrenomAssure: "",
  sPrenomPatient: "",
  sSituationAutre: null,
  sTPAutre: null,
  sTPInfo: null,
  sTaille: null,
  sTypeAutre: null,
  sTypeConcentrateur: null,
  sTypeMatOXYL: null,
  sTypeRespirateur: null,
  sVEMS: null,
  sTypeJustificatif: "DAP",
};
