import axios from 'axios';

import type {
  NewModeleTexte, UpdateModeleTexte
} from '../interfaces/modeleTexte.interface';
import type ModeleTexte from '../interfaces/modeleTexte.interface';
import config from '../config';

export async function getModelesTexte(iPKAnnuaire: number): Promise<ModeleTexte[]> {
  const { data } = await axios.get(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/modeles`,
    { withCredentials: true }
  );
  return data;
}

export async function addModeleTexte(iPKAnnuaire: number, newModeleTexte: NewModeleTexte): Promise<ModeleTexte> {
  const { data } = await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/modeles`,
    newModeleTexte,
    { withCredentials: true }
  );
  return data as ModeleTexte;
}

export async function deleteModeleTexte(iPKAnnuaire: number, iPKModeleTexte: number) {
  await axios.delete(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/modeles/${iPKModeleTexte}`,
    { withCredentials: true }
  );
}

export async function updateModeleTexte(iPKAnnuaire: number, iPKModeleTexte: number, newData: UpdateModeleTexte) {
  const { data } = await axios.patch(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/modeles/${iPKModeleTexte}`,
    newData,
    { withCredentials: true }
  );
  return data;
}
