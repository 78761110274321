import styled from "styled-components";
import PrescriptionsAttente from "../../molecules/prescriptions/PrescriptionsAttente";
import PrescriptionsEnCours from "../../molecules/prescriptions/PrescriptionsEnCours";
import IndexProfil from "../../molecules/profil/IndexProfil";

const Grid = styled("section")`
  padding: 37px 0;
  display: grid;
  grid-template-columns: 552px 552px auto;
  min-height: 420px;

  @media screen and (max-width: 1530px) {
    grid-template-columns: auto auto auto;
  }
`;

const PrescriptionProfil = () => {
  return (
    <Grid>
      <PrescriptionsAttente />
      <PrescriptionsEnCours />
      <IndexProfil />
    </Grid>
  );
};

export default PrescriptionProfil;
