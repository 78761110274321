import React from "react";
import styled from "styled-components";

const Span = styled("span")`
  position: absolute;
  bottom: -19px;
  left: 20px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.danger[100]};
`;

interface PropsErrorFormMsg {
  children: React.ReactNode;
}

const ErrorFormMsg = ({ children }: PropsErrorFormMsg) => {
  return <Span>{children}</Span>;
};

export default ErrorFormMsg;
