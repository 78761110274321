import { useSelector, useDispatch } from "react-redux";
import remove from "../../../assets/icons/delete.svg";
import waiting from "../../../assets/icons/hourglass_bl.svg";
import { notification, Table, Tooltip } from "antd";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
// slice
import { toggleFilter } from "../../../redux/filter/filter.slice";
import {
  toggleModal,
  selectContentModal,
} from "../../../redux/modal/modal.slice";

import type { ColumnsType } from "antd/lib/table";
import type { IPrescription } from "../../../interfaces/prescription.interface";
import { selectMedecinInfos } from "../../../redux/utilisateur/utilisateur.slice";
import { computeRemainingDays } from "../../../utils/date";

import config from "../../../config";
import FormRemoveSelectedPrescription from "../../molecules/FormRemoveSelectedPrescription";
import { DeadIcon, OutIcon } from "./TablePrescriptions";

/* eslint-disable @typescript-eslint/no-unused-vars */
const FilterButton = styled.button`
  z-index: 120;
  position: absolute;
  top: -44px;
  right: 202px;
  width: 195px;
  height: 44px;
  border: none;
  cursor: pointer;
  background: ${(props) => props.theme.colors.neutral[100]} 0% 0% no-repeat
    padding-box;
  transform: perspective(10px) rotateX(1deg);
  border-radius: 15px 15px 0 0;
`;

const CustomTable = styled(Table)`
  position: relative;
  z-index: 110;
  border-radius: 20px 20px 0px 0px;
  cursor: pointer;

  .ant-table-tbody {
    background-color: ${(props) => props.theme.colors.neutral[100]};
  }

  .ant-table {
    color: ${(props) => props.theme.colors.neutral[190]};
    background: transparent;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  .ant-table-content {
    min-height: 340px;
  }

  .ant-table-thead > tr > th {
    background-color: ${(props) => props.theme.colors.neutral[100]};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${(props) => props.theme.colors.neutral[120]};
  }

  .disabled-row {
    background-color: #eee;
    pointer-events: none;
  }

  .disable-row:hover:after {
    content: " Document en cours de finalisation";
    font-size: 16px;
    font-weight: 800;
  }
`;

const RemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  gap: 10px;
  border-radius: 20px;
  font-weight: 400;
  transition: all 0.2s linear;
  border: none;
  background-color: #fff;
  &:disabled {
    opacity: 0.75;
    background-color: ${(props) => props.theme.colors.neutral[110]};
    cursor: not-allowed !important;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.neutral[110]};
    cursor: pointer;
    &:before {
      stroke: #fff;
    }
  }
  &:before {
    content: "";
    display: block;
    background-image: url(${remove});
    width: 18px;
    height: 18px;
  }
`;

const WaitingIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  gap: 10px;
  border-radius: 20px;
  font-weight: 400;
  transition: all 0.2s linear;
  border: none;
  background-color: #fff;
  &:disabled {
    opacity: 0.75;
    background-color: ${(props) => props.theme.colors.neutral[110]};
    cursor: not-allowed !important;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.neutral[110]};
    cursor: pointer;
    &:before {
      stroke: #fff;
    }
  }
  &:before {
    content: "";
    display: block;
    background-image: url(${waiting});
    width: 18px;
    height: 18px;
  }
`;

const LateRemainingDays = styled.div`
  padding: 0.3rem 0.5rem;
  border-radius: 0.6rem;
  background-color: ${(props) => props.theme.colors.danger[100]};
  color: ${(props) => props.theme.colors.neutral[100]};
  text-align: center;
  white-space: nowrap;
`;

const StateDiv = styled.div`
  color: ${(props) => props.theme.colors.danger[100]};
`;

export type PrescriptionsColumn = Omit<IPrescription, "oPatient"> &
  IPrescription["oPatient"];

const sTypeSequenceToTypePrescription: any = {
  In: "Initiale",
  Pm: "Modifiée",
  Pr: "Renouvellement",
};

interface Props {
  prescriptions: IPrescription[];
  listRenouvellement: PrescriptionsColumn[] | undefined;
  //getPrescriptionsFromIdentity: Function;
  getSelectedPrescriptions: Function;
  getMenuId: Function;
  getPrescriptionsASignerFromIdentity: Function;
  //enableSignButton: Function;
  setPrescriptions: React.Dispatch<React.SetStateAction<IPrescription[]>>;
  //element: useRef<null | HTMLElement>(null);
}
export default function TablePrescriptionsASigner({
  listRenouvellement,
  getSelectedPrescriptions,
  getMenuId,
  getPrescriptionsASignerFromIdentity /*, enableSignButton*/,
}: Props) {
  const dispatch = useDispatch();
  const medecin = useSelector(selectMedecinInfos);
  const etats = ["initial", "à signer", "en attente", "échoué", "en attente"];
  const history = useHistory();

  const tableColumns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "sCodeCivilite",
      key: "sCodeCivilite",
    },

    {
      title: "Nom",
      dataIndex: "sNom",
      key: "sNom",
      showSorterTooltip: false,
      render: (record) => record,
      sorter: (a, b) => {
        if (a.sNom < b.sNom) return -1;
        if (a.sNom > b.sNom) return 1;
        return 0;
      },
    },
    {
      title: "Prénom",
      dataIndex: "sPrenom",
      key: "sPrenom",
      showSorterTooltip: false,
      render: (record) => record,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => {
        if (a.sPrenom < b.sPrenom) return -1;
        if (a.sPrenom > b.sPrenom) return 1;
        return 0;
      },
    },
    {
      title: "Naissance",
      dataIndex: "dDateNaissance",
      key: "dDateNaissance",
      render: (record) => new Date(record).toLocaleDateString(),
      sorter: (a, b) =>
        new Date(a.dDateNaissance).getTime() -
        new Date(b.dDateNaissance).getTime(),
    },
    {
      title: "Statut",
      dataIndex: "bSorti",
      key: "bSorti",
      render: (_, record: PrescriptionsColumn) => {
        return (
          <Tooltip
            placement="topLeft"
            title={
              record.bDecede
                ? "Patient Décédé"
                : record.bSorti
                ? "Patient Sorti"
                : "Patient OK"
            }
            arrowPointAtCenter
          >
            {record.bDecede ? (
              <DeadIcon />
            ) : record.bSorti ? (
              <OutIcon />
            ) : (
              "Patient sous traitement"
            )}
          </Tooltip>
        );
      },
      sorter: (a, b) => {
        if (a.bDecede || a.bSorti) return -1;
        if (!a.bDecede && !a.bSorti) return 1;
        return 0;
      },
    },
    {
      title: "Forfait",
      dataIndex: "sFKCodePrescription",
      key: "sFKCodePrescription",
      sorter: (a, b) => {
        if (a.sFKCodePrescription < b.sFKCodePrescription) return -1;
        if (a.sFKCodePrescription > b.sFKCodePrescription) return 1;
        return 0;
      },
    },
    {
      title: "Type prescription",
      dataIndex: "sTypeSequence",
      key: "sTypeSequence",
      showSorterTooltip: false,
      render: (record) => sTypeSequenceToTypePrescription[record],
      sorter: (a, b) => {
        if (a.sTypeSequence < b.sTypeSequence) return -1;
        if (a.sTypeSequence > b.sTypeSequence) return 1;
        return 0;
      },
    },
    {
      title: "Début prescription",
      dataIndex: "dDebut",
      key: "dDebut",
      showSorterTooltip: false,
      render: (record) => new Date(record).toLocaleDateString(),
      sorter: (a, b) =>
        new Date(a.dDebut).getTime() - new Date(b.dDebut).getTime(),
    },
    {
      title: "Fin prescription",
      dataIndex: "dFin",
      key: "dFin",
      showSorterTooltip: false,
      render: (record) => new Date(record).toLocaleDateString(),
      sorter: (a, b) => new Date(a.dFin).getTime() - new Date(b.dFin).getTime(),
    },
    {
      title: "Jours restants",
      dataIndex: "sJoursRestant",
      key: "sJoursRestant",
      showSorterTooltip: false,
      render: (_, record: PrescriptionsColumn) => {
        const remainingDays = computeRemainingDays(record.dFin).toFixed(0);
        return remainingDays.startsWith("-") ? (
          <LateRemainingDays>
            {remainingDays.slice(1)} jours de retard
          </LateRemainingDays>
        ) : (
          remainingDays
        );
      },
      sorter: (a, b) =>
        computeRemainingDays(a.dFin) - computeRemainingDays(b.dFin),
    },
    {
      title: "Prochain rendez-vous médical",
      dataIndex: "dProchainRDV",
      key: "dProchainRDV",
      showSorterTooltip: false,
      render: (record) => (record ? new Date(record).toLocaleDateString() : ""),
      sorter: (a, b) =>
        new Date(a.dProchainRDV).getTime() - new Date(b.dProchainRDV).getTime(),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record: PrescriptionsColumn) => {
        // Ajout d'une condition pour afficher la corbeille (prescriptions non signées)
        if (record.etatPrescription === 1) {
          return (
            <RemoveButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(toggleModal());
                setContentModal(
                  // <FormRemoveSelectedPrescription
                  //   getPrescriptionsFromIdentity={getPrescriptionsFromIdentity}
                  //   iPKPrescription={record.iPKPrescription}
                  // />
                  <FormRemoveSelectedPrescription
                    getPrescriptionsASignerFromIdentity={
                      getPrescriptionsASignerFromIdentity
                    }
                    iPKPrescription={record.iPKPrescription}
                  />
                );
              }}
            />
          );
        }
        // signé et en attente
        //else if(record.etatPrescription === 3 || record.etatPrescription !== 1){
        else if (record.etatPrescription === 4) {
          return (
            <div>
              {etats[record.etatPrescription]}
              <WaitingIcon />
            </div>
          );
          //return (<div>{sms} <WaitingIcon /></div>)
        }
        // else if(record.etatPrescription === 3){
        //   return (<StateDiv>{etats[record.etatPrescription]}</StateDiv>)
        // }
        else if (record.etatPrescription === 5) {
          return (
            <StateDiv>
              {etats[record.etatPrescription]}
              <RemoveButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(toggleModal());
                  setContentModal(
                    // <FormRemoveSelectedPrescription
                    //   getPrescriptionsFromIdentity={getPrescriptionsFromIdentity}
                    //   iPKPrescription={record.iPKPrescription}
                    // />
                    <FormRemoveSelectedPrescription
                      getPrescriptionsASignerFromIdentity={
                        getPrescriptionsASignerFromIdentity
                      }
                      iPKPrescription={record.iPKPrescription}
                    />
                  );
                }}
              />
            </StateDiv>
          );
        }
      },
    },
  ];

  const handleClick = () => {
    dispatch(toggleFilter());
  };

  const handleClickRenouvellement = (item: number) =>
    history.push(`/renouvellement/${item}`);

  const handleScroll = () => {
    setTimeout(function () {
      document!.querySelector(".ant-pagination")!.scrollIntoView({
        behavior: "smooth",
      });
    }, 50);
  };

  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);

  let serializedPrescriptionsIndex: number[] = [];
  if (listRenouvellement !== undefined) {
    for (let i = 0; i < listRenouvellement.length; i++) {
      serializedPrescriptionsIndex[i] = i;
    }
  }

  let selectedPrescriptions: any[];
  let nbRowsSelected = 0;

  const rowSelection = {
    //selectedRowKeys,
    onChange: (selectedRows: any) => {
      // @FIX pour récupérer les ipk Prescription
      if (listRenouvellement !== undefined) {
        selectedPrescriptions = [];
        for (let p of selectedRows) {
          selectedPrescriptions.push(listRenouvellement[p]);
        }
      }
      getSelectedPrescriptions(selectedPrescriptions);
    },
    getCheckboxProps: (record: any) => ({
      disabled: nbRowsSelected >= 5,
      // Column configuration not to be checked
      name: record.name,
    }),
    onClick: (e: any) => {
      e.stopPropagation();
    },
    // onChange: (selectedRowKeys: any, selectedRows: any) => {
    //   setSelectedRowKeys(selectedRows);
    // }
  };

  return (
    <>
      {/* <FilterButton onClick={() => handleClick()}>
        {isFilterOpen ? "Masquer" : "Afficher"} les filtres
      </FilterButton> */}

      <CustomTable
        columns={tableColumns}
        rowSelection={
          config.oodriveSignatureActivated || config.signatureClassiqueMultiple
            ? { ...rowSelection }
            : undefined
        }
        pagination={{
          defaultPageSize: 9,
          position: ["bottomLeft"],
        }}
        dataSource={listRenouvellement}
        onRow={(record: any) => ({
          onClick: () =>
            medecin?.sSignature?.length
              ? handleClickRenouvellement(record.iPKPrescription)
              : notification.error({
                  description:
                    "Veuillez configurer votre signature dans les informations de votre compte",
                  message: "Action indisponible",
                  onClick: () => history.push("/mes-informations"),
                }),
        })}
        onChange={handleScroll}
        // Ajout classe css pour désactiver les lignes en court de finalisation
        rowClassName={(record: any) =>
          record.etatPrescription === 4 ? "disabled-row" : ""
        }
      />
    </>
  );
}
