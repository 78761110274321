import { notification } from "antd";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getPrescription,
  saveRenouvellement,
  addPrescriptionToBasket,
  sendValidRenouvellement,
  getDocumentsByPrescription,
} from "../../../api/prescriptions.api";
import config from "../../../config";
import { RedirectionSignatureResponse } from "../../../interfaces/oodrive/redirectionSignatureResponse.interface";
import { toggleModal } from "../../../redux/modal/modal.slice";
import { frenchDateStringToISODateString } from "../../../utils/date";
import { convertPrescriptionToReinitFront } from "../../../utils/reiniti";
import { IRenouvellementPrescriptionService } from "./interfaces/IRenouvellementPrescriptionService";
import { usePrescription } from "./usePrescription";

export interface RenouvellementRouteParams {
  iPKPrescription?: string;
}

export function useRenouvellementPrescription({
  iPKPrescription,
}: RenouvellementRouteParams): IRenouvellementPrescriptionService {
  // const { iPKPrescription } = useParams<RenouvellementRouteParams>();
  const service = usePrescription();
  const [isError, setIsError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const getPrescriptionByIPK = useCallback(
    async (reload: boolean = false) => {
      if (!iPKPrescription) return;

      try {
        let prescriptionFromBDD = await getPrescription(
          service.identity?.iFKAnnuaire as number,
          parseInt(iPKPrescription, 10),
          reload
        );

        // UGLY! Use ISO 8601 norm for dates!
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateAccident)
          prescriptionFromBDD.oJustificatifs.dep.sDateAccident =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateAccident
            );
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateDebut)
          prescriptionFromBDD.oJustificatifs.dep.sDateDebut =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateDebut
            );
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateFin)
          prescriptionFromBDD.oJustificatifs.dep.sDateFin =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateFin
            );
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateGazEffort)
          prescriptionFromBDD.oJustificatifs.dep.sDateGazEffort =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateGazEffort
            );
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateGazRA1)
          prescriptionFromBDD.oJustificatifs.dep.sDateGazRA1 =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateGazRA1
            );
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateGazRA2)
          prescriptionFromBDD.oJustificatifs.dep.sDateGazRA2 =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateGazRA2
            );
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateGazRO2)
          prescriptionFromBDD.oJustificatifs.dep.sDateGazRO2 =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateGazRO2
            );
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateSituationAssure)
          prescriptionFromBDD.oJustificatifs.dep.sDateSituationAssure =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateSituationAssure
            );
        if (prescriptionFromBDD?.oJustificatifs?.dep?.sDateSpiro)
          prescriptionFromBDD.oJustificatifs.dep.sDateSpiro =
            frenchDateStringToISODateString(
              prescriptionFromBDD.oJustificatifs.dep.sDateSpiro
            );
        if (service.prescription && reload) {
          service.setPrescription({
            ...convertPrescriptionToReinitFront(
              prescriptionFromBDD,
              service.prescription
            ),
            bUpdated: true,
          });
        } else {
          service.setPrescription({ ...prescriptionFromBDD, bUpdated: true });
        }
        if (
          !service.selectedMenuItem &&
          prescriptionFromBDD &&
          prescriptionFromBDD.oJustificatifs
        ) {
          const justificatif = Object.entries(
            prescriptionFromBDD.oJustificatifs
          )[0];

          const key = `${justificatif[0]}${
            justificatif[1].sType ? `-${justificatif[1].sType}` : ""
          }`;
          service.setSelectedMenuItem(key);
          const isInArray = service.listeOrdonnance.includes(key);
          if (!isInArray) {
            service.listeOrdonnance.push(key);
          }
        }
      } catch (error) {
        console.log(error);

        notification.error({
          description: `La prescription n'a pas été trouvée, il est possible que la prescription n'existe plus sur les bases Doconline.`,
          message: `Prescription non-trouvé`,
        });
        setIsError(true);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [service.identity, iPKPrescription, service.selectedMenuItem]
  );

  const handleClickTab = (e: any, child: Array<any>) => {
    saveWithoutRedirect();
    service.handleClickTab(e, child);
  };

  const remove = () => {
    getPrescriptionByIPK(true);
    saveWithoutRedirect();
  };

  const save = async () => {
    if (!service.prescription) return;

    try {
      dispatch(toggleModal());
      service.setbEdited(false);
      await saveRenouvellement(
        service.identity?.iFKAnnuaire as number,
        iPKPrescription as string,
        service.prescription
      );
      notification.success({
        description: "Le renouvellement a bien été sauvegardé",
        message: "Succès !",
      });
      service.setbRedirect(true);
    } catch (error) {
      notification.error({
        description: `Nous rencontrons une erreur à l'enregistrement du formulaire, veuillez contacter votre administrateur`,
        message: `Echec de l'enregistrement du formulaire`,
      });
    } finally {
      dispatch(toggleModal());
    }
  };

  // AJOUT PRESCRIPTION PANIER
  const addToBasket = async () => {
    if (!service.prescription) return;
    dispatch(toggleModal());

    try {
      await save();

      service.setbEdited(false);

      notification.success({
        description:
          "Le renouvellement ajouté au panier - Retrouvez les dans 'Prêt pour signature'",
        message: "Succès !",
      });
      await addPrescriptionToBasket(
        service.identity?.iFKAnnuaire as number,
        iPKPrescription as unknown as number,
        service.prescription
      );
      service.setbRedirect(true);
    } catch (error) {
      notification.error({
        description: `Nous rencontrons une erreur à l'enregistrement du formulaire, veuillez contacter votre administrateur`,
        message: `Echec de l'enregistrement du formulaire`,
      });
    } finally {
    }
  };

  const saveWithoutRedirect = async () => {
    if (!service.prescription) return;

    try {
      await saveRenouvellement(
        service.identity?.iFKAnnuaire as number,
        iPKPrescription as string,
        service.prescription
      );
    } catch (error) {
      notification.error({
        description: `Nous rencontrons une erreur à l'enregistrement du formulaire, veuillez contacter votre administrateur`,
        message: `Echec de l'enregistrement du formulaire`,
      });
    }
  };
  const send = async () => {
    if (!service.prescription) return;
    // Sauvagarde l'état de la prescription avant de l'envoyer pour signature
    save();

    try {
      // Ouverture modal attente
      // if (config.oodriveSignatureActivated === true) {
      // service.setContentModal(<ModalWaitingOneSign />);
      dispatch(toggleModal());
      //}

      const response: AxiosResponse = await sendValidRenouvellement(
        service.identity?.iFKAnnuaire as number,
        iPKPrescription as string,
        service.prescription
      );

      let notificationPayload = {
        description: "Le renouvellement a bien été effectué",
        message: "Succès !",
      };

      if (
        config.oodriveSignatureActivated === true &&
        response.status === 200
      ) {
        let redirectionSignatureResponse: RedirectionSignatureResponse =
          response.data as RedirectionSignatureResponse;

        if (redirectionSignatureResponse.url !== undefined) {
          const rediredirectURL: string = redirectionSignatureResponse.url;
          notificationPayload = {
            description:
              "Le document est prêt. Vous allez être redirigé vers la plateforme de signature électronique",
            message: "Succès !",
          };

          setTimeout(() => {
            document.location.href = rediredirectURL;
          }, 5000);
        }
      }
      // dispatch(toggleModal());
      notification.success(notificationPayload);
      service.setbRedirect(true);
    } catch (error) {
      notification.error({
        description: `Nous rencontrons une erreur à l'envoi des données du formulaire, veuillez contacter votre administrateur`,
        message: `Echec de l'envoi des données du formulaire`,
      });
    } finally {
      dispatch(toggleModal());
    }
  };

  const getDocuments = useCallback(async () => {
    if (!iPKPrescription) return;

    try {
      const documents = await getDocumentsByPrescription(
        service.identity?.iFKAnnuaire as number,
        parseInt(iPKPrescription)
      );
      service.setDocuments(documents);
    } catch (error) {
      notification.error({
        description:
          "Nous rencontrons une erreur de chargement des documents associés aux prescriptions, veuillez contacter votre administrateur",
        message:
          "Echec lors du chargement des documents associés aux prescriptions",
      });
    }
  }, [service.identity, iPKPrescription]);

  useEffect(() => {
    if (!service.bRedirect) {
      service.getModeles();
      getPrescriptionByIPK();
    }
  }, [service.bRedirect, getPrescriptionByIPK, service.getModeles]);

  useEffect(() => {
    getDocuments();
  }, [service.identity, getDocuments]);
  return {
    ...service,
    saveWithoutRedirect: saveWithoutRedirect,
    save: save,
    addToBasket: addToBasket,
    remove: remove,
    send: send,
    getDocuments: getDocuments,
    iPKPrescription: iPKPrescription,
    handleClickTab: handleClickTab,
    isError: isError,
    setIsError: setIsError,
  };
}
