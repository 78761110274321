import styled from "styled-components";

// Icons
import contactEmail from "../../../assets/icons/contact_email.svg";
import contactPhone from "../../../assets/icons/contact_phone.svg";
import contactSpecialite from "../../../assets/icons/contact_specialite.svg";
import prescAttente from "../../../assets/icons/presc_attente.svg";
import prescEnCours from "../../../assets/icons/presc_encours_white.svg";
import profil from "../../../assets/icons/profil.svg";
import tableau from "../../../assets/icons/tableau.svg";
import signatureWhite from "../../../assets/icons/signature_white.svg";
import placeWhite from "../../../assets/icons/place_white.svg";

interface PropsStyleText {
  size?: string;
  uppercase?: boolean;
  center?: boolean;
  color?: string;
}

const StyleText = styled.p<PropsStyleText>`
  display: flex;
  align-items: center;
  ${(props) => props.center && "justify-content: center;"}
  gap: 3px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.neutral[140]};
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 2px;

  ${(props) => props.size === "medium" && "font-size: 1.5rem;"}
  ${(props) => props.color === "black" && "color: #3A3D4B;"}
  ${(props) => props.color === "white" && "color: #FFF;"}

  ${(props) => props.uppercase && "text-transform: uppercase;"}

  &:before {
    display: block;
  }

  &.contactSpecialite {
    &:before {
      content: url(${contactSpecialite});
    }
  }
  &.contactEmail {
    &:before {
      content: url(${contactEmail});
    }
  }
  &.contactPhone {
    &:before {
      content: url(${contactPhone});
    }
  }
  &.prescAttente {
    &:before {
      content: url(${prescAttente});
    }
  }
  &.prescEnCours {
    &:before {
      content: url(${prescEnCours});
    }
  }
  &.prescEnCours {
    &:before {
      content: url(${prescEnCours});
    }
  }
  &.tableau {
    &:before {
      content: url(${tableau});
    }
  }
  &.profil {
    &:before {
      content: url(${profil});
    }
  }
  &.signature {
    &:before {
      content: url(${signatureWhite});
    }
  }
  &.lieux {
    &:before {
      content: url(${placeWhite});
    }
  }
`;

interface Props {
  label: string;
  icon: string;
  size?: string;
  color?: string;
  uppercase?: boolean;
  center?: boolean;
}

const TextIcon = ({ label, center, icon, size, color, uppercase }: Props) => {
  return (
    <StyleText
      center={center}
      size={size}
      color={color}
      className={icon}
      uppercase={uppercase}
    >
      {label}
    </StyleText>
  );
};

export default TextIcon;
