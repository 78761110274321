import { MouseEventHandler } from "react";
import styled from "styled-components";
import edit from "../../../assets/icons/edit.svg";

const StyledEditButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 7px 17px;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: bold;
  color: #3c4450;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  background: none;
  transition: all 0.15s linear;
  &:before {
    content: "";
    display: inline-block;
    background-image: url(${edit});
    width: 20px;
    height: 20px;
    background-size: cover;
  }
  &:hover {
    background: ${(props) => props.theme.colors.neutral[130]};
  }
`;

interface Props {
  label: string;
  handleClick?: MouseEventHandler<HTMLButtonElement> | any;
}

const EditButton = ({ label, handleClick }: Props) => (
  <>
    <StyledEditButton onClick={handleClick}>{label}</StyledEditButton>
  </>
);

export default EditButton;
