import { AdsTheme } from "@ads/react-common-module";
import { DEFAULT_NOTIFICATION_DURATION } from "@ads/ui";

const breakpoints = {
  xs: "0px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
};

const theme: AdsTheme = {
  breakpoints: {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1600px",
  },
  media: {
    xs: "0px",
    sm: `@media (min-width: ${breakpoints.sm})`,
    md: `@media (min-width: ${breakpoints.md})`,
    lg: `@media (min-width: ${breakpoints.lg})`,
    xl: `@media (min-width: ${breakpoints.xl})`,
    xxl: `@media (min-width: ${breakpoints.xxl})`,
  },
  colors: {
    primary: {
      // VERTs
      80: "#698F0A",
      90: "#AEEE11",
      100: "#93C90E",
      110: "#CBF466",
      120: "#E8FABC",
      130: "#F7FDE7",
    },
    secondary: {
      // BLEU
      80: "#1D627C",
      90: "#30A3CF",
      100: "#3BA8D2",
      110: "#7AC4E1",
      120: "#C5E5F2",
    },
    tertiary: {
      // BLEU FONCE
      80: "#2774AE",
      90: "#2774AE",
      100: "#2774AE",
      110: "#2774AE",
      120: "#2774AE",
    },
    neutral: {
      100: "#FFFFFF",
      110: "#F7F9FD",
      120: "#E4E8F0",
      130: "#D4D8DE",
      140: "#BCC2CB",
      150: "#8B939F",
      160: "#7B838E",
      170: "#676E79",
      180: "#555D69",
      190: "#3C4450",
    },
    danger: {
      80: "#FF585D",
      90: "#E8070E",
      100: "#FF585D",
      110: "#FF585D",
      120: "#FF585D",
    },
    gradient: {
      // DEGRADE
      1: "transparent linear-gradient(150deg, #30A3CF 0%, #7AC4E1 100%) 0% 0% no-repeat padding-box;",
      2: "transparent linear-gradient(133deg, #30A3CF 0%, #7AC4E1 100%) 0% 0% no-repeat padding-box;",
    },
    // pas de quaternary sur doconline mais interface commune nomad
    quaternary: {},
    // pas de quinary sur doconline mais interface commune nomad
    quinary: {},
  },
  font: {
    type: {
      primary: '"Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif',
      primaryMedium:
        '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
    },
    weight: {
      regular: "400",
      bold: "700",
      extrabold: "800",
      black: "900",
    },
    size: {
      s1: 12,
      s2: 14,
      s3: 16,
      m1: 20,
      m2: 24,
      m3: 28,
      l1: 32,
      l2: 40,
      l3: 48,
      code: 90,
    },
  },
  duration: {
    notification: DEFAULT_NOTIFICATION_DURATION,
  },
};

export default theme;
