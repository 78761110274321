import { FunctionComponent, useEffect, useState } from "react";
import { Text } from "@ads/ui";
import { CustomButton, CustomText } from "../../atoms/buttons/CheckDocument";
import { v4 as uuidv4 } from "uuid";

import { HeaderProps } from "./headerInterface";
import {
  CustomNavLink,
  Header,
  NavWrapper,
  WrapperChild,
} from "./header.elements";
import { Select } from "antd";
import { ModalConfirmDelete } from "../ModalConfirmDelete";
import {
  selectContentModal,
  toggleModal,
} from "../../../redux/modal/modal.slice";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FormItem from "antd/es/form/FormItem";

export const FormSelectWrapperNeutral = styled(FormItem)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  top: 10px;
  width: 200px;
  // Style Label
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-label > label {
    //color: ${(props) => props.theme.colors.neutral[100]};
    color: ${(props) => props.theme.colors.neutral[90]};
  }
  // Style Select
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    color: ${(props) => props.theme.colors.neutral[170]};
    background-color: ${(props) => props.theme.colors.neutral[100]};
    min-height: 50px;
    width: 200px;
    border-radius: 10px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 200px;
    height: 50px;
    border-radius: 13px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
    width: 200px;
  }

  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    color: ${(props) => props.theme.colors.neutral[130]};
  }

  .ant-select-arrow {
    color: ${(props) => props.theme.colors.neutral[130]};
  }
  .ant-select-clear {
    background: ${(props) => props.theme.colors.neutral[140]};
  }

  .ant-select-multiple .ant-select-selection-item {
    background: ${(props) => props.theme.colors.neutral[160]};
    border-color: ${(props) => props.theme.colors.neutral[160]};
    height: 30px;
    line-height: 30px;
    width: 200px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${(props) => props.theme.colors.neutral[100]};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: 0 0 0 1px rgb(255 255 255);
  }

  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 50px;
    width: 200px;
  }
`;

export const HeaderNewPrescription: FunctionComponent<HeaderProps> = ({
  isLoading,
  remove,
  controler,
}) => {
  const typePrestaList = [
    "dap",
    "dep",
    "dap/dep",
    "ordonnance-standard",
    "ordonnance-bizone",
  ];
  const [typePresta, setTypePresta] = useState<string>("");
  const dispatch = useDispatch();

  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);
  useEffect(() => {
    console.log(typePresta);
    if (typePresta === "dap/dep") {
      controler?.setSelectedMenuItem("dap");
    } else {
      controler?.setSelectedMenuItem(typePresta);
    }
    controler?.setOrdoByTypePresta(typePresta);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [typePresta]);

  return (
    <Header>
      <NavWrapper className="nav-wrapper">
        <WrapperChild className="retour">
          <CustomNavLink className="back" exact to="/">
            <Text
              label="Retour"
              sColor={{ style: "neutral", variant: 190 }}
              fontWeight="600"
            />
          </CustomNavLink>
          <FormSelectWrapperNeutral name="TypeJustificatif">
            <Select
              allowClear
              autoClearSearchValue
              value={typePresta}
              placeholder="Type de Justificatif"
              onSelect={(value: any) => setTypePresta(value)}
              showSearch
            >
              {typePrestaList.map((nom: string) => (
                <Select.Option key={uuidv4()} value={nom}>
                  {nom}
                </Select.Option>
              ))}
            </Select>
          </FormSelectWrapperNeutral>
        </WrapperChild>

        <WrapperChild className="renouvellement">
          <Text
            label={`Nouvelle prescription `}
            sColor={{ style: "neutral", variant: 190 }}
            fontWeight="600"
          />
        </WrapperChild>

        <WrapperChild className="nav">
          <div style={{ display: "flex" }}>
            <CustomButton
              onClick={() => {
                if (controler?.prescription?.oJustificatifs) {
                  setContentModal(
                    <ModalConfirmDelete
                      returnFct={() => (window.location.href = "/")}
                    />
                  );
                  dispatch(toggleModal());
                } else {
                  window.location.href = "/";
                }
              }}
              className="delete"
            >
              <CustomText>
                <Text
                  label="Supprimer"
                  sColor={{ style: "neutral", variant: 100 }}
                />
              </CustomText>
            </CustomButton>
          </div>

          <CustomButton
            disabled={isLoading}
            className="check"
            onClick={() => {
              controler?.sendPrescriptionToIsadom();
            }}
          >
            <CustomText>
              <Text
                label={isLoading ? "En cours d'envoi" : "Signer et Envoyer "}
                sColor={{ style: "neutral", variant: 100 }}
              />
            </CustomText>
          </CustomButton>
        </WrapperChild>
      </NavWrapper>
    </Header>
  );
};
