import type { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";

export const Button = styled.button`
  margin: 0 1rem;
  padding: 0 2rem;
  height: 3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.button.primary.background};
  border: 0;
  border-radius: 0.28571429rem;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;
  font-size: 1.4rem;
  line-height: 3rem;

  & > span {
    margin-right: 1rem !important;
  }

  &:disabled {
    background: ${(props) => props.theme.button.neutral.disabled};
    cursor: not-allowed;

    &:hover {
      background: ${(props) => props.theme.button.neutral.disabled};
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background: ${(props) => props.theme.button.primary.hoverBackground};
  }
`;

export const PrimaryButton = styled(Button)`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.button.primary.background};
  box-shadow: 4px 5px 5px ${(props) => props.theme.button.primary.boxShadow};

  &:hover {
    background: ${(props) => props.theme.button.primary.hoverBackground};
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${(props) => props.theme.button.primary.background};
  background-color: ${(props) => props.theme.background.default};
  border: 0.2vmin solid ${(props) => props.theme.button.primary.background};

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.button.primary.background};
  }
`;

export const TernaryButton = styled(Button)`
  color: ${(props) => props.theme.button.neutral.background};
  background-color: ${(props) => props.theme.background.default};

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.button.neutral.background};
  }
`;

export const ButtonCancel = styled(Button)`
  background: ${(props) => props.theme.button.neutral.background};
  &:hover {
    background: ${(props) => props.theme.button.neutral.hoverBackground};
  }
`;

export const ButtonSubmit = styled(Button)`
  width: 100%;
`;

export const ButtonDelete = styled(Button)`
  background: ${(props) => props.theme.colors.error.main};

  &:hover {
    background: ${(props) => props.theme.colors.error.hover};
  }
`;

export const ButtonBack = styled(Link)`
  display: inline-block;
  margin: 0 1rem;
  padding: 0 2rem;
  height: 2.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${(props) => props.theme.text.primary};
  background: transparent;
  border: 1px solid ${(props) => props.theme.button.neutral.background};
  border-radius: 0.28571429rem;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;
  font-size: 1.4rem;
  line-height: 2.8rem;
  transition: all ease-in-out 0.25s;

  &:disabled {
    border-color: ${(props) => props.theme.button.neutral.disabled};
    cursor: not-allowed;

    &:hover {
      border-color: ${(props) => props.theme.button.neutral.disabled};
    }
  }

  &:first-child {
    margin-left: 0;
  }

  & > span {
    margin-right: 0.25rem;
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary.main};
    border-color: ${(props) => props.theme.colors.primary.main};
  }
`;

export const ButtonLink = styled(Link)`
  display: inline-block;
  padding: 0 2rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  text-decoration: none;
  color: inherit;
  background: ${(props) => props.theme.button.neutral.background};
  border: 0;
  cursor: pointer;
`;

export const ButtonFormSquare = styled(Button)<{
  barcode?: boolean;
  disabled?: boolean;
  lock?: boolean;
}>`
  position: relative;
  top: ${(props) => (props.barcode ? "6px" : "2px")};
  float: ${(props) => (props.lock ? "right" : "none")};
  padding: 0;
  margin: 0 0 0 10px;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;

  & i {
    margin: 0;
    font-size: ${(props) => (props.barcode ? "24px" : "16px")};
  }
`;

interface StyledButtonProps {
  type: string;
}

const StyledButton: FunctionComponent<StyledButtonProps> = ({
  children,
  type,
}) => {
  return type === "primary" ? (
    <PrimaryButton>{children}</PrimaryButton>
  ) : type === "secondary" ? (
    <SecondaryButton>{children}</SecondaryButton>
  ) : type === "ternary" ? (
    <TernaryButton>{children}</TernaryButton>
  ) : (
    <Button>{children}</Button>
  );
};

export default StyledButton;
