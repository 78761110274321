import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import LieuExercice from "../../../../interfaces/lieuExercice.interface";

// Slices
import {
  toggleModal,
  selectContentModal,
} from "../../../../redux/modal/modal.slice";
import { selectMedecinInfos } from "../../../../redux/utilisateur/utilisateur.slice";

// Components
import EditButton from "../../../atoms/buttons/EditButton";
import TitleIcon from "../../../atoms/text/TitleIcon";
import FormLieuxExercice from "../../FormLieuxExercice";

const Section = styled("div")`
  margin-bottom: 24px;
  padding: 24px 7px 24px 49px;
  border-bottom: 1px solid #f4f4f4;
  &:last-child {
    border: none;
  }
`;

const NormalText = styled("p")`
  font-size: 1.4rem;
  font-weight: 500;
  color: #3a3d4b;
`;

const HeaderSection = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const WrapperLieuExercice = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const ProfilItemInfos = () => {
  const dispatch = useDispatch();
  const medecin = useSelector(selectMedecinInfos);
  const setContentModal: (param: JSX.Element) => void = useSelector(
    selectContentModal
  );

  const handleClick = (lieuExercice: LieuExercice) => {
    dispatch(toggleModal());
    setContentModal(<FormLieuxExercice lieuExercice={lieuExercice} />);
  };

  return (
    <Section>
      <HeaderSection>
        <TitleIcon title="Lieux d'exercice" icon="place" />
      </HeaderSection>
      {medecin?.tLieuxExercice.map((lieuExercice) => (
        <WrapperLieuExercice>
          <NormalText>
            <b>{lieuExercice.sLibelle}</b>
            <br />
            {lieuExercice.sNumeroVoie} {lieuExercice.sCodeTypeVoie}{" "}
            {lieuExercice.sAdresse} - {lieuExercice.sCodePostal}{" "}
            {lieuExercice.sVille}
          </NormalText>
          <div>
            <EditButton
              label="Modifier"
              handleClick={() => handleClick(lieuExercice)}
            />
          </div>
        </WrapperLieuExercice>
      ))}
    </Section>
  );
};

export default ProfilItemInfos;
