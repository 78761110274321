import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Upload, Form, notification } from "antd";
// API
import { uploadDocument } from "../../api/prescriptions.api";
import { CloudUploadOutlined } from "@ant-design/icons";
import { PrimaryButton } from "@ads/ui";
import { setTimeout } from "timers";

const WrapperForm = styled("div")`
  width: 290px;
  padding: 10px;
  position: absolute;
  top: 0;
  height: 100vh;
  overflow: auto;
  padding-top: 12px;
  opacity: 0;
  z-index: 999;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${(props) => props.theme.colors.neutral[130]};
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${(props) => props.theme.colors.neutral[170]};
  }
  background: ${(props) => props.theme.colors.neutral[190]};
  transition: opacity 0.3s linear;
  &.mounted {
    opacity: 1;
  }
`;

const WrapperFormItems = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .ant-upload-list-item-card-actions .anticon {
    color: ${(props) => props.theme.colors.neutral[100]};
  }
  .ant-upload-list-picture .ant-upload-list-item {
    color: ${(props) => props.theme.colors.neutral[100]};
    max-width: 20vw;
  }

  .ant-upload.ant-upload-drag {
    background: none;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: ${(props) => props.theme.colors.neutral[100]};
    padding: 0 10px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: ${(props) => props.theme.colors.neutral[130]};
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: ${(props) => props.theme.colors.secondary[100]};
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${(props) => props.theme.colors.secondary[100]};
  }
`;

const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 20px 0 0 0;
`;

interface Props {
  iPKAnnuaire: number;
  iFKPrescription: number;
  iFKAnnuairePatient: number;
  cancel: Function;
  getDocuments: Function;
}

const FormDocument = ({
  iPKAnnuaire,
  iFKPrescription,
  iFKAnnuairePatient,
  cancel,
  getDocuments,
}: Props) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const refWrapperForm = useRef<HTMLDivElement>(null);

  const handleCancel = () => {
    refWrapperForm.current?.classList.remove("mounted");
    setSelectedFiles([]);
    setTimeout(() => {
      cancel();
    }, 500);
  };

  const handleFileInput = (file: File) => {
    selectedFiles.push(file);
    setSelectedFiles(selectedFiles);
    return false;
  };

  const handleRemoveFile = (file: any) => {
    setSelectedFiles(selectedFiles.filter((f: any) => f.uid !== file.uid));
  };

  const saveDocument = async () => {
    await selectedFiles.forEach((selectedFile) => {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile!);
      reader.onerror = (error) => {
        notification.error({
          description: `Nous rencontrons une erreur lors de la récupération d'un document`,
          message: `Echec de la récupération d'un document`,
        });
      };
      reader.onload = async () => {
        setIsSubmiting(true);
        try {
          await uploadDocument(iPKAnnuaire, iFKPrescription, {
            iFKAnnuairePatient,
            dataUrlDocument: reader.result as string,
            name: selectedFile.name,
          });
        } catch (error) {
          notification.error({
            description: `Nous rencontrons une erreur lors de l'envoi d'un document`,
            message: `Echec de l'envoi d'un document`,
          });
          throw error;
        } finally {
          // Close modal with dispatch
          setIsSubmiting(false);
          getDocuments();
          handleCancel();
        }
      };
    });
  };

  useEffect(() => {
    refWrapperForm.current?.classList.add("mounted");
  }, []);

  return (
    <Form name="validate_other" onFinish={saveDocument}>
      <WrapperForm ref={refWrapperForm}>
        <WrapperFormItems>
          <Form.Item
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
            name="dragger"
            noStyle
            valuePropName="fileList"
          >
            <Upload.Dragger
              accept="*"
              beforeUpload={handleFileInput}
              listType="picture"
              multiple={true}
              name="signature"
              onRemove={handleRemoveFile}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">
                Cliquez ou déposez vos documents ici
              </p>
              <p className="ant-upload-hint"></p>
            </Upload.Dragger>
            {isSubmiting && (
              <p style={{ color: "white" }} className="ant-upload-text">
                Envois en cours...
              </p>
            )}
          </Form.Item>
        </WrapperFormItems>
        <WrapperButtons>
          <PrimaryButton
            as="button"
            label="Valider"
            size="S"
            buttonStyle="primary"
            type="submit"
            minWidth="130px"
            disabled={isSubmiting}
          />
          <PrimaryButton
            as="button"
            label="Annuler"
            size="S"
            buttonStyle="danger"
            type="reset"
            minWidth="130px"
            onClick={handleCancel}
          />
        </WrapperButtons>
      </WrapperForm>
    </Form>
  );
};

export default FormDocument;
