import React from "react";
import type { KeyboardEvent } from "react";
import { useEffect, useRef } from "react";
import type { TextAreaRef } from "antd/lib/input/TextArea";
import styled from "styled-components";
import { Input } from "antd";
import { Separator, Wrapper } from "./ordonnance.style";
import Footer from "./footer.elements";
import { HeaderBizone, HeaderBizoneEditable } from "./header.bizone.elements";
import { OrdonnanceBizoneProps } from "./ordonnanceInterface";

const AdaptedWrapper = styled(Wrapper)`
  grid-template-rows: 20px auto 1fr 180px;
  height: unset;
  width: 850px;
`;

export default function OrdonnanceBizone({
  bDateSignature,
  onChange,
  ordonnance,
  resetSelectedModele,
  selectedModele,
  prescription,
  setPrescription,
  isEditable,
}: OrdonnanceBizoneProps) {
  const textAreaEl = useRef<TextAreaRef>(null);

  const handleTab = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { textArea } = textAreaEl.current!.resizableTextArea!;

    console.log(e);

    if (e.key === "Tab") {
      const { selectionStart, selectionEnd } = textArea;
      const contentAfter = textArea.value.slice(selectionEnd);
      const contentBefore = textArea.value.slice(0, selectionStart);
      const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        "value"
      )?.set;

      e.preventDefault();

      // React library overrides input value setter
      nativeTextAreaValueSetter?.call(
        textArea,
        `${contentBefore}\t${contentAfter}`
      );
      textArea.dispatchEvent(new Event("input", { bubbles: true }));

      textArea.selectionEnd = selectionStart + 1;
      textArea.selectionStart = selectionStart + 1;
    }
  };

  useEffect(() => {
    const textArea = textAreaEl.current?.resizableTextArea?.textArea;

    if (!selectedModele || !textArea) return;

    const { selectionStart, selectionEnd } = textArea;
    const contentAfter = textArea.value.slice(selectionEnd);
    const contentBefore = textArea.value.slice(0, selectionStart);
    const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      "value"
    )?.set;

    textAreaEl.current!.focus();

    // React library overrides input value setter
    nativeTextAreaValueSetter?.call(
      textArea,
      `${contentBefore}\n${selectedModele}\n${contentAfter}`
    );
    textArea.dispatchEvent(new Event("input", { bubbles: true }));

    textArea.selectionEnd = contentBefore.length + selectedModele.length + 2;
    textArea.selectionStart = selectionStart;

    resetSelectedModele();
  }, [selectedModele, resetSelectedModele]);

  return (
    <AdaptedWrapper>
      {isEditable && (
        <HeaderBizoneEditable
          ordonnance={ordonnance}
          onChange={onChange}
          prescription={prescription}
          setPrescription={setPrescription}
        />
      )}
      {!isEditable && (
        <HeaderBizone
          dDateNaissancePatient={ordonnance.dDateNaissancePatient}
          sAdeliPrescripteur={ordonnance.sAdeliPrescripteur}
          sAdressePrescripteur={ordonnance.sAdressePrescripteur}
          sCiviliteNomPrenomPatient={ordonnance.sCiviliteNomPrenomPatient}
          sCiviliteNomPrenomPrescripteur={
            ordonnance.sCiviliteNomPrenomPrescripteur
          }
          sCodePatient={ordonnance.sCodePatient}
          sNoSecuriteSociale={ordonnance.sNoSecuriteSociale}
          sRPPSPrescripteur={ordonnance.sRPPSPrescripteur}
        />
      )}
      <Separator />
      <Input.TextArea
        value={ordonnance.sContenu}
        name="sContenu"
        onChange={onChange}
        onKeyDown={handleTab}
        rows={25}
        cols={5}
        wrap={"hard"}
        ref={textAreaEl}
      />
      <Separator />
      <Footer
        bDateSignature={bDateSignature}
        prescription={prescription}
        setPrescription={setPrescription}
      />
    </AdaptedWrapper>
  );
}
