import { FunctionComponent } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../redux/modal/modal.slice";
import WhiteModal from "../../components/atoms/modals/WhiteModal";
import warning from "../../assets/icons/warning.svg";

import {
  Text,
  PrimaryButton,
} from "@ads/ui";

interface Props {
  tabOrdonnance?: string[];
  ongletUnchecked: string;
}

const Warning = styled.img`
  background-color: ${(props) => props.theme.colors.secondary[100]};
`;

const StyledSvg = styled.div`
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${(props) => props.theme.colors.secondary[100]};
  border-radius: 50%;
`;

const Wrapper = styled.div`
  position: relative;
`;
const WrapperContent = styled.div`
  margin-top: 20px;
`;

const ModalDocument: FunctionComponent<Props> = ({ ongletUnchecked }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleModal());
  };
  return (
    <Wrapper>
      <WhiteModal>
        <StyledSvg>
          <Warning src={warning} />
        </StyledSvg>
        <WrapperContent></WrapperContent>
        <Text
          label="Remplir tous les documents avant de signer"
          sColor={{ style: "neutral", variant: 170 }}
          fontWeight="600"
        />
        <Text
          label="Tous les documents doivent être consultés et remplis avant de signer et d'envoyer"
          sColor={{ style: "neutral", variant: 170 }}
          fontWeight="500"
          textAlign="center"
          marginText="10px"
        />
        <Text
          label="Document en attente de consultation:"
          sColor={{ style: "neutral", variant: 170 }}
          marginText="10px"
        />
        <Text
          label={ongletUnchecked}
          sColor={{ style: "secondary", variant: 80 }}
          fontWeight="600"
          marginText="10px 0 20px 0"
        />

        <PrimaryButton
          onClick={() => handleClick()}
          label="D'accord"
          as="button"
          size="S"
          buttonStyle="secondary"
          type="button"
          minWidth="200px"
        />
      </WhiteModal>
    </Wrapper>
  );
};

export default ModalDocument;
