import { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { deconnexionAction } from "../../../redux/utilisateur/actions/deconnexion.action";

import Layout from "../../../components/templates/Layout";
import { PrimaryButton } from "@ads/ui";
import config from "../../../config";

import AdsPng from "../../../assets/icons/logo-ADS.png";

const WrapperBackground = styled("div")`
  background: ${(props) => props.theme.colors.gradient[1]};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  a:hover {
    color: ${(props) => props.theme.colors.neutral[100]};
  }
`;

const WrapperContent = styled("div")`
  position: relative;
  bottom: 20px;
  background: ${(props) => props.theme.colors.neutral[100]};
  padding: 55px 75px;
  border-radius: 20px;
  box-shadow: rgb(10 36 67 / 26%) 0px 3px 20px;
`;

const AdsLogo = styled.div`
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  border-radius: 13px;
  background-color: ${(props) => props.theme.colors.neutral[100]};
  padding: 1rem 2rem;
  z-index: 2;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    bottom: 2rem;
    right: 2rem;
  }
`;

const IFrame = styled.iframe`
  border: 0;
  height: 0;
  width: 0;
`;
export default function DeconnexionView() {
  const dispatch = useDispatch();

  document.cookie =
    "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  useEffect(() => {
    dispatch(deconnexionAction());
  });

  return (
    <Layout
      navigation={false}
      main={
        <WrapperBackground>
          <IFrame
            frameBorder="0"
            height="0"
            src={`${config.identityServerURL}/logout`}
          />
          <WrapperContent>
            <h1>Déconnexion réussie</h1>
            <p>
              Nous vous remercions pour votre visite, vous souhaitez vous
              reconnecter ?
            </p>

            <PrimaryButton
              as="a"
              href="/"
              size="S"
              label="Connexion"
              buttonStyle="primary"
              type="button"
            />
          </WrapperContent>
          <AdsLogo>
            <img src={AdsPng} alt="ads" />
          </AdsLogo>
        </WrapperBackground>
      }
    />
  );
}
