import React, { FunctionComponent, SyntheticEvent } from "react";
import styled from "styled-components";
import check from "../../../assets/icons/check.svg";

const StyledItemTab = styled("div")`
  display: inline-flex;
  //padding: 13px 39px;
  padding: 0px 10px;
  color: ${(props) => props.theme.colors.tertiary[110]};
  font-size: 1.4rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f4f4f4;
  font-weight: 500;
  &.active,
  &:hover {
    background-color: transparent;
  }
  transition: all 0.1s linear;
`;

const StylePicto = styled("div")`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: #ddd;
  pointer-events: none;
  .active & {
    background-color: ${(props) => props.theme.colors.primary[100]};
    background-image: url("${check}");
    background-size: 8px 6px;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

interface Props {
  label: string;
  active?: boolean;
  picto?: boolean;
  dataAttr: string;
  onClick: (event: SyntheticEvent) => void;
}

const ItemTabs: FunctionComponent<Props> = ({
  label,
  active,
  picto,
  dataAttr,
  onClick,
}: Props) => {
  return (
    <StyledItemTab
      data-target={dataAttr}
      onClick={onClick}
      className={active ? "active tab-header-item" : "tab-header-item"}
    >
      {picto ? <StylePicto /> : ""}
      {label}
    </StyledItemTab>
  );
};

export default ItemTabs;
