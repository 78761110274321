import { Checkbox as CheckboxAntd } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { UseFormRegister, FieldValues } from "react-hook-form";
import styled from "styled-components";

const WrapperCheckbox = styled("span")`
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.neutral[130]};
  }
  .ant-checkbox-checked::after {
    border-color: ${(props) => props.theme.colors.neutral[130]};
  }

  // check state
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colors.neutral[130]};
    background-color: ${(props) => props.theme.colors.neutral[130]};
  }

  // check mark
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${(props) => props.theme.colors.neutral[170]};
  }
  &:hover {
    .ant-checkbox-inner {
      background-color: ${(props) => props.theme.colors.neutral[130]};
    }
  }
`;

interface Props {
  name?: string;
  checked?: boolean;
  handleChange?: (e: CheckboxChangeEvent) => void;
  register?: UseFormRegister<FieldValues>;
}

const Checkbox = ({ handleChange, register, name, checked }: Props) =>
  register && name ? (
    <WrapperCheckbox>
      <CheckboxAntd {...register(name)} />
    </WrapperCheckbox>
  ) : (
    <WrapperCheckbox>
      <CheckboxAntd onChange={handleChange} defaultChecked={checked} />
    </WrapperCheckbox>
  );

export default Checkbox;
