import { Select } from "antd";
import { FiltersPrescriptionsService } from "../hooks/useFiltersPrescriptions";
import { v4 as uuidv4 } from "uuid";

export function SelectForfaitPrescription(
  controler: FiltersPrescriptionsService
) {
  return (
    <Select
      allowClear
      autoClearSearchValue
      mode="multiple"
      placeholder="Forfait"
      onChange={(value) => controler.addFilter("sFKCodesPrescription", value)}
      showSearch
      value={
        controler.options.find((o) => o.filter === "sFKCodesPrescription")
          ?.value
      }
    >
      {controler.forfaitFilters.map((sLibelle) => (
        <Select.Option key={uuidv4()} value={sLibelle}>
          {sLibelle}
        </Select.Option>
      ))}
      d
    </Select>
  );
}
