import { Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Modal, Spin } from "antd";

import { PrimaryButton, IconsV3 } from "@ads/ui";

import { getDocumentLink } from "../../api/prescriptions.api";

import { toggleModal } from "../../redux/modal/modal.slice";

import Layout from "../../components/templates/Layout";
import TabsHeader from "../../components/molecules/tabs/TabsHeader";
import TabsHeaderItem from "../../components/atoms/tabs/TabsHeaderItem";
import TabsContent from "../../components/molecules/tabs/TabsContent";
import FormDocument from "../../components/molecules/FormDocument";

import { FormModele } from "../../components/molecules/FormModele";
import DAP from "./DAP/DAP.elements";
import DEP from "./DEP/DEP.elements";
import {
  OrdonnanceBizone,
  OrdonnancePPC,
  OrdonnanceStandard,
} from "./ordonnances";

import InsertDocument from "../../components/molecules/InsertDocument/InsertDocument";
import ButtonDocument from "../../components/molecules/ButtonDocument";
import { Wrapper } from "../index/index.elements";
import {
  StyledLayout,
  FixedLeftWrapper,
  LeftTextWrapper,
  TitlePanel,
  WrapperButton,
  RightTextWrapper,
  WrapperDocument,
  ContentCenter,
  WrapperTabsHeader,
  TabsContentWrapper,
  OverFlowWrapper,
} from "./elements/renouvellement.elements";
import { HeaderRenouvellement } from "../../components/molecules/header/HeaderRenouvellement";
import {
  RenouvellementRouteParams,
  useRenouvellementPrescription,
} from "./hook/useRenouvellementPrescription";
import { MultiRollingPanelStyled } from "../../components/atoms/MultiRollingPanelStyled";

type RenouvellementViewProps = RouteComponentProps<RenouvellementRouteParams>;

const Renouvellement = (props: RenouvellementViewProps) => {
  const controler = useRenouvellementPrescription({
    iPKPrescription: props.match.params.iPKPrescription,
  });
  const dispatch = useDispatch();

  if (controler.isError) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Layout
        main={
          <StyledLayout>
            <HeaderRenouvellement
              iPKPrescription={controler.iPKPrescription}
              send={controler.send}
              save={controler.save}
              isLoading={controler.isLoading}
              tabOrdonnance={controler.listeOrdonnance}
              ordonnances={controler.ordonnances}
              remove={controler.remove}
              prescription={controler.prescription}
              addToBasket={controler.addToBasket}
            />
            <Wrapper className="wrapper">
              <FixedLeftWrapper className="fixedwrapper">
                <MultiRollingPanelStyled
                  panels={[
                    {
                      icon: <IconsV3.Attached />,
                      children: (
                        <div style={{ overflowY: "hidden", height: "68rem" }}>
                          <LeftTextWrapper>
                            <TitlePanel
                              label="DOCUMENTS"
                              sColor={{ style: "neutral" }}
                            />
                          </LeftTextWrapper>
                          <div
                            style={{
                              paddingBottom: 65,
                              overflowX: "hidden",
                              height: "60rem",
                            }}
                          >
                            {controler.documents.length > 0 ? (
                              controler.documents.map((doc) => (
                                <ButtonDocument
                                  label={doc.sLibelle}
                                  key={uuidv4()}
                                  onclick={() => {
                                    window
                                      .open(
                                        getDocumentLink(
                                          controler.identity
                                            ?.iFKAnnuaire as number,
                                          doc.iPKPrescription,
                                          doc.iPKTmpPprDocument
                                        ),
                                        "_blank"
                                      )
                                      ?.focus();
                                  }}
                                />
                              ))
                            ) : (
                              <p>Aucun document disponible</p>
                            )}
                          </div>
                          {controler.prescription?.bAutorizeJointDoc && (
                            <WrapperButton>
                              <PrimaryButton
                                as="button"
                                label="Ajouter un document"
                                size="S"
                                buttonStyle="primary"
                                type="reset"
                                minWidth="157px"
                                onClick={() =>
                                  controler.setAddDocument(
                                    !controler.addDocument
                                  )
                                }
                              />
                            </WrapperButton>
                          )}
                        </div>
                      ),
                    },
                  ]}
                />

                {controler.addDocument &&
                  controler.iPKPrescription &&
                  controler.prescription?.iFKAnnuairePatient && (
                    <FormDocument
                      iPKAnnuaire={controler.identity?.iFKAnnuaire as number}
                      iFKAnnuairePatient={Number(
                        controler.prescription.iFKAnnuairePatient
                      )}
                      iFKPrescription={parseInt(controler.iPKPrescription, 10)}
                      cancel={() =>
                        controler.setAddDocument(!controler.addDocument)
                      }
                      getDocuments={controler.getDocuments}
                    />
                  )}
              </FixedLeftWrapper>
              {controler.prescription?.oJustificatifs?.ordonnance && (
                <MultiRollingPanelStyled
                  side="right"
                  panels={[
                    {
                      icon: <IconsV3.DocumentPen />,
                      children: (
                        <div style={{ overflowY: "auto", height: "68rem" }}>
                          <RightTextWrapper>
                            <TitlePanel
                              label="Modèles de texte"
                              sColor={{ style: "neutral" }}
                            />
                          </RightTextWrapper>
                          <WrapperDocument>
                            {controler.modelesTexte.map((modeleText) => (
                              <InsertDocument
                                key={modeleText.sLibelle}
                                label={modeleText.sTexte}
                                onClick={() => {
                                  if (controler.isBlank) {
                                    controler.setSelectedModele(
                                      modeleText.sTexte
                                    );
                                  } else {
                                    alert(
                                      "Attention ! Le texte saisi doit être vidé avant d'ajouter un nouveau texte"
                                    );
                                  }
                                }}
                                editModele={() => {
                                  dispatch(toggleModal());
                                  controler.setContentModal(
                                    <FormModele
                                      modele={modeleText}
                                      setModeles={() => controler.getModeles()}
                                    />
                                  );
                                }}
                                svgLabel="Insérer"
                                titleLabel={modeleText.sLibelle}
                              />
                            ))}
                          </WrapperDocument>
                        </div>
                      ),
                    },
                  ]}
                />
              )}
              {!controler.prescription ? (
                ""
              ) : (
                <ContentCenter className="contentcentner">
                  <WrapperTabsHeader className="wrappertabsheader">
                    <TabsHeader>
                      {controler.prescription &&
                        controler.prescription.oJustificatifs &&
                        Object.entries(
                          controler.prescription.oJustificatifs
                        ).map((child: Array<any>, index) => {
                          const key = `${child[0]}${
                            child[1].sType ? `-${child[1].sType}` : ""
                          }`;

                          return (
                            <TabsHeaderItem
                              key={key}
                              label={`${child[0]} ${
                                child[1].sType ? child[1].sType : ""
                              }`}
                              picto
                              active={index === 0 ? true : false}
                              dataAttr={key}
                              onClick={(event) =>
                                controler.handleClickTab(event, child)
                              }
                            />
                          );
                        })}
                    </TabsHeader>
                  </WrapperTabsHeader>
                  <TabsContentWrapper>
                    {controler.prescription?.oJustificatifs?.dap && (
                      <TabsContent
                        id="dap"
                        active={controler.selectedMenuItem === "dap"}
                      >
                        <OverFlowWrapper>
                          <DAP
                            bDateSignature={controler.bDateSignature}
                            key="DAP"
                            onChange={(e) => {
                              controler.handleChange("dap", e);
                            }}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                            justificatif={
                              controler.prescription.oJustificatifs.dap
                            }
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}
                    {controler.prescription?.oJustificatifs?.dep && (
                      <TabsContent
                        id="dep"
                        active={controler.selectedMenuItem === "dep"}
                      >
                        <OverFlowWrapper>
                          <DEP
                            bDateSignature={controler.bDateSignature}
                            key="DEP"
                            onChange={(e) => {
                              controler.handleChange("dep", e);
                            }}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}

                    {controler.prescription?.oJustificatifs?.ordonnance
                      ?.sType === "standard" && (
                      <TabsContent
                        id="ordonnance-standard"
                        active={
                          controler.selectedMenuItem === "ordonnance-standard"
                        }
                      >
                        <OverFlowWrapper>
                          <OrdonnanceStandard
                            bDateSignature={controler.bDateSignature}
                            key="Ordonnance Standard"
                            onChange={(e) => {
                              controler.handleChange("ordonnance", e);
                            }}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                            ordonnance={
                              controler.prescription.oJustificatifs.ordonnance
                            }
                            resetSelectedModele={() => {
                              controler.setSelectedModele(undefined);
                            }}
                            selectedModele={controler.selectedModele}
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}

                    {controler.prescription?.oJustificatifs?.ordonnance
                      ?.sType === "bizone" && (
                      <TabsContent
                        id="ordonnance-bizone"
                        active={
                          controler.selectedMenuItem === "ordonnance-bizone"
                        }
                      >
                        <OverFlowWrapper>
                          <OrdonnanceBizone
                            bDateSignature={controler.bDateSignature}
                            key="Ordonnance Bizone"
                            onChange={(e) => {
                              controler.handleChange("ordonnance", e);
                            }}
                            ordonnance={
                              controler.prescription.oJustificatifs.ordonnance
                            }
                            resetSelectedModele={() => {
                              controler.setSelectedModele(undefined);
                            }}
                            selectedModele={controler.selectedModele}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}
                    {controler.prescription?.oJustificatifs?.ordonnance
                      ?.sType === "ppc" && (
                      <TabsContent
                        id="ordonnance-ppc"
                        active={controler.selectedMenuItem === "ordonnance-ppc"}
                      >
                        <OverFlowWrapper>
                          <OrdonnancePPC
                            bDateSignature={controler.bDateSignature}
                            key="Ordonnance PPC"
                            onChange={(e) => {
                              controler.handleChange("ordonnance", e);
                            }}
                            ordonnance={
                              controler.prescription.oJustificatifs.ordonnance
                            }
                            resetSelectedModele={() => {
                              controler.setSelectedModele(undefined);
                            }}
                            selectedModele={controler.selectedModele}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}
                  </TabsContentWrapper>
                </ContentCenter>
              )}
            </Wrapper>
            <Modal
              style={{ textAlign: "center" }}
              closable={false}
              title="Signature électronique"
              centered
              width={450}
              visible={controler.isLoading}
              footer={[]}
            >
              <Spin tip="Préparation des documents à signer. Merci de patienter." />
            </Modal>
          </StyledLayout>
        }
      />
      {controler.bRedirect && <Redirect to="/?normal=true" />}
    </>
  );
};

export default Renouvellement;
