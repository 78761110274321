import { useSelector } from "react-redux";
import styled from "styled-components";
import ProfilInfoPersoGridItem from "../../atoms/profil/ProfilInfoPersoGridItem";

import { selectMedecinInfos } from "../../../redux/utilisateur/utilisateur.slice";
import { selectCivilites } from "../../../redux/config/config.slice";

const GridSection = styled("div")`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto;
  grid-template-areas:
    "civilite adeli specialite"
    "nom mel specialite"
    "prenom portable specialite";

  margin-top: 24px;
`;

const InfosPersos = () => {
  const medecin = useSelector(selectMedecinInfos);
  const civilites = useSelector(selectCivilites);
  return medecin ? (
    <GridSection>
      <ProfilInfoPersoGridItem
        gridArea="civilite"
        dt="Civilité"
        dd={civilites?.find(c => c.sCode === medecin.sCodeCivilite)?.sLibelle || medecin.sCodeCivilite}
      />
      <ProfilInfoPersoGridItem
        gridArea="adeli"
        dt="Numéro Adeli"
        dd={medecin.sNumAdeli}
      />
      <ProfilInfoPersoGridItem gridArea="nom" dt="Nom" dd={medecin.sNom} />
      <ProfilInfoPersoGridItem gridArea="mel" dt="Mel" dd={medecin.sMail} />
      <ProfilInfoPersoGridItem
        gridArea="prenom"
        dt="Prénom"
        dd={medecin.sPrenom}
      />
      {medecin.sTelGsm && (
        <ProfilInfoPersoGridItem
          gridArea="portable"
          dt="Portable"
          dd={medecin.sTelGsm}
        />
      )}
      <ProfilInfoPersoGridItem
        gridArea="specialite"
        dt="Specialité"
        dd={medecin.tSpecialites.join(",")}
      />
    </GridSection>
  ) : null;
};

export default InfosPersos;
