import React, { useState } from "react";
import styled from "styled-components";
import ContactBubble from "../../atoms/ContactBubble";
import ContactPopup from "../../molecules/Contact/ContactPopup";

const Wrapper = styled("div")`
  position: fixed;
  bottom: 9px;
  right: 8px;
  z-index: 999;
  pointer-events: none;
`;

const RelativeContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
`;

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      <RelativeContainer>
        <ContactPopup isOpen={isOpen} />
        <ContactBubble onClick={() => setIsOpen(!isOpen)} />
      </RelativeContainer>
    </Wrapper>
  );
};

export default Contact;
