import styled from "styled-components";

const WhiteModal = styled("div")`
  padding: 55px 150px;
  border-radius: 20px;
  background: ${(props) => props.theme.colors.neutral[100]};
  box-shadow: 0px 3px 40px #0a244343;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: thin;
`;

export default WhiteModal;
