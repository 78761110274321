import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import type { ChangeEvent as ReactChangeEvent } from "react";

export interface CheckboxOrdoPropsInterface {
  onChange: (
    e: CheckboxChangeEvent | ReactChangeEvent<HTMLInputElement>
  ) => void;
  name: string;
  value: string | undefined;
  children?: string;
}
export function CheckboxOrdo(props: CheckboxOrdoPropsInterface) {
  return (
    <Checkbox
      checked={props.value === "X"}
      name={props.name}
      onChange={props.onChange}
    >
      {props.children}
    </Checkbox>
  );
}
