import { HeaderAdene } from "../../header.elements";
import { OrdonnancePPCProps } from "../../ordonnance.ppc";
import PriseEnChargeSection from "../priseEnChargeSection.elements";
import TelesuiviSection from "../telesuiviSection.elements";
import TraitementSection from "../traitementSection.elements";

export function PrescriptionAdene(props: OrdonnancePPCProps) {
  return (
    <>
      <HeaderAdene
        dDateNaissancePatient={props.ordonnance.dDateNaissancePatient}
        sAdeliPrescripteur={props.ordonnance.sAdeliPrescripteur}
        sAdressePrescripteur={props.ordonnance.sAdressePrescripteur}
        sCiviliteNomPrenomPatient={props.ordonnance.sCiviliteNomPrenomPatient}
        sCiviliteNomPrenomPrescripteur={
          props.ordonnance.sCiviliteNomPrenomPrescripteur
        }
        sNoSecuriteSociale={props.ordonnance.sNoSecuriteSociale}
        sRPPSPrescripteur={props.ordonnance.sRPPSPrescripteur}
      />
      <PriseEnChargeSection
        onChange={props.onChange}
        ordonnance={props.ordonnance}
      />

      <TraitementSection
        onChange={props.onChange}
        ordonnance={props.ordonnance}
      />

      <TelesuiviSection
        onChange={props.onChange}
        ordonnance={props.ordonnance}
        resetSelectedModele={props.resetSelectedModele}
        selectedModele={props.selectedModele}
      />
    </>
  );
}
