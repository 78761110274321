import type { Action } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';
import { verify as verifyJwt } from 'jsonwebtoken';

import { connexionSucces, connexionErreur } from '../utilisateur.slice';
import type { Identite } from '../../../interfaces/utilisateur.interface';
import config from '../../../config';

function getCookie(name: string) {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export function authentificationAction(): ThunkAction<void, Identite, unknown, Action<string>> {
  return async (dispatch) => {
    const token = getCookie('Authorization');
    if (token) {
      try {
        const payload = verifyJwt(token, config.jwtPUBLIC!);
        dispatch(connexionSucces({ user: payload as Identite, token }));
      } catch {
        dispatch(connexionErreur({ sErreur: 'Token invalide' }));
      }
    }
  };
}
