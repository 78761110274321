import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TextIcon from "../atoms/text/TextIcon";
import LieuExercice from "../../interfaces/lieuExercice.interface";
// Forms
import { Label, Textarea } from "@ads/ui";
import Checkbox from "../atoms/form/Checkbox";

// Slices
import { toggleModal } from "../../redux/modal/modal.slice";
import { setMedecinInfos } from "../../redux/utilisateur/utilisateur.slice";

import { PrimaryButton, FormItemWrapper } from "@ads/ui";
import { updateAdresseMedecins } from "../../api/lieuxExercice.api";
import { selectTypesVoies } from "../../redux/config/config.slice";
import { selectMedecinInfos } from "../../redux/utilisateur/utilisateur.slice";
import BlackModal from "../atoms/modals/BlackModal";
import { Select } from "antd";
import { useState } from "react";
import moment from "moment";
import FormSelectWrapper from "../atoms/form/FormSelectWrapper";
import { StyledInput } from "../atoms/input/InputStyled";

const Content = styled("div")`
  margin-top: 55px;
  min-width: 730px;
  padding: 0 100px 55px 100px;
  .col-span2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .col-span3 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  #sAdresse {
    max-width: 100%;
  }
`;

const TextareaWrapper = styled("div")`
  width: 100%;
  textarea {
    min-height: 100px;
    border-radius: 15px;
  }
`;

const WrapperTitle = styled("div")`
  margin-top: 55px;
`;

const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 55px 0 0 0;
`;

interface Props {
  lieuExercice: LieuExercice;
}

const FormLieuxExercice = ({ lieuExercice: le }: Props) => {
  console.log(le);

  const dispatch = useDispatch();
  const medecin = useSelector(selectMedecinInfos);
  const [isLoading, setLoading] = useState(false);
  const typesVoies = useSelector(selectTypesVoies);
  const [lieuExercice, setLieuExercice] = useState(le);

  const handleCancel = () => {
    dispatch(toggleModal());
  };

  const onSubmit = async (updatedLieuData: any) => {
    try {
      setLoading(true);
      if (medecin) {
        const lieuExerciceToUpdate = medecin.tLieuxExercice.find(
          (l) => l.iFKAnnAdresse === updatedLieuData!.iFKAnnAdresse
        )!;
        const untouchedLieuxExercice = medecin.tLieuxExercice.filter(
          (l) => l.iFKAnnAdresse !== updatedLieuData!.iFKAnnAdresse
        );
        console.log({ lieuExerciceToUpdate });
        console.log({ untouchedLieuxExercice });
        delete updatedLieuData.iPKMedecinExercice;

        const updatedLieuExercice = {
          ...lieuExerciceToUpdate,
          ...updatedLieuData,
        };
        console.log({ updatedLieuExercice });

        await updateAdresseMedecins(
          medecin.iFKAnnuaire,
          lieuExercice!.iFKAnnAdresse,
          {
            ...updatedLieuData,
            dFin:
              updatedLieuExercice.dFin === "" ? null : updatedLieuExercice.dFin,
          }
        );

        dispatch(
          setMedecinInfos({
            medecinInfos: {
              ...medecin,
              tLieuxExercice: [
                ...untouchedLieuxExercice,
                {
                  ...updatedLieuExercice,
                  dFin:
                    updatedLieuExercice.dFin === ""
                      ? null
                      : updatedLieuExercice.dFin,
                },
              ],
            },
          })
        );
      }
    } catch (errors) {
      console.error(errors);
    } finally {
      setLoading(false);
      dispatch(toggleModal());
    }
  };

  return (
    <BlackModal>
      <WrapperTitle className="wrapper-title">
        <TextIcon
          size="medium"
          color="white"
          label="Modifier un lieu d'exercice"
          icon="lieux"
          center
        />
      </WrapperTitle>
      <Content>
        <div>
          <div className="col-span2">
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="nom">
                Libellé
              </Label>
              <StyledInput
                name="sLibelle"
                id="sLibelle"
                type="text"
                defaultValue={lieuExercice.sLibelle}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sLibelle: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="sNumAdeli">
                Numéro Adeli
              </Label>
              <StyledInput
                name="sNumAdeli"
                id="sNumAdeli"
                type="text"
                defaultValue={lieuExercice.sNumAdeli}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sNumAdeli: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
          </div>
          <div className="col-span2">
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="sDebut">
                Depuis le
              </Label>
              <StyledInput
                name="dDebut"
                id="dDebut"
                type="date"
                defaultValue={lieuExercice.dDebut as unknown as string}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      dDebut: e.target.value as unknown as Date,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="dFin">
                Jusqu'au
              </Label>
              <StyledInput
                name="dFin"
                id="dFin"
                type="date"
                defaultValue={
                  (lieuExercice.dFin as unknown as string) || undefined
                }
                onChange={(e) => {
                  console.log(e.target.value);
                  console.log(moment(e.target.value).toDate());

                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      dFin: e.target.value as unknown as Date,
                    });
                  }
                }}
              />
            </FormItemWrapper>
          </div>
          <div className="col-span3">
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="bAdressePrincipale">
                Adresse Principale
              </Label>
              <Checkbox
                handleChange={(e: any) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      bAdressePrincipale: e.target.checked,
                    });
                  }
                }}
                checked={lieuExercice.bAdressePrincipale}
                // register={register}
                name="bAdressePrincipale"
              />
            </FormItemWrapper>
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="bAdresseCourrier">
                Adresse Courrier
              </Label>
              <Checkbox
                handleChange={(e: any) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      bAdresseCourrier: e.target.checked,
                    });
                  }
                }}
                checked={lieuExercice.bAdresseCourrier}
                name="bAdresseCourrier"
              />
            </FormItemWrapper>
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="bVisiteLivraison">
                Visite Livraison
              </Label>
              <Checkbox
                handleChange={(e: any) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      bVisiteLivraison: e.target.checked,
                    });
                  }
                }}
                checked={lieuExercice.bVisiteLivraison}
                name="bVisiteLivraison"
              />
            </FormItemWrapper>
          </div>
          <div className="col-span2">
            <FormItemWrapper direction="horizontal">
              <Label minWidth="100px" htmlFor="sNumeroVoie">
                N° de voie
              </Label>
              <StyledInput
                name="sNumeroVoie"
                id="sNumeroVoie"
                type="text"
                defaultValue={lieuExercice.sNumeroVoie}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sNumeroVoie: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="horizontal">
              <FormSelectWrapper name="Type voie">
                <Select
                  defaultValue={lieuExercice.sCodeTypeVoie}
                  value={lieuExercice.sCodeTypeVoie}
                  options={typesVoies?.map((data) => {
                    return {
                      label: data.sLibelle,
                      value: data.sCode,
                    };
                  })}
                  onSelect={(value: string) => {
                    if (lieuExercice) {
                      setLieuExercice({
                        ...lieuExercice,
                        sCodeTypeVoie: value,
                      });
                    }
                  }}
                />
              </FormSelectWrapper>
            </FormItemWrapper>
          </div>
          <FormItemWrapper direction="horizontal">
            <Label minWidth="100px" htmlFor="sAdresse">
              Adresse
            </Label>
            <TextareaWrapper>
              <Textarea
                name="sAdresse"
                id="sAdresse"
                onChange={(e: any) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sAdresse: e.target.value,
                    });
                  }
                }}
                value={lieuExercice.sAdresse}
              ></Textarea>
            </TextareaWrapper>
          </FormItemWrapper>

          <div className="col-span2">
            <FormItemWrapper direction="horizontal">
              <Label minWidth="100px" htmlFor="sBatiment">
                Bâtiment
              </Label>
              <StyledInput
                name="sBatiment"
                id="sBatiment"
                type="text"
                defaultValue={lieuExercice.sBatiment}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sBatiment: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="horizontal">
              <Label minWidth="100px" htmlFor="sInterphone">
                Interphone
              </Label>
              <StyledInput
                name="sInterphone"
                id="sInterphone"
                type="text"
                defaultValue={lieuExercice.sInterphone}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sInterphone: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
          </div>
          <div className="col-span2">
            <FormItemWrapper direction="horizontal">
              <Label minWidth="100px" htmlFor="sEtage">
                Étage
              </Label>
              <StyledInput
                name="sEtage"
                id="sEtage"
                type="text"
                defaultValue={lieuExercice.sEtage}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sEtage: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="horizontal">
              <Label minWidth="100px" htmlFor="sPorte">
                Porte
              </Label>
              <StyledInput
                name="sPorte"
                id="sPorte"
                type="text"
                defaultValue={lieuExercice.sPorte}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sPorte: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
          </div>
          <div className="col-span2">
            <FormItemWrapper direction="horizontal">
              <Label minWidth="100px" htmlFor="sVille">
                Ville
              </Label>
              <StyledInput
                name="sVille"
                id="sVille"
                type="text"
                defaultValue={lieuExercice.sVille}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sVille: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="horizontal">
              <Label minWidth="100px" htmlFor="sCodePostal">
                Code postal
              </Label>

              <StyledInput
                id="sCodePostal"
                name="sCodePostal"
                type="text"
                defaultValue={lieuExercice.sCodePostal}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sCodePostal: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
          </div>
          <div className="col-span3">
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="sTelFixe">
                Téléphone Fixe
              </Label>
              <StyledInput
                name="sTelFixe"
                id="sTelFixe"
                type="text"
                defaultValue={lieuExercice.sTelFixe}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sTelFixe: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="sTelAutre">
                Téléphone Autre
              </Label>
              <StyledInput
                name="sTelAutre"
                id="sTelAutre"
                type="text"
                defaultValue={lieuExercice.sTelAutre}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sTelAutre: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="sTelInformation">
                Téléphone Information
              </Label>
              <StyledInput
                name="sTelInformation"
                id="sTelInformation"
                type="text"
                defaultValue={lieuExercice.sTelInformation}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sTelInformation: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
          </div>
          <div className="col-span2">
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="sFax">
                Fax
              </Label>
              <StyledInput
                name="sFax"
                id="sFax"
                type="text"
                defaultValue={lieuExercice.sFax}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sFax: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
            <FormItemWrapper direction="vertical">
              <Label minWidth="135px" htmlFor="sInformation">
                Information
              </Label>
              <StyledInput
                name="sInformation"
                id="sInformation"
                type="text"
                defaultValue={lieuExercice.sInformation}
                onChange={(e) => {
                  if (lieuExercice) {
                    setLieuExercice({
                      ...lieuExercice,
                      sInformation: e.target.value,
                    });
                  }
                }}
              />
            </FormItemWrapper>
          </div>
          <WrapperButtons>
            <PrimaryButton
              label="Annuler"
              as="button"
              size="S"
              buttonStyle="danger"
              type="reset"
              minWidth="157px"
              onClick={handleCancel}
            />
            <PrimaryButton
              disabled={isLoading}
              as="button"
              label={isLoading ? "Envois en cours" : "Valider"}
              size="S"
              buttonStyle={isLoading ? "neutral" : "primary"}
              type="submit"
              minWidth="157px"
              onClick={() => {
                console.log(lieuExercice);

                if (lieuExercice) {
                  onSubmit(lieuExercice);
                }
              }}
            />
          </WrapperButtons>
        </div>
      </Content>
    </BlackModal>
  );
};

export default FormLieuxExercice;
