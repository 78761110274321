import { Form, FormInstance, notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrescriptions } from "../../../../api/prescriptions.api";
import Medecin from "../../../../interfaces/medecin.interface";
import { IPrescription } from "../../../../interfaces/prescription.interface";
import {
  closeFilter,
  selectIsFilterOpen,
} from "../../../../redux/filter/filter.slice";
import { selectMedecinInfos } from "../../../../redux/utilisateur/utilisateur.slice";
import { PrescriptionsColumn } from "../interfaces/filtersPrescriptionsType";

export const adaptToTable = (list: IPrescription[]): PrescriptionsColumn[] => {
  return list.length
    ? list.map(({ oPatient, ...rest }, index) => ({
        ...oPatient,
        ...rest,
        key: index,
      }))
    : [];
};
export type Filter =
  | "sFKCodesPrescription"
  | "sJoursRestants"
  | "sNomPatient"
  | "sPeriodeDebut"
  | "sPeriodeFin"
  | "sPeriodeProchainRdvDebut"
  | "sPeriodeProchainRdvFin"
  | "tLieuxExercice";

export type FilterOptions = {
  filter: Filter;
  value: string | string[];
};
export interface FiltersPrescriptionsInput {
  prescriptions: IPrescription[];
  setPrescriptions: React.Dispatch<React.SetStateAction<IPrescription[]>>;
  setListRenouvellement: React.Dispatch<
    React.SetStateAction<PrescriptionsColumn[] | undefined>
  >;
}

export interface FiltersPrescriptionsService {
  isFilterOpen: boolean;
  form: FormInstance<any> | undefined;
  addFilter: (filter: Filter, value: any) => void;
  handleReset: () => void;
  search: (options: FilterOptions[]) => Promise<void>;
  medecin: Medecin | undefined;
  options: FilterOptions[];
  setOptions: React.Dispatch<React.SetStateAction<FilterOptions[]>>;

  patientFilters: string[];
  setPatientFilters: React.Dispatch<React.SetStateAction<string[]>>;

  forfaitFilters: string[];
  setForfaitFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

export function useFiltersPrescriptions(
  input: FiltersPrescriptionsInput
): FiltersPrescriptionsService {
  const [options, setOptions] = useState<FilterOptions[]>([]);
  const [patientFilters, setPatientFilters] = useState<string[]>([]);
  const [forfaitFilters, setForfaitFilters] = useState<string[]>([]);
  const medecin = useSelector(selectMedecinInfos);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const isFilterOpen: boolean = useSelector(selectIsFilterOpen);

  useEffect(() => {
    return () => {
      dispatch(closeFilter());
    };
  }, [dispatch]);

  const search = async (options: FilterOptions[]) => {
    if (!options.length) {
      input.setListRenouvellement(adaptToTable(input.prescriptions));
      return;
    }

    // New options to transform filter forfait to string
    const newOptions = options.map((option) => {
      if (
        option.filter === "sFKCodesPrescription" ||
        option.filter === "tLieuxExercice"
      ) {
        if (Array.isArray(option.value)) {
          const valueStringified = option.value.join(",");
          return {
            ...option,
            value: valueStringified,
          };
        }
      }

      return option;
    });

    try {
      const filteredPrescriptions = await getPrescriptions(
        medecin!.iFKAnnuaire,
        newOptions
      );
      input.setListRenouvellement(adaptToTable(filteredPrescriptions));
    } catch (error) {
      notification.error({
        description:
          "Nous rencontrons une erreur de chargement de vos informations, veuillez contacter votre administrateur",
        message: "Echec du chargement du tableau des prescriptions",
      });
    }
  };

  const addFilter = (filter: Filter, value: any) =>
    setOptions((options) => {
      // FIXME: any
      const otherOptions = options?.filter((o) => o.filter !== filter) || [];
      return (Array.isArray(value) ? value.length : value)
        ? [...otherOptions, { filter, value }]
        : otherOptions;
    });

  useEffect(() => {
    input.setListRenouvellement(adaptToTable(input.prescriptions));

    if (input.prescriptions.length) {
      const forfaits = input.prescriptions.map(
        (prescription) => prescription.sFKCodePrescription
      );

      setForfaitFilters([...new Set(forfaits)].sort());

      const patients = input.prescriptions.map((list) => list.oPatient.sNom);
      setPatientFilters([...new Set(patients)].sort());
    }
    /* eslint-disable  react-hooks/exhaustive-deps*/
  }, [input.prescriptions, input.setListRenouvellement]);

  const handleReset = () => {
    form.resetFields();
    search([]);
    setOptions([]);
  };

  return {
    isFilterOpen: isFilterOpen,
    form: form,
    addFilter: addFilter,
    handleReset: handleReset,
    search: search,
    forfaitFilters: forfaitFilters,
    options: options,
    patientFilters: patientFilters,
    setForfaitFilters: setForfaitFilters,
    setPatientFilters: setPatientFilters,
    setOptions: setOptions,
    medecin: medecin,
  };
}
