import { InputProps } from "antd";
import Styled from "styled-components";

export const StyledInput = Styled.input<InputProps>`
  padding: 0 20px;
  height: 50px;
  line-height: 1;
  width: 100%;
  max-width: 315px;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.neutral[100]};
  background-color: ${(props) => props.theme.colors.neutral[170]};
  border: 1px solid ${(props) => props.theme.colors.neutral[170]};
  border-radius: 13px;
  outline-style: none;
  text-align: left;
  transition: border 0.2s linear;

  ::placeholder {
    background-color: ${(props) => props.theme.colors.neutral[80]};
    color: ${(props) => props.theme.colors.neutral[130]};
  }
  &:focus{
    border: 1px solid ${(props) => props.theme.colors.neutral[100]};
  }

  &.error,  &:invalid{
    border: 1px solid ${(props) => props.theme.colors.danger[100]};
  }

  [type="date"] {
    background:#fff url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;")
  }
`;

export const StyledInputNeutral = Styled.input<InputProps>`
  padding: 0 20px;
  height: 50px;
  line-height: 1;
  width: 100%;
  max-width: 315px;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.neutral[190]};
  background-color: ${(props) => props.theme.colors.neutral[140]};
  border: 1px solid ${(props) => props.theme.colors.neutral[190]};
  border-radius: 13px;
  outline-style: none;
  text-align: left;
  transition: border 0.2s linear;

  ::placeholder {
    background-color: ${(props) => props.theme.colors.neutral[140]};
    color: ${(props) => props.theme.colors.neutral[190]};
    opacity: 0.3;
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.neutral[100]};
  }

  &.error,
  &:invalid {
    border: 1px solid ${(props) => props.theme.colors.danger[100]};
  }

  [type="date"] {
    background: #fff
      url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;");
  }
`;
