import styled from "styled-components";

// Icons
import contactEmail from "../../../assets/icons/contact_email.svg";
import contactPhone from "../../../assets/icons/contact_phone.svg";
import contactSpecialite from "../../../assets/icons/contact_specialite.svg";
import prescAttente from "../../../assets/icons/presc_attente.svg";
import prescEnCours from "../../../assets/icons/presc_encours_white.svg";
import profil from "../../../assets/icons/profil.svg";
import tableau from "../../../assets/icons/tableau.svg";
import signatureMulti from "../../../assets/icons/signature_multi.svg";
import placeWhite from "../../../assets/icons/place_white.svg";
import pictoPanier from "../../../assets/icons/picto_panier_doc.svg";

interface PropsStyleText {
  size?: string;
  uppercase?: boolean;
  center?: boolean;
  color?: string;
}

const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 25px;
  gap: 10px;
  border-radius: 20px;
  font-weight: 400;
  transition: all 0.2s linear;
  border: none;
  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;

//const StyleText = styled.p<PropsStyleText>`
const StyleText = styled.p<PropsStyleText>`
  display: flex;
  align-items: center;
  ${(props) => props.center && "justify-content: center;"}
  gap: 3px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.neutral[140]};
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 2px;

  ${(props) => props.size === "medium" && "font-size: 1.5rem;"}
  ${(props) => props.color === "black" && "color: #3A3D4B;"}
  ${(props) => props.color === "grey" && "color: #aaa;"}

  ${(props) => props.uppercase && "text-transform: uppercase;"}

  &:before {
    display: block;
  }

  &.contactSpecialite {
    &:before {
      content: url(${contactSpecialite});
    }
  }
  &.contactEmail {
    &:before {
      content: url(${contactEmail});
    }
  }
  &.contactPhone {
    &:before {
      content: url(${contactPhone});
    }
  }
  &.prescAttente {
    &:before {
      content: url(${prescAttente});
    }
  }
  &.prescEnCours {
    &:before {
      content: url(${prescEnCours});
    }
  }
  &.prescEnCours {
    &:before {
      content: url(${prescEnCours});
    }
  }
  &.tableau {
    &:before {
      content: url(${tableau});
    }
  }
  &.profil {
    &:before {
      content: url(${profil});
    }
  }
  &.signature {
    &:before {
      color: #eee;
      content: url(${signatureMulti});
    }
  }
  &.pictoPanier {
    &:before {
      content: url(${pictoPanier});
    }
  }
  &.lieux {
    &:before {
      content: url(${placeWhite});
    }
  }
`;

interface Props {
  label: string;
  icon: string;
  size?: string;
  color?: string;
  uppercase?: boolean;
  center?: boolean;
  onClick: (e?: React.MouseEvent) => void;
}

const TextIconASigner = ({
  label,
  center,
  icon,
  size,
  color,
  uppercase,
  onClick,
}: Props) => {
  return (
    <WrapperButton onClick={onClick}>
      <StyleText
        center={center}
        size={size}
        color={color}
        className={icon}
        uppercase={uppercase}
      >
        {label}
      </StyleText>
    </WrapperButton>
  );
};

export default TextIconASigner;
