import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import warning from "../../assets/icons/warning.svg";
import WhiteModal from "../atoms/modals/WhiteModal";
import { Text } from "@ads/ui";
import { Progress } from "antd";

const Wrapper = styled.div`
  position: relative;
`;

interface PopconfirmTitleProps {
  sendContent: string;
  percentProgress: number;
  updateStatus: Function;
  updateProgress: Function;
}

const StyledSvg = styled.div`
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${(props) => props.theme.colors.secondary[100]};
  border-radius: 50%;
`;

const Warning = styled.img`
  background-color: ${(props) => props.theme.colors.secondary[100]};
`;

const ModalProgress: FunctionComponent<PopconfirmTitleProps> = ({
  sendContent,
  percentProgress,
  updateStatus,
  updateProgress,
}) => {
  let [content, getContent] = useState(sendContent);
  let [progress, getPercentProgress] = useState(percentProgress);

  const updateModalContent = () => {
    setTimeout(() => {
      getContent(updateStatus());
      getPercentProgress(updateProgress());
      updateModalContent();
    }, 1000);
  };

  updateModalContent();

  return (
    <Wrapper>
      <WhiteModal>
        <StyledSvg>
          <Warning src={warning} />
        </StyledSvg>
        <Text
          label="Envoi des documents à signer"
          sColor={{ style: "neutral", variant: 170 }}
          fontWeight="600"
          marginText="10px 0 20px 0"
          fontSize="1.6rem"
        />

        <Progress type="circle" percent={progress} />
        <Text
          label={content}
          sColor={{ style: "neutral", variant: 170 }}
          fontWeight="600"
          marginText="10px 0 20px 0"
          fontSize="1.6rem"
          textAlign="center"
        />
      </WhiteModal>
    </Wrapper>
  );
};

export default ModalProgress;
