import styled from 'styled-components';

import { mainColor } from '../ordonnance.style';

export const SectionHeader = styled.div`
  background-color: ${mainColor};
  color: white;
  margin: 15px 0 10px;
  text-align: center;
  width: 100%;
`;
