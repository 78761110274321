import { Checkbox, Row } from "antd";
import React from "react";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

import {
  CheckboxComplete,
  CheckboxCompleteLabel,
  CheckboxGroupRowAlign,
  DEPStyledFormItemTextArea,
  DEPStyledFormItemWithoutWidth,
  DefaultInput,
  StyledSpan,
} from "../../elements/DEPinput.elements";
import { Wrapper } from "../../elements/sections.elements";

const Bloc = styled(DEPStyledFormItemTextArea)`
  grid-area: bloc;
`;

const Body = styled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-rows: 0.2fr 0.2fr 0.7fr 1fr;
  border-radius: 12px;
  background-color: lightgray;
`;

const Date = styled(DEPStyledFormItemWithoutWidth)`
  grid-area: date;
`;

const SectionWrapper = styled(Wrapper)`
  border-right: 1px solid black;
`;

const Sign = styled(DEPStyledFormItemTextArea)`
  grid-area: sign;
`;

const Signature = styled.div`
  display: grid;
  grid-template-areas:
    "date bloc"
    "sign bloc";
`;

export default function ControleMedicalSection() {
  return (
    <SectionWrapper>
      <Body>
        <Row>
          <CheckboxGroupRowAlign>
            <CheckboxComplete>
              <Checkbox disabled />
              <CheckboxCompleteLabel>Avis favorable du</CheckboxCompleteLabel>
              <DefaultInput disabled type="date" />

              <CheckboxCompleteLabel>Au</CheckboxCompleteLabel>
              <DefaultInput disabled type="date" />
            </CheckboxComplete>
          </CheckboxGroupRowAlign>
        </Row>

        <Row>
          <CheckboxGroupRowAlign>
            <Checkbox disabled>Forfait association</Checkbox>
            <Checkbox disabled>Location </Checkbox>
            <Checkbox disabled>Achat</Checkbox>
          </CheckboxGroupRowAlign>
        </Row>

        <Row>
          <CheckboxComplete>
            <Checkbox disabled />
            <CheckboxCompleteLabel>Avis défavorable</CheckboxCompleteLabel>
            <StyledSpan>Motif</StyledSpan>
            <TextArea disabled />
          </CheckboxComplete>
        </Row>

        <Signature>
          <Date label="Date">
            <DefaultInput disabled type="date" />
          </Date>

          <Sign label="Signature">
            <TextArea cols={3} disabled rows={2} />
          </Sign>

          <Bloc>
            <TextArea cols={3} disabled rows={3} />
          </Bloc>
        </Signature>
      </Body>
    </SectionWrapper>
  );
}
