import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
interface Props {
  datas: any[];
}

const DonutStyle = styled("div")`
  .apexcharts-legend {
    left: 24px !important;
    scrollbar-width: thin;
  }

  #chartContainer .apexcharts-tooltip {
    font-size: 40px !important;
  }

  #chartContainer .apexcharts-tooltip .apexcharts-tooltip-series-group.active {
    background: ${(props) => props.theme.colors.neutral[100]} !important;
  }

  .apexcharts-tooltip-text-value,
  .apexcharts-tooltip-text-z-value {
    margin-left: 0;
  }

  .apexcharts-tooltip-text-label {
    display: block;
    color: ${(props) => props.theme.colors.neutral[100]};
  }

  .apexcharts-tooltip.apexcharts-theme-dark {
    background: none;
    box-shadow: none;
  }

  .apexcharts-tooltip-series-group.apexcharts-active {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    background-color: ${(props) => props.theme.colors.neutral[180]} !important;
    box-shadow: 0px 3px 20px #00000029;
  }

  .apexcharts-canvas ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .apexcharts-tooltip-text-value {
    color: ${(props) => props.theme.colors.primary[100]};
  }

  .apexcharts-legend-marker {
    display: none;
  }

  .apexcharts-legend-text {
    display: block;
    margin-bottom: 4px;
    font-family: "Poppins" !important;
    text-transform: uppercase;
    font-weight: 600 !important;
  }

  .apexcharts-legend-text span {
    font-size: 1.4rem;
    font-family: "Poppins" !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    color: ${(props) => props.theme.colors.primary[100]};
  }

  .apexcharts-legend-series {
    margin: 0 !important;
  }
`;

const DonutChart = ({ datas }: Props) => {
  const series = datas.map((d) => d.iNbTypePrestation);
  const labels = datas.map((d) => d.sTypePrestation);

  const defaultStateChart = {
    series: series,

    options: {
      labels: labels,
      stroke: {
        width: 1,
      },
      chart: {
        dropShadow: {
          enabled: true,
          enabledOnSeries: [],
          top: 5,
          left: 0,
          blur: 0,
          color: "#eeeeee",
          opacity: 1,
        },
      },
      legend: {
        position: "left" as const,
        show: true,
        fontSize: "15px",
        formatter: function (seriesName: string, opts: any) {
          return (
            seriesName + "  (" + opts.w.globals.series[opts.seriesIndex] + ")"
          );
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "30%",
          },
        },
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        fillSeriesColor: true,
        style: {
          fontSize: "12px",
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        marker: {
          show: true,
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },
      colors: [
        "#008000",
        "#00FF00",
        "#00FF7F",
        "#6EBF3B",
        "#86D654",
        "#8FBC8F",
        "#93EA5D",
        "#A7F576",
        "#7CFC00",
        "#3CB371",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const adjust = (color: string, amount: number) => {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  };

  const [stateChart] = useState(defaultStateChart);
  const theme:any = useTheme();

  const options: ApexOptions = stateChart.options;
  const colors = [];

  for (let i = 1; i <= stateChart.series.length; i++) {
    if (i < stateChart.series.length / 2) {
      colors.push(
        adjust(
          theme.colors.primary[100],
          -(stateChart.series.length - i) * 10
        )
      );
    } else {
      colors.push(
        adjust(
          theme.colors.primary[100],
          (stateChart.series.length - i) * 10
        )
      );
    }
  }

  return (
    <DonutStyle>
      <Chart
        options={{ ...options, colors }}
        series={stateChart.series}
        type="donut"
        height={250}
      />
    </DonutStyle>
  );
};

export default DonutChart;
