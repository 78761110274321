import { useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";

import Layout from "../../components/templates/Layout";
import TabsHeader from "../../components/molecules/tabs/TabsHeader";
import TabsHeaderItem from "../../components/atoms/tabs/TabsHeaderItem";
import TabsContent from "../../components/molecules/tabs/TabsContent";

//
import ProfilItemInfos from "../../components/molecules/profil/profilItem/ProfilItemInfos";
import ProfilItemLieu from "../../components/molecules/profil/profilItem/ProfilItemLieu";
import ProfilItemSignature from "../../components/molecules/profil/profilItem/ProfilItemSignature";

import ModelesTabContent from "../../components/organisms/modeles/ModelesTabContent";

const Wrapper = styled(motion.div)``;

const Content = styled(motion.div)`
  width: 860px;
  margin: 0 auto 0 auto;
  padding-top: 166px;
  min-height: ${() => window.innerHeight - 182}px;
  opacity: 1;
`;

const handleClickTab = (event: any) => {
  // Remove active class from every tab header item elements
  const allTabHeaderItem = document.querySelectorAll(".tab-header-item");
  allTabHeaderItem.forEach((tabElement) => {
    tabElement.classList.remove("active");
  });

  // Add active class to current tab header item
  event.target.classList.add("active");

  // Remove active class from every tab content elements
  const allTabContent = document.querySelectorAll(".tab-content");
  allTabContent.forEach((tabElement) => {
    tabElement.classList.remove("active");
  });

  // Display tab content element in target
  const tabContent = document.querySelector(`#${event.target.dataset.target}`);
  tabContent && tabContent.classList.add("active");
};

export default function InfosView() {
  const router: any = useSelector((state: any) => state.router);
  const wrapperVariants = {
    initial: { y: 0 },
    enter: { y: 0 },
    exit:
      router.location.pathname !== "/utilisateur/connexion"
        ? { y: 420 }
        : { opacity: 0 },
  };
  const contentVariants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <Layout
      main={
        <Wrapper
          initial="initial"
          animate="enter"
          variants={wrapperVariants}
          transition={{ duration: 1 }}
        >
          <Content
            initial="initial"
            animate="enter"
            variants={contentVariants}
            transition={{ duration: 0.4 }}
          >
            <TabsHeader>
              <TabsHeaderItem
                label="Profil"
                active
                dataAttr="tab-0"
                onClick={handleClickTab}
              />
              <TabsHeaderItem
                label="Modèles de textes"
                dataAttr="tab-1"
                onClick={handleClickTab}
              />
            </TabsHeader>
            <TabsContent id="tab-0" active={true}>
              <ProfilItemInfos />
              <ProfilItemSignature />
              <ProfilItemLieu />
            </TabsContent>
            <TabsContent id="tab-1" active={false}>
              <ModelesTabContent />
            </TabsContent>
          </Content>
        </Wrapper>
      }
    />
  );
}
