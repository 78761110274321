import { useState, FunctionComponent } from "react";
import styled from "styled-components";
import { Text } from "@ads/ui";
import Svg from "../../assets/icons/picto_dl.svg";

interface Props {
  label: string;
  href?: string;
  onclick?: () => void;
  hover?: boolean;
}

const StyledLayout = styled.a`
  display: block;
  background: ${(props) => props.theme.colors.neutral[180]};
  border: unset;
  outline-style: none;
  border-radius: 3.4rem;
  padding: 1.4rem 6.1rem 1.4rem 2.3rem;
  margin-bottom: 1.4rem;
  &:hover {
    background: ${(props) => props.theme.colors.neutral[170]};
    cursor: pointer;
  }
  text-decoration: none;
`;

const StyledDiv = styled.div`
  word-wrap: break-word;
  display: block;
  position: relative;

  p {
    margin: 0;
    padding: 0;
  }
`;

const StyledSvg = styled.img<Omit<Props, "label" | "href">>`
  position: absolute;
  right: -22rem;
  top: calc(50% - 2.2rem);
  transform: ${(props) => {
    if (props.hover) {
      return `
          translateY(50%);
        `;
    }
    return `
          translateY(0%);
        `;
  }};
  transition: all 0.3s ease-in-out;
`;

const DownloadDocumentLayout: FunctionComponent<Props> = ({
  label,
  href,
  onclick,
}) => {
  const [isHover, setIsHover] = useState(false);
  const handleHover = () => {
    setIsHover(() => !isHover);
  };
  return (
    <StyledLayout
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      href={href}
      onClick={onclick}
    >
      <StyledDiv>
        <Text
          label={label}
          font="primary"
          sColor={{ style: "neutral", variant: 100 }}
          fontSize="1.2rem"
          lineHeight="1.8rem"
        />
        <StyledSvg
          src={Svg}
          alt="Svg"
          style={{ opacity: isHover ? "1" : "0" }}
          hover={isHover}
        />
      </StyledDiv>
    </StyledLayout>
  );
};

export default DownloadDocumentLayout;
