import { Form, Input } from "antd";
import FormItem from "antd/lib/form/FormItem";
import React from "react";
import styled from "styled-components";

import {
  CheckboxItem,
  CheckboxList,
  StyledCheckbox,
  StyledCheckboxGroup,
  StyledFormItem,
} from "../../elements/input.elements";
import { Header } from "../../elements/header.elements";

const Body = styled.div`
  padding: 5px 0px 5px 0px;
`;

const ConvocationCheckbox = styled(StyledCheckbox)`
  grid-column: 2/3;
`;

const InnerForm = styled(Form)`
  grid-row: 3;
`;

const RefusCheckbox = styled(StyledCheckbox)`
  grid-row: 2;
`;

const StyledCheckboxList = styled(CheckboxList)`
  display: flex;
  align-items: flex-end;
`;

const StyledForm = styled(Form)`
  border: 1px solid lightgray;
  height: min-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 2fr;
`;

const StyledFormDate = styled(StyledFormItem)`
  grid-area: date;
  border: none;
  grid-column: 3;
  grid-row: 3;
`;

export default function AvisSection() {
  return (
    <>
      <Header>Avis du médecin conseil</Header>
      <Body>
        <StyledForm layout="inline">
          <StyledCheckbox disabled>Accord</StyledCheckbox>

          <ConvocationCheckbox disabled>Convocation</ConvocationCheckbox>

          <RefusCheckbox disabled>Refus</RefusCheckbox>

          <InnerForm layout="vertical">
            <FormItem>
              <StyledCheckboxGroup>
                <CheckboxList>
                  <CheckboxItem>
                    <StyledCheckbox disabled>d'ordre médical</StyledCheckbox>
                  </CheckboxItem>

                  <CheckboxItem>
                    <StyledCheckbox disabled>
                      d'ordre administratif
                    </StyledCheckbox>
                  </CheckboxItem>
                </CheckboxList>
              </StyledCheckboxGroup>
            </FormItem>
          </InnerForm>

          <StyledCheckboxList>
            <CheckboxItem>
              <StyledCheckbox disabled>motif :</StyledCheckbox>
            </CheckboxItem>
          </StyledCheckboxList>

          <StyledFormDate label="Date">
            <Input disabled type="date" />
          </StyledFormDate>
        </StyledForm>
      </Body>
    </>
  );
}
