import { Select } from "antd";
import soundex from "../../../../utils/soundex";
import { v4 as uuidv4 } from "uuid";
import {
  Filter,
  FiltersPrescriptionsService,
} from "../hooks/useFiltersPrescriptions";

export function SelectPatientPrescription(
  controler: FiltersPrescriptionsService
) {
  const searchPatient = async (filter: Filter, value: any) => {
    console.log(value);

    // FIXME: any
    const otherOptions =
      controler.options?.filter((o) => o.filter !== filter) || [];

    return (Array.isArray(value) ? value.length : value)
      ? controler.search([...otherOptions, { filter, value }])
      : controler.search(otherOptions);
  };

  return (
    <Select
      allowClear
      autoClearSearchValue
      placeholder="Patient"
      filterOption={(input, option) =>
        `${option?.value}`.toUpperCase().includes(input.toUpperCase()) ||
        soundex(input) === soundex(`${option?.value}`)
      }
      onSelect={(value: any) => {
        searchPatient("sNomPatient", value);
      }}
      onClear={() => controler.handleReset()}
      showSearch
      value={
        controler.options.find((o) => o.filter === "sNomPatient")
          ?.value as string
      }
    >
      {controler.patientFilters.map((sNom: string) => (
        <Select.Option key={uuidv4()} value={sNom}>
          {sNom}
        </Select.Option>
      ))}
    </Select>
  );
}
