import { createSlice } from "@reduxjs/toolkit";

export interface FilterState {
  isOpen: Boolean;
}

const initialState: FilterState = {
  isOpen: false,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    toggleFilter(state) {
      state.isOpen = !state.isOpen;
    },
    closeFilter(state) {
      state.isOpen = false;
    },
  },
});

export const { toggleFilter } = filterSlice.actions;
export const { closeFilter } = filterSlice.actions;
export const selectIsFilterOpen = (state: any) => state.filter.isOpen;
export default filterSlice.reducer;
