import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";
import Contact from "../organisms/contact/Contact";
import Modal from "../organisms/modals/Modal";
import Header from "../organisms/header/header.element";

import { useSelector } from "react-redux";
import {
  createSetModalFunction,
  selectIsModalOpen,
} from "../../redux/modal/modal.slice";

import { selectIsFilterOpen } from "../../redux/filter/filter.slice";
import { selectUtilisateur } from "../../redux/utilisateur/utilisateur.slice";

const LayoutWrapper = styled(motion.div)`
  position: relative;
  width: 100%;

  &.modal-is-open {
    filter: blur(5px);
    pointer-events: none;
    max-height: 100vh;
    overflow: hidden;
    #header {
      position: relative;
    }
    #main {
      position: inherit;
    }
  }
`;

interface LayoutProps {
  header?: JSX.Element | null;
  main?: JSX.Element | null;
  navigation?: boolean;
  scrolling?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({
  header = null,
  main = null,
  navigation = true,
}) => {
  const [modalContent, setModalContent] = useState<JSX.Element | undefined>();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createSetModalFunction(setModalContent));
  }, [dispatch]);

  const isModalOpen: boolean = useSelector(selectIsModalOpen);
  const isFilterOpen: boolean = useSelector(selectIsFilterOpen);
  const identity = useSelector(selectUtilisateur);

  return (
    <>
      <LayoutWrapper
        initial="initial"
        animate="enter"
        transition={{ duration: 1 }}
        className={isModalOpen ? "modal-is-open" : ""}
      >
        <Header
          navigation={navigation}
          header={header}
          isFilterOpen={isFilterOpen}
        />
        <main>{main}</main>
      </LayoutWrapper>
      {isModalOpen && <Modal modalContent={modalContent} isModalOpen />}
      {identity && <Contact />}
    </>
  );
};

export default Layout;
