import React from "react";
import { Input, Form, DatePicker } from "antd";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import FormItem from "antd/lib/form/FormItem";

import styled from "styled-components";

import {
  Indication,
  StyledFormItem,
  StyledInput,
  StyledInputSection,
} from "../../elements/input.elements";
import { Header } from "../../elements/header.elements";
import type IDAP from "../../../../interfaces/dap.interface";
import moment from "moment";
import { IPrescriptionRenouvellement } from "../../../../interfaces/prescription.interface";

const Body = styled.div``;

const DateNaissance = styled(StyledFormItem)`
  grid-column: 1;

  & .ant-input {
    width: 140px;
  }
`;

const FormIndication = styled(StyledFormItem)`
  grid-column: 2;
  grid-row: 2/4;
`;

const NumImmatriculation = styled(StyledFormItem)`
  grid-column: 1;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-input {
    width: min-content;
  }
`;

const PersonInputLayout = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 2.5fr 0.5fr;
  grid-template-rows: auto;
`;

const PrenomNom = styled(StyledFormItem)``;

const PrenomNomIndication = styled.div`
  grid-column: span 2;
`;

const TextArea = styled(Input.TextArea)`
  min-width: 200px;
  width: 100%;
`;

const InputNumber = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface PersonSectionProps {
  justificatif: IDAP;
  onChange: (e: ChangeEvent) => void;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export default function PersonSection({
  prescription,
  setPrescription,
  justificatif,
  onChange,
}: PersonSectionProps) {
  return (
    <>
      <Header>Personne recevant les soins et assuré(e)</Header>
      <Body>
        <StyledInputSection>
          <Form layout="vertical">
            <FormItem
              label="Personne recevant les soins :"
              style={{ fontWeight: "bold" }}
            >
              <PersonInputLayout>
                <PrenomNomIndication>
                  <PrenomNom label="Nom et prénom">
                    <Input
                      value={justificatif.sDAPNomPrenomPatient}
                      name="sDAPNomPrenomPatient"
                      onChange={onChange}
                    />
                  </PrenomNom>

                  <Indication>
                    (Nom de famille (de naissance) suivi du nom d'usage
                    (facultatif et s'il y a lieu)
                  </Indication>
                </PrenomNomIndication>

                <NumImmatriculation label="Numéro d'immatriculation">
                  <InputNumber
                    value={justificatif.sDAPNoSecuPatient}
                    maxLength={15}
                    type="number"
                    name="sDAPNoSecuPatient"
                    onChange={onChange}
                    placeholder="0 00 00 00 000 000 00"
                  />
                </NumImmatriculation>

                <DateNaissance label="Date de naissance">
                  <DatePicker
                    value={
                      justificatif.dDAPDateNaissancePatient
                        ? moment(justificatif.dDAPDateNaissancePatient)
                        : undefined
                    }
                    placeholder="jj/mm/aaaa"
                    name="dDAPDateNaissancePatient"
                    format="DD/MM/YYYY"
                    locale={locale}
                    onChange={(momentDate) => {
                      if (
                        prescription &&
                        prescription!.oJustificatifs &&
                        prescription!.oJustificatifs!.dap &&
                        momentDate
                      ) {
                        setPrescription({
                          ...prescription,
                          oJustificatifs: {
                            ...prescription.oJustificatifs,
                            dap: {
                              ...prescription.oJustificatifs.dap,
                              dDAPDateNaissancePatient: new Date(
                                momentDate.format("YYYY-MM-DD")
                              ),
                            },
                          },
                          bUpdated: true,
                        });
                      }
                    }}
                  />
                </DateNaissance>

                <FormIndication>
                  <Indication>
                    Nom et n° du centre de paiement ou de la section mutualiste
                    (pour les salariés) ou nom et n° de l'organisme conventionné
                    (pour les non salariés)
                  </Indication>

                  <TextArea
                    value={justificatif.sDAPNomRO}
                    name="sDAPNomRO"
                    onChange={onChange}
                    rows={4}
                  />
                </FormIndication>
              </PersonInputLayout>
            </FormItem>
          </Form>
        </StyledInputSection>

        <StyledInputSection>
          <Form layout="vertical">
            <FormItem label="Assuré(e) :" style={{ fontWeight: "bold" }}>
              <Indication>
                (à remplir si la personne recevant les soins n’est pas
                l’assuré(e))
              </Indication>

              <PrenomNom label="Nom et prénom">
                <Input
                  value={justificatif.sDAPNomPrenomAssure}
                  name="sDAPNomPrenomAssure"
                  onChange={onChange}
                />
              </PrenomNom>

              <Indication>
                (Nom de famille (de naissance) suivi du nom d'usage (facultatif
                et s'il y a lieu)
              </Indication>

              <NumImmatriculation label="Numéro d'immatriculation">
                <InputNumber
                  value={justificatif.sDAPNoSecuAssure}
                  maxLength={15}
                  type="number"
                  name="sDAPNoSecuAssure"
                  onChange={onChange}
                  placeholder="0 00 00 00 000 000 00"
                />
              </NumImmatriculation>
            </FormItem>
          </Form>
        </StyledInputSection>

        <StyledInput>
          <Form layout="vertical">
            <FormItem
              label="Adresse de l'assuré(e) :"
              style={{ fontWeight: "bold" }}
            >
              <TextArea
                value={justificatif.sDAPAdresseAssure}
                name="sDAPAdresseAssure"
                onChange={onChange}
                rows={2}
              />
            </FormItem>
          </Form>
        </StyledInput>
      </Body>
    </>
  );
}
