import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";

import type { IOrdonnancePPC } from "../../../../../interfaces/ordonnance.interface";
import { SectionHeader } from "../sectionHeader.elements";
import { StyledCheckboxGroup } from "../../../elements/input.elements";

const ALDFormItem = styled(FormItem)`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

const StyledForm = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;
`;

interface PriseEnChargeSectionProps {
  ordonnance: IOrdonnancePPC;
  onChange: (e: CheckboxChangeEvent) => void;
}

export default function PriseEnChargeSection({
  onChange,
  ordonnance,
}: PriseEnChargeSectionProps) {
  return (
    <>
      <SectionHeader>Conditions de prise en charge des soins</SectionHeader>

      <StyledForm>
        <FormItem>
          <Checkbox
            checked={ordonnance.bMaladie}
            name="bMaladie"
            onChange={onChange}
          >
            Maladie
          </Checkbox>
        </FormItem>

        <ALDFormItem label="Soins en rapport avec une ALD :">
          <StyledCheckboxGroup>
            <Checkbox
              checked={ordonnance.bALDVrai}
              name="bALDVrai"
              onChange={onChange}
            >
              Oui
            </Checkbox>

            <Checkbox
              checked={ordonnance.bALDFaux}
              name="bALDFaux"
              onChange={onChange}
            >
              Non
            </Checkbox>
          </StyledCheckboxGroup>
        </ALDFormItem>
      </StyledForm>
    </>
  );
}
