import { useHistory } from "react-router-dom";
import { ButtonWrapper } from "./FilterPrescriptions/FilterPrescriptions";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
export function AddPrescriptionButton() {
  const history = useHistory();

  return (
    <>
      <ButtonWrapper>
        <Button
          size="large"
          type={"primary"}
          shape="round"
          onClick={() => {
            console.log(history);

            history.push(`/prescription`);
          }}
          icon={<PlusCircleOutlined twoToneColor="#eb2f96" />}
        ></Button>
      </ButtonWrapper>
    </>
  );
}
