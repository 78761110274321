import React from "react";
import styled from "styled-components";

interface Props {
  gridArea: string;
  dt: string;
  dd?: string;
}

const ItemGrid = styled("div")`
  display: flex;
  dt {
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #73778b;
    text-transform: uppercase;
  }
  dd {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #3a3d4b;
    font-weight: 600;
  }
  &.civilite {
    grid-area: civilite;
  }
  &.adeli {
    grid-area: adeli;
  }
  &.specialite {
    grid-area: specialite;
  }
  &.nom {
    grid-area: nom;
  }
  &.mel {
    grid-area: mel;
  }
  &.prenom {
    grid-area: prenom;
  }
  &.portable {
    grid-area: portable;
  }
`;

const ProfilInfoPersoGridItem = ({ gridArea, dt, dd }: Props) => (
  <ItemGrid className={gridArea}>
    <dl>
      <dt>{dt}</dt>
      <dd>{dd}</dd>
    </dl>
  </ItemGrid>
);

export default ProfilInfoPersoGridItem;
