import { sendValidMultiRenouvellement } from '../../../api/prescriptions.api';

import { AxiosResponse } from 'axios';
import { RedirectionSignatureResponse } from '../../../interfaces/oodrive/redirectionSignatureResponse.interface';
import notification from 'antd/lib/notification';
import config from '../../../config';


// renouvellement multiple depuis dashboard
const RenouvellementMultiple = async (selectedIPKPrescriptions: string[], IFKAnnuaire: any) => {

    let iPKPrescriptions: string[] = [];
    for(let prescription of selectedIPKPrescriptions){
        let res = JSON.stringify(prescription);
        iPKPrescriptions.push(JSON.parse(res)["iPKPrescription"]);
    }
    try {
      const response: AxiosResponse = await sendValidMultiRenouvellement(IFKAnnuaire as number, iPKPrescriptions as string[]);
      let notificationPayload = {
        description: 'Le renouvellement a bien été effectué',
        message: 'Succès !',
      };
      if(response.status === 200 && config.oodriveSignatureActivated === true) {
        let redirectionSignatureResponse: RedirectionSignatureResponse = response.data as RedirectionSignatureResponse;
        
        if(redirectionSignatureResponse.url !== undefined) {
          const rediredirectURL: string = redirectionSignatureResponse.url;
          notificationPayload = {
            description: 'Le document est prêt. Vous allez être redirigé vers la plateforme de signature électronique',
            message: 'Succès !',
          };

          setTimeout(() => {
            document.location.href = rediredirectURL;
          }, 5000);
        }
      } else if(response.status === 204) {
        // signature par defaut
      }
      notification.success(notificationPayload);
    } catch (error) {
      notification.error({
        description: `Nous rencontrons une erreur à l'envoi des données du formulaire, veuillez contacter votre administrateur`,
        message: `Echec de l'envoi des données du formulaire`,
      });
    } finally {
      
    }

    /*const checkAdvancedProgressStatus = async (selectedIPKPrescriptions: string[]) => {

    }*/
}


export default RenouvellementMultiple;