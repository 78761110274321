import { Checkbox, Form } from "antd";
import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";

import { Header } from "../../elements/header.elements";
import { StyledCheckboxGroup } from "../../elements/input.elements";
import type IDAP from "../../../../interfaces/dap.interface";
import { CheckboxOrdo } from "../../../../components/atoms/buttons/CheckboxOrdo";
import { ChangeEvent } from "../../DEP/sections/medecinTraitantSection.elements";

const ALDFormItem = styled(FormItem)`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div`
  padding: 5px 0px 5px 0px;
`;

const StyledForm = styled(Form)`
  border: 1px solid lightgray;
  height: min-content;
`;

const StyledFormItem = styled(FormItem)`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;

  & > .ant-form-item-label {
    font-weight: 700;
    padding-bottom: 0px;
    text-align: left;
    width: 100%;
  }
`;

interface ConditionSectionProps {
  justificatif: IDAP;
  onChange: (e: ChangeEvent) => void;
}

export default function ConditionSection({
  justificatif,
  onChange,
}: ConditionSectionProps) {
  return (
    <>
      <Header>Conditions de prise en charge des soins</Header>

      <Body>
        <StyledForm layout="inline">
          <StyledFormItem label="Maladie">
            <Checkbox
              checked={justificatif.bMaladie}
              name="bMaladie"
              onChange={onChange}
            />
          </StyledFormItem>

          <ALDFormItem label="soins en rapport avec une ALD :">
            <StyledCheckboxGroup>
              <CheckboxOrdo
                value={justificatif.sCocheALDOui}
                name="sCocheALDOui"
                onChange={onChange}
              >
                Oui
              </CheckboxOrdo>

              <CheckboxOrdo
                value={justificatif.sCocheALDNon}
                name="sCocheALDNon"
                onChange={onChange}
              >
                Non
              </CheckboxOrdo>
            </StyledCheckboxGroup>
          </ALDFormItem>
        </StyledForm>
      </Body>
    </>
  );
}
