import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import TextIcon from "../../atoms/text/TextIcon";
import { selectMedecinInfos } from "../../../redux/utilisateur/utilisateur.slice";

import ContactReferent from "./ContactReferent";
import { motion } from "framer-motion";

const StylePopup = styled(motion.div)`
  position: relative;
  z-index: 990;
  display: block;
  opacity: 0;
  width: 304px;
  padding: 31px 36px;
  background: #3c4450 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 10px;
  pointer-events: none;
`;

const TitlePopup = styled("p")`
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 38px;
`;

const ContactName = styled("p")`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.neutral[100]};
`;

const TextBasic = styled("p")`
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.neutral[100]};
`;

const variants = {
  open: { opacity: 1, x: "-20%", y: 0 },
  closed: { opacity: 0, x: "-9%", y: "8%" },
};

interface Props {
  isOpen: boolean;
}

const ContactPopup = ({ isOpen }: Props) => {
  const medecin = useSelector(selectMedecinInfos);
  const entiteJuridique: any = medecin?.oEntiteJuridique;

  return (
    <StylePopup variants={variants} animate={isOpen ? "open" : "closed"}>
      {medecin ? (
        <>
          <TitlePopup>Vos référents commerciaux</TitlePopup>
          {medecin.tReferents?.map((referent) => (
            <ContactReferent key={`${referent.sPrenom}-${referent.sNom}`} referent={referent} />
          ))}
          <ContactName>{entiteJuridique?.sNom}</ContactName>
          <TextIcon label="Adresse" icon="contactEmail" />
          <TextBasic>
            {entiteJuridique?.sAdresse} {entiteJuridique?.sCodePostal}{" "}
            {entiteJuridique?.sVille}
          </TextBasic>
          <TextIcon label="Email" icon="contactEmail" />
          <TextBasic>{entiteJuridique?.sMail || '-'}</TextBasic>
          <TextIcon label="Téléphone" icon="contactPhone" />
          <TextBasic>{entiteJuridique?.sTelFixe}</TextBasic>
        </>
      ) : (
        <div>Pas de contact</div>
      )}
    </StylePopup>
  );
};

export default ContactPopup;
