import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FormItemWrapper, Label, PrimaryButton } from "@ads/ui";
import { notification } from "antd";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Slices
import { toggleModal } from "../../redux/modal/modal.slice";
import { selectUtilisateur } from "../../redux/utilisateur/utilisateur.slice";
// Design
import BlackModal from "../atoms/modals/BlackModal";
import ErrorFormMsg from "../atoms/form/ErrorFormMsg";
// APi
import { deletePrescriptionFromBasket } from "../../api/prescriptions.api";

const WrapperForm = styled("div")`
  padding: 5.5rem 15rem;
`;

const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 55px 0 0 0;
`;

const schema = yup.object().shape({
  sRaisonSuppression: yup.string(),
});

interface Props {
  iPKPrescription: number;
  getPrescriptionsASignerFromIdentity: Function;
}

export default function FormRemoveSelectedPrescription({
  iPKPrescription,
  getPrescriptionsASignerFromIdentity,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const identity = useSelector(selectUtilisateur);
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(toggleModal());
  };
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      await deletePrescriptionFromBasket(
        identity?.iFKAnnuaire as number,
        iPKPrescription,
        {}
      );
      notification.success({
        description:
          "La suppression de votre renouvellement a bien été prise en compte",
        message: "Succès !",
      });
      dispatch(toggleModal());
      getPrescriptionsASignerFromIdentity(identity);
    } catch (error) {
      notification.error({
        description: `Nous rencontrons une erreur à la suppression de votre renouvellement, veuillez contacter votre administrateur`,
        message: `Echec de l'enregistrement du formulaire`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BlackModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapperForm>
          <FormItemWrapper>
            {errors.sRaisonSuppression &&
              errors.sRaisonSuppression.type === "required" && (
                <ErrorFormMsg>Ce champ est requis</ErrorFormMsg>
              )}
            <Label htmlFor="sRaisonSuppression">
              Remettre cette prescription dans la liste des "Renouvellements en
              attente"?
            </Label>
          </FormItemWrapper>
          <WrapperButtons>
            <PrimaryButton
              label="Annuler"
              as="button"
              size="S"
              buttonStyle="danger"
              type="reset"
              minWidth="157px"
              onClick={handleCancel}
            />
            <PrimaryButton
              as="button"
              label="Valider"
              size="S"
              buttonStyle="primary"
              type="submit"
              minWidth="157px"
              disabled={isLoading}
            />
          </WrapperButtons>
        </WrapperForm>
      </form>
    </BlackModal>
  );
}
