import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Modal, Spin } from "antd";
import { Redirect } from "react-router-dom";

import { PrimaryButton, IconsV3 } from "@ads/ui";

import { getDocumentLink } from "../../api/prescriptions.api";

import { toggleModal } from "../../redux/modal/modal.slice";

import Layout from "../../components/templates/Layout";
import TabsHeader from "../../components/molecules/tabs/TabsHeader";
import TabsHeaderItem from "../../components/atoms/tabs/TabsHeaderItem";
import TabsContent from "../../components/molecules/tabs/TabsContent";

import { FormModele } from "../../components/molecules/FormModele";
import DAP from "./DAP/DAP.elements";
import DEP from "./DEP/DEP.elements";
import {
  OrdonnanceBizone,
  OrdonnancePPC,
  OrdonnanceStandard,
} from "./ordonnances";
import InsertDocument from "../../components/molecules/InsertDocument/InsertDocument";
import ButtonDocument from "../../components/molecules/ButtonDocument";
import { Wrapper } from "../index/index.elements";
import {
  StyledLayout,
  LeftTextWrapper,
  TitlePanel,
  WrapperButton,
  RightTextWrapper,
  WrapperDocument,
  ContentCenter,
  WrapperTabsHeader,
  TabsContentWrapper,
  OverFlowWrapper,
} from "./elements/renouvellement.elements";
import { usePrescription } from "./hook/usePrescription";
import { HeaderNewPrescription } from "../../components/molecules/header/HeaderNewPrescription";
import { MultiRollingPanelStyled } from "../../components/atoms/MultiRollingPanelStyled";

export const Prescription = () => {
  const controler = usePrescription();
  const dispatch = useDispatch();

  return (
    <>
      <Layout
        main={
          <StyledLayout>
            <HeaderNewPrescription
              send={controler.sendPrescriptionToIsadom}
              isLoading={controler.isLoading}
              tabOrdonnance={controler.listeOrdonnance}
              ordonnances={controler.ordonnances}
              prescription={controler.prescription}
              controler={controler}
            />
            <Wrapper className="wrapper">
              <MultiRollingPanelStyled
                panels={[
                  {
                    icon: <IconsV3.Attached />,
                    children: (
                      <div style={{ overflowY: "auto", overflowX: "hidden" }}>
                        <LeftTextWrapper>
                          <TitlePanel
                            label="DOCUMENTS"
                            sColor={{ style: "neutral" }}
                          />
                        </LeftTextWrapper>
                        <div
                          style={{
                            paddingBottom: 65,
                            overflowX: "hidden",
                            height: "68rem",
                          }}
                        >
                          {controler.documents.length > 0 ? (
                            controler.documents.map((doc) => (
                              <ButtonDocument
                                label={doc.sLibelle}
                                key={uuidv4()}
                                onclick={() => {
                                  window
                                    .open(
                                      getDocumentLink(
                                        controler.identity
                                          ?.iFKAnnuaire as number,
                                        doc.iPKPrescription,
                                        doc.iPKTmpPprDocument
                                      ),
                                      "_blank"
                                    )
                                    ?.focus();
                                }}
                              />
                            ))
                          ) : (
                            <p>Aucun document disponible</p>
                          )}
                        </div>
                        {controler.prescription?.bAutorizeJointDoc && (
                          <WrapperButton>
                            <PrimaryButton
                              as="button"
                              label="Ajouter un document"
                              size="S"
                              buttonStyle="neutral"
                              type="reset"
                              minWidth="157px"
                              onClick={() =>
                                controler.setAddDocument(!controler.addDocument)
                              }
                            />
                          </WrapperButton>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
              {controler.prescription?.oJustificatifs?.ordonnance && (
                <MultiRollingPanelStyled
                  side="right"
                  panels={[
                    {
                      icon: <IconsV3.DocumentPen />,
                      children: (
                        <div
                          style={{
                            overflowY: "auto",
                            overflowX: "hidden",
                            height: "68rem",
                          }}
                        >
                          <RightTextWrapper>
                            <TitlePanel
                              label="Modèles de texte"
                              sColor={{ style: "neutral" }}
                            />
                          </RightTextWrapper>
                          <WrapperDocument>
                            {controler.modelesTexte.map((modeleText) => (
                              <InsertDocument
                                key={modeleText.sLibelle}
                                label={modeleText.sTexte}
                                onClick={() => {
                                  if (controler.isBlank) {
                                    controler.setSelectedModele(
                                      modeleText.sTexte
                                    );
                                  } else {
                                    alert(
                                      "Attention ! Le texte saisi doit être vidé avant d'ajouter un nouveau texte"
                                    );
                                  }
                                }}
                                editModele={() => {
                                  dispatch(toggleModal());
                                  controler.setContentModal(
                                    <FormModele
                                      modele={modeleText}
                                      setModeles={() => controler.getModeles()}
                                    />
                                  );
                                }}
                                svgLabel="Insérer"
                                titleLabel={modeleText.sLibelle}
                              />
                            ))}
                          </WrapperDocument>
                        </div>
                      ),
                    },
                  ]}
                />
              )}
              {!controler.prescription ? (
                ""
              ) : (
                <ContentCenter className="contentcentner">
                  <WrapperTabsHeader className="wrappertabsheader">
                    <TabsHeader>
                      {controler.prescription &&
                        controler.prescription.oJustificatifs &&
                        Object.entries(
                          controler.prescription.oJustificatifs
                        ).map((child: Array<any>, index) => {
                          const key = `${child[0]}${
                            child[1].sType ? `-${child[1].sType}` : ""
                          }`;
                          return (
                            <TabsHeaderItem
                              key={key}
                              label={`${child[0]} ${
                                child[1].sType ? child[1].sType : ""
                              }`}
                              picto
                              active={index === 0 ? true : false}
                              dataAttr={key}
                              onClick={(event) =>
                                controler.handleClickTab(event, child)
                              }
                            />
                          );
                        })}
                    </TabsHeader>
                  </WrapperTabsHeader>
                  <TabsContentWrapper>
                    {controler.prescription?.oJustificatifs?.dap && (
                      <TabsContent
                        id="dap"
                        active={controler.selectedMenuItem === "dap"}
                      >
                        <OverFlowWrapper>
                          <DAP
                            bDateSignature={controler.bDateSignature}
                            key="DAP"
                            onChange={(e) => {
                              controler.handleChange("dap", e);
                            }}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                            justificatif={
                              controler.prescription.oJustificatifs.dap
                            }
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}
                    {controler.prescription?.oJustificatifs?.dep && (
                      <TabsContent
                        id="dep"
                        active={controler.selectedMenuItem === "dep"}
                      >
                        <OverFlowWrapper>
                          <DEP
                            bDateSignature={controler.bDateSignature}
                            key="DEP"
                            onChange={(e) => {
                              controler.handleChange("dep", e);
                            }}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}

                    {controler.prescription?.oJustificatifs?.ordonnance
                      ?.sType === "standard" && (
                      <TabsContent
                        id="ordonnance-standard"
                        active={
                          controler.selectedMenuItem === "ordonnance-standard"
                        }
                      >
                        <OverFlowWrapper>
                          <OrdonnanceStandard
                            bDateSignature={controler.bDateSignature}
                            key="Ordonnance Standard"
                            onChange={(e) => {
                              controler.handleChange("ordonnance", e);
                            }}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                            ordonnance={
                              controler.prescription.oJustificatifs.ordonnance
                            }
                            resetSelectedModele={() => {
                              controler.setSelectedModele(undefined);
                            }}
                            selectedModele={controler.selectedModele}
                            isEditable
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}

                    {controler.prescription?.oJustificatifs?.ordonnance
                      ?.sType === "bizone" && (
                      <TabsContent
                        id="ordonnance-bizone"
                        active={
                          controler.selectedMenuItem === "ordonnance-bizone"
                        }
                      >
                        <OverFlowWrapper>
                          <OrdonnanceBizone
                            bDateSignature={controler.bDateSignature}
                            key="Ordonnance Bizone"
                            onChange={(e) => {
                              controler.handleChange("ordonnance", e);
                            }}
                            ordonnance={
                              controler.prescription.oJustificatifs.ordonnance
                            }
                            resetSelectedModele={() => {
                              controler.setSelectedModele(undefined);
                            }}
                            selectedModele={controler.selectedModele}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                            isEditable
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}
                    {controler.prescription?.oJustificatifs?.ordonnance
                      ?.sType === "ppc" && (
                      <TabsContent
                        id="ordonnance-ppc"
                        active={controler.selectedMenuItem === "ordonnance-ppc"}
                      >
                        <OverFlowWrapper>
                          <OrdonnancePPC
                            bDateSignature={controler.bDateSignature}
                            key="Ordonnance PPC"
                            onChange={(e) => {
                              controler.handleChange("ordonnance", e);
                            }}
                            ordonnance={
                              controler.prescription.oJustificatifs.ordonnance
                            }
                            resetSelectedModele={() => {
                              controler.setSelectedModele(undefined);
                            }}
                            selectedModele={controler.selectedModele}
                            prescription={controler.prescription}
                            setPrescription={controler.setPrescription}
                          />
                        </OverFlowWrapper>
                      </TabsContent>
                    )}
                  </TabsContentWrapper>
                </ContentCenter>
              )}
            </Wrapper>
            <Modal
              style={{ textAlign: "center" }}
              closable={false}
              title="Signature électronique"
              centered
              width={450}
              visible={controler.isLoading}
              footer={[]}
            >
              <Spin tip="Préparation des documents à signer. Merci de patienter." />
            </Modal>
          </StyledLayout>
        }
      />{" "}
      {controler.bRedirect && <Redirect to="/?normal=true" />}
    </>
  );
};
