import axios from 'axios';

import type {
  default as LieuExercice, NewLieuExercice, UpdateLieuExercice
} from '../interfaces/lieuExercice.interface';
import config from '../config';

export async function addAdresseMedecins(iPKAnnuaire: number, newLieuExercice: NewLieuExercice): Promise<LieuExercice> {
  const { data } = await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/lieuxExercice/`,
    newLieuExercice,
    { withCredentials: true }
  );
  return data as LieuExercice;
}

export async function deleteAdresseMedecins(iPKAnnuaire: number, iPKAnnAdresse: number) {
  await axios.delete(
    `${config.serverURL}/medecins/${iPKAnnuaire}/lieuxExercice/${iPKAnnAdresse}`,
    { withCredentials: true }
  );
}

export async function updateAdresseMedecins(iPKAnnuaire: number, iPKAnnAdresse: number, lieuExerciceUpdates: UpdateLieuExercice): Promise<LieuExercice> {
  const { data } = await axios.patch(
    `${config.serverURL}/medecins/${iPKAnnuaire}/lieuxExercice/${iPKAnnAdresse}`,
    lieuExerciceUpdates,
    { withCredentials: true }
  );
  return data as LieuExercice;
}
