import styled from "styled-components";

const StyleDatePicker = styled.div`
  .ant-picker {
    height: 50px;
    line-height: 1;
    border: none;
    width: 100%;
    background-color: ${(props) => props.theme.colors.neutral[170]};
    border: 1px solid ${(props) => props.theme.colors.neutral[170]};
    border-radius: 13px;
  }
  .ant-picker-input > input {
    color: ${(props) => props.theme.colors.neutral[100]};
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border: 1px solid ${(props) => props.theme.colors.neutral[100]};
  }

  .ant-picker-suffix {
    color: ${(props) => props.theme.colors.neutral[100]};
  }
`;

export default StyleDatePicker;
