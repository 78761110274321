import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';


import { selectConnecte } from "../../../redux/utilisateur/utilisateur.slice";

export default function ProtectedRoute(props: RouteProps) {
  if (useSelector(selectConnecte)) return <Route {...props} />;

  return <Route {...props} component={() => <Redirect to={{ pathname: '/utilisateur/connexion' }} />} />;
}
