import { notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getModelesTexte } from "../../../api/modelesTexte.api";
import ModeleTexte from "../../../interfaces/modeleTexte.interface";
import { selectUtilisateur } from "../../../redux/utilisateur/utilisateur.slice";
import ModeleHeader from "../../molecules/modeles/ModeleHeader";
import ModeleItemsWrapper from "../../molecules/modeles/ModeleItemsWrapper";

const ModelesTabContent = () => {
  const user = useSelector(selectUtilisateur);
  const [modeles, setModeles] = useState<ModeleTexte[]>([]);

  const getModeles = useCallback(async () => {
    try {
      if (!user) return;
      setModeles(await getModelesTexte(user.iFKAnnuaire!)); // FIXME: user.iFKAnnuaire!
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  }, [user]);

  useEffect(() => {
    getModeles();
  }, [getModeles]);
  return (
    <>
      <ModeleHeader
        setModeles={setModeles}
        title="Modèles enregistrés"
        icon="modeles"
      />
      <ModeleItemsWrapper modeles={modeles} setModeles={setModeles} />
    </>
  );
};

export default ModelesTabContent;
