import { notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModelesTexte } from "../../../api/modelesTexte.api";
import { sendNewPrescription } from "../../../api/prescriptions.api";
import { DocumentDetails } from "../../../interfaces/document.interface";
import ModeleTexte from "../../../interfaces/modeleTexte.interface";
import { IPrescriptionRenouvellement } from "../../../interfaces/prescription.interface";
import { selectDateSignatureEditable } from "../../../redux/config/config.slice";
import {
  selectUtilisateur,
  selectMedecinInfos,
} from "../../../redux/utilisateur/utilisateur.slice";
import { displayTabContent } from "../elements/renouvellement.elements";
import {
  IPrescriptionService,
  JustificatifType,
} from "./interfaces/IPrescriptionService";
import IDAP from "./../../../interfaces/dap.interface";
import IDEP from "./../../../interfaces/dep.interface";
import { newPrescription } from "../../../utils/dataMockup";
import { IOrdonnanceRenouvellement } from "../../../interfaces/ordonnance.interface";
import { selectContentModal } from "../../../redux/modal/modal.slice";
import { toggleModal } from "../../../redux/modal/modal.slice";

export function usePrescription(): IPrescriptionService {
  const [prescription, setPrescription] =
    useState<IPrescriptionRenouvellement | undefined>(newPrescription);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [documents, setDocuments] = useState<DocumentDetails[]>([]);
  // State : boolean pour savoir si on redirige ou non après la sauvegarde
  const [bRedirect, setbRedirect] = useState(false);
  // State : Bolean d'info de modification ou non du formulaire
  const [bEdited, setbEdited] = useState(false);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isLoading, setIsLoading] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<string | undefined>("");
  const [selectedModele, setSelectedModele] = useState<string | undefined>();
  const [modelesTexte, setModelesTexte] = useState<ModeleTexte[]>([]);
  const [ordonnances, setOrdonnances] = useState<string[]>([]);
  const dispatch = useDispatch();

  const [listeOrdonnance, setListOrdonnance] = useState<string[]>([]);

  // State : Gestion du contenu de texte
  const [isBlank, setIsBlank] = useState<boolean>(
    prescription?.oJustificatifs?.ordonnance?.sContenu?.replaceAll(" ", "") ===
      "" ||
      prescription?.oJustificatifs?.ordonnance?.sContenu === undefined ||
      prescription?.oJustificatifs?.ordonnance?.sContenu === null
  );

  // Gestion Modal
  const [leftPanel, setLeftPanel] = useState(false);
  const [rightPanel, setRightPanel] = useState(false);

  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);
  const panelLeftHandler = () => {
    setLeftPanel(() => !leftPanel);
  };
  const panelRightHandler = () => {
    setRightPanel(() => !rightPanel);
  };
  const identity = useSelector(selectUtilisateur);
  const medecin = useSelector(selectMedecinInfos);
  const bDateSignature = useSelector(selectDateSignatureEditable);

  const handleClickTab = (e: any, child: Array<any>) => {
    displayTabContent(e);
    let newListOrdo = listeOrdonnance;

    const key = `${child[0]}${child[1].sType ? `-${child[1].sType}` : ""}`;
    const isInArray = newListOrdo.includes(key);

    setSelectedMenuItem(key);
    if (!isInArray) {
      newListOrdo.push(key);
      setListOrdonnance(newListOrdo);
    }
  };

  const setOrdoByTypePresta = (typePresta: string) => {
    let prescriptionVierge = prescription;
    if (prescriptionVierge && prescriptionVierge.oJustificatifs) {
      if (typePresta.includes("dap"))
        prescriptionVierge.oJustificatifs.dap = {} as IDAP;
      if (typePresta.includes("dep"))
        prescriptionVierge.oJustificatifs.dep = {} as IDEP;
      if (typePresta.includes("standard")) {
        prescriptionVierge.oJustificatifs.ordonnance =
          {} as IOrdonnanceRenouvellement;
        prescriptionVierge.oJustificatifs.ordonnance.sType = "standard";
      }
      if (typePresta.includes("bizone")) {
        prescriptionVierge.oJustificatifs.ordonnance =
          {} as IOrdonnanceRenouvellement;
        prescriptionVierge.oJustificatifs.ordonnance.sType = "bizone";
      }

      if (!typePresta.includes("dep"))
        delete prescriptionVierge.oJustificatifs.dep;
      if (!typePresta.includes("dap"))
        delete prescriptionVierge.oJustificatifs.dap;
      if (!typePresta.includes("standard") && !typePresta.includes("bizone"))
        delete prescriptionVierge.oJustificatifs.ordonnance;
    }
    setPrescription(prescriptionVierge);
  };

  const handleChange = (
    justificatifType: JustificatifType,
    { target }: any
  ) => {
    // FIXME; any
    const { checked, name, type, value } = target;

    let valueToModify = value;
    if (type === "checkbox" && Array.from(name)[0] === "s") {
      if (checked) {
        valueToModify = "X";
      } else {
        valueToModify = " ";
      }
    } else if (type === "checkbox" && Array.from(name)[0] === "b") {
      valueToModify = checked;
    }

    const updatedJustificatif = {
      ...prescription!.oJustificatifs![justificatifType],
      [name]: valueToModify,
    };

    setbEdited(true);
    setPrescription({
      ...prescription,
      oJustificatifs: {
        ...prescription!.oJustificatifs,
        [justificatifType]: updatedJustificatif,
      },
      bUpdated: true,
    });
  };

  const sendPrescriptionToIsadom = async () => {
    if (!prescription) return;
    dispatch(toggleModal());
    setbEdited(false);
    try {
      await sendNewPrescription(identity?.iFKAnnuaire as number, prescription);
      notification.success({
        description: "La prescription a été envoyé à votre structure !",
        message: "Succès !",
      });
      setbRedirect(true);
    } catch (error) {
      notification.error({
        description: `Nous rencontrons une erreur à l'envoi des données du formulaire, veuillez contacter votre administrateur`,
        message: `Echec de l'envoi des données du formulaire`,
      });
    } finally {
      dispatch(toggleModal());
    }
  };

  const handleLengthTab = useCallback(() => {
    if (prescription && prescription.oJustificatifs) {
      const tabOrdonnancePatient: string[] = [];
      Object.entries(prescription.oJustificatifs).forEach((child) => {
        tabOrdonnancePatient.push(child[0] as string);
      });
      setOrdonnances(tabOrdonnancePatient);
    }
  }, [prescription, setOrdonnances]);

  const getModeles = useCallback(async () => {
    try {
      if (!medecin) return;
      setModelesTexte(await getModelesTexte(medecin.iFKAnnuaire));
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  }, [medecin, setModelesTexte]);

  useEffect(() => {
    if (!bRedirect) {
      getModeles();
    }
  }, [bRedirect, getModeles]);

  useEffect(() => {
    documents.length > 0 && setLeftPanel(true);
  }, [documents]);

  useEffect(() => {
    setIsBlank(
      prescription?.oJustificatifs?.ordonnance?.sContenu?.replaceAll(
        " ",
        ""
      ) === "" ||
        prescription?.oJustificatifs?.ordonnance?.sContenu === undefined ||
        prescription?.oJustificatifs?.ordonnance?.sContenu === null
    );
  }, [prescription?.oJustificatifs?.ordonnance?.sContenu]);

  useEffect(() => {
    handleLengthTab();
  }, [prescription, handleLengthTab]);

  const service: IPrescriptionService = {
    bEdited: bEdited,
    setbEdited: setbEdited,
    bRedirect: bRedirect,
    setbRedirect: setbRedirect,
    isLoading: isLoading,
    prescription: prescription,
    setPrescription: setPrescription,
    listeOrdonnance: listeOrdonnance,
    ordonnances: ordonnances,
    handleClickTab: handleClickTab,
    setOrdoByTypePresta: setOrdoByTypePresta,
    handleChange: handleChange,
    sendPrescriptionToIsadom: sendPrescriptionToIsadom,
    handleLengthTab: handleLengthTab,
    getModeles: getModeles,
    identity: identity,
    setAddDocument: setAddDocument,
    documents: documents,
    setDocuments: setDocuments,
    addDocument: addDocument,
    modelesTexte: modelesTexte,
    setSelectedModele: setSelectedModele,
    selectedMenuItem: selectedMenuItem,
    setSelectedMenuItem: setSelectedMenuItem,
    isBlank: isBlank,
    medecin: medecin,
    bDateSignature: bDateSignature,
    selectedModele: selectedModele,
    panelLeftHandler: panelLeftHandler,
    setContentModal: setContentModal,
    leftPanel: leftPanel,
    setLeftPanel: setLeftPanel,
    rightPanel: rightPanel,
    setRightPanel: setRightPanel,
    panelRightHandler: panelRightHandler,
  };
  return service;
}
