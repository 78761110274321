import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";

import {
  BarcodeDiv as BarcodesDiv,
  CerfaInfo,
  IdentificationDiv as IdentificationPatient,
  IdentificationDiv as IdentificationPrescripteur,
  IdentificationDivTitle,
} from "./ordonnance.style";
import type { IOrdonnanceRenouvellement } from "../../../interfaces/ordonnance.interface";
import { displayFormatImmatriculation } from "../../../utils/display";
import Barcode from "./barcode.elements";
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import { ChangeEvent } from "react";
import { IPrescriptionRenouvellement } from "../../../interfaces/prescription.interface";
import { StyledInputNeutral } from "../../../components/atoms/input/InputStyled";

const AmelieNumberDiv = styled.div``;

const BirthDate = styled(FormItem)`
  margin-top: 20px;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
`;

const RppsNumberDiv = styled.div``;

type HeaderStandardProps = Pick<
  IOrdonnanceRenouvellement,
  | "dDateNaissancePatient"
  | "sAdeliPrescripteur"
  | "sAdressePrescripteur"
  | "sCiviliteNomPrenomPatient"
  | "sCiviliteNomPrenomPrescripteur"
  | "sCodePatient"
  | "sNoSecuriteSociale"
  | "sRPPSPrescripteur"
>;

export interface HeaderEditableProps {
  ordonnance: HeaderStandardProps;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}
export function HeaderStandard({ ...ordonnance }: HeaderStandardProps) {
  return (
    <>
      {ordonnance.sCodePatient}

      <Header>
        <IdentificationPrescripteur>
          <IdentificationDivTitle>
            Identification du prescripteur
          </IdentificationDivTitle>

          {ordonnance.sCiviliteNomPrenomPrescripteur}

          <p>{ordonnance.sAdressePrescripteur}</p>

          <BarcodesDiv>
            {ordonnance.sRPPSPrescripteur && (
              <RppsNumberDiv>
                <span style={{ marginLeft: 10 }}>N° RPPS</span>
                <Barcode value={ordonnance.sRPPSPrescripteur} />
              </RppsNumberDiv>
            )}

            {ordonnance.sAdeliPrescripteur && (
              <AmelieNumberDiv>
                <span style={{ marginLeft: 10 }}>N° AM</span>
                <Barcode value={ordonnance.sAdeliPrescripteur} />
              </AmelieNumberDiv>
            )}
          </BarcodesDiv>
        </IdentificationPrescripteur>

        <CerfaInfo />

        <IdentificationPatient>
          {ordonnance.sCiviliteNomPrenomPatient}

          <BirthDate label="Né(e) le">
            {ordonnance.dDateNaissancePatient &&
              new Date(ordonnance.dDateNaissancePatient).toLocaleDateString()}
          </BirthDate>

          <FormItem label="N° S.S">
            {displayFormatImmatriculation(ordonnance.sNoSecuriteSociale)}
          </FormItem>
        </IdentificationPatient>
      </Header>
    </>
  );
}

export function HeaderEditable({
  ordonnance,
  onChange,
  prescription,
  setPrescription,
}: HeaderEditableProps) {
  return (
    <>
      <Header>
        <IdentificationPrescripteur>
          <IdentificationDivTitle>
            Identification du prescripteur
          </IdentificationDivTitle>

          <StyledInputNeutral
            name="sCiviliteNomPrenomPrescripteur"
            id="sCiviliteNomPrenomPrescripteur"
            type="text"
            placeholder="Identité du prescripteur"
            value={ordonnance.sCiviliteNomPrenomPrescripteur}
            onChange={onChange}
          />

          <p>
            <StyledInputNeutral
              name="sAdressePrescripteur"
              id="sAdressePrescripteur"
              type="text"
              placeholder="Adresse du prescripteur"
              value={ordonnance.sAdressePrescripteur}
              onChange={onChange}
            />
          </p>

          <BarcodesDiv>
            {ordonnance.sRPPSPrescripteur && (
              <RppsNumberDiv>
                <span style={{ marginLeft: 10 }}>N° RPPS</span>
                <Barcode value={ordonnance.sRPPSPrescripteur} />
              </RppsNumberDiv>
            )}

            {ordonnance.sAdeliPrescripteur && (
              <AmelieNumberDiv>
                <span style={{ marginLeft: 10 }}>N° AM</span>
                <Barcode value={ordonnance.sAdeliPrescripteur} />
              </AmelieNumberDiv>
            )}
          </BarcodesDiv>
        </IdentificationPrescripteur>

        <CerfaInfo />

        <IdentificationPatient>
          <StyledInputNeutral
            name="sCiviliteNomPrenomPatient"
            id="sCiviliteNomPrenomPatient"
            type="text"
            placeholder="Civilité, Nom, Prénom Patient"
            value={ordonnance.sCiviliteNomPrenomPatient}
            onChange={onChange}
          />

          <BirthDate label="Né(e) le">
            <DatePicker
              value={
                ordonnance.dDateNaissancePatient
                  ? moment(ordonnance.dDateNaissancePatient)
                  : moment(new Date())
              }
              placeholder="jj/mm/aaaa"
              name="dDateNaissancePatient"
              format="DD/MM/YYYY"
              locale={locale}
              onChange={(momentDate) => {
                if (
                  momentDate &&
                  prescription &&
                  prescription.oJustificatifs &&
                  prescription.oJustificatifs.ordonnance
                ) {
                  setPrescription({
                    ...prescription,
                    oJustificatifs: {
                      ...prescription.oJustificatifs,
                      ordonnance: {
                        ...prescription.oJustificatifs.ordonnance,
                        dDateNaissancePatient: new Date(
                          momentDate.format("YYYY-MM-DD")
                        ),
                      },
                    },
                    bUpdated: true,
                  });
                }
              }}
            />
            {ordonnance.dDateNaissancePatient &&
              new Date(ordonnance.dDateNaissancePatient).toLocaleDateString()}
          </BirthDate>

          <FormItem label="N° S.S">
            <StyledInputNeutral
              name="sNoSecuriteSociale"
              id="sNoSecuriteSociale"
              type={"number"}
              maxLength={15}
              placeholder="Numéro de Sécurité Sociale"
              value={ordonnance.sNoSecuriteSociale}
              onChange={onChange}
            />
          </FormItem>
        </IdentificationPatient>
      </Header>
    </>
  );
}
