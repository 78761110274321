import axios from "axios";
import config from "../config";

import type {
  default as Medecin,
  DataPersMed,
} from "../interfaces/medecin.interface";

export async function getMedecin(iPKAnnuaire: number): Promise<Medecin> {
  const { data } = await axios.get(
    `${config.serverURL}/medecins/${iPKAnnuaire}`,
    { withCredentials: true }
  );
  return data;
}

export async function updateInfosMedecins(
  iPKAnnuaire: number,
  infos: DataPersMed
) {
  await axios.patch(`${config.serverURL}/medecins/${iPKAnnuaire}`, infos, {
    withCredentials: true,
  });
}
