import { forwardRef, useEffect, useRef, useState } from "react";
import { Result } from "antd";
import type { ElementRef } from "react";
import Layout from "../templates/Layout";
import type { ReactSignatureCanvasProps } from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import { PrimaryButton } from "@ads/ui";

import { upsertSignatureOtp } from "../../api/signature.api";

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  padding-top: 135px;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.neutral[130]};
`;

export default function Signature() {
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState<"error" | "success">();
  const [otp, setOtp] = useState("");
  const [userIPKAnnuaire, setUserIPKAnnuaire] = useState<number>();
  const canvasEl = useRef<ElementRef<typeof SignatureInput>>(null);

  const clear = () => canvasEl.current?.clear();

  const saveSignature = async () => {
    const dataUrl = canvasEl.current?.getTrimmedCanvas().toDataURL();

    if (!dataUrl) return;

    setIsSaving(true);
    try {
      await upsertSignatureOtp(userIPKAnnuaire!, otp, dataUrl);
      setSaveStatus("success");
    } catch (error) {
      setSaveStatus("error");
    }
    setIsSaving(false);
  };

  useEffect(() => {
    const [otp, userId] = window.location.href.split("/").reverse();

    setOtp(otp);
    setUserIPKAnnuaire(Number(userId));
  }, []);

  return saveStatus ? (
    <Result
      status={saveStatus}
      {...(saveStatus === "error"
        ? {
            subTitle: `Le QR code n'est peut être plus valide ; veuillez le flasher de nouveau afin de réessayer`,
            title: "Une erreur est survenue",
          }
        : {
            subTitle: "Vous pouvez fermer cette fenêtre",
            title: "Signature enregistrée",
          })}
    />
  ) : (
    <Layout
      main={
        <Main>
          <SignatureInput
            backgroundColor="white"
            canvasProps={{
              height: (window.innerHeight * 0.95 * 26.32) / 100, // UGLY but respect 100 / 380 ratio
              width: window.innerWidth * 0.95, // UGLY but otherwise too wide
            }}
            ref={canvasEl}
          />

          <ActionsWrapper>
            <PrimaryButton
              as="button"
              size="M"
              type="button"
              buttonStyle="danger"
              onClick={clear}
              label="Effacer"
            />

            <PrimaryButton
              as="button"
              size="M"
              type="button"
              buttonStyle="primary"
              onClick={saveSignature}
              minWidth="172px"
              label={isSaving ? "En Cours" : "Sauvegarder"}
            />
          </ActionsWrapper>
        </Main>
      }
    />
  );
}

const SignatureInput = forwardRef<SignatureCanvas, ReactSignatureCanvasProps>(
  (props, ref) => <SignatureCanvas {...props} ref={ref} />
);
