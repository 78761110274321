import React, { FunctionComponent } from "react";
import styled from "styled-components";

const StyledTabsContent = styled("div")`
  display: none;
  padding: 24px 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #0000000d;
  &.active {
    display: inherit;
  }
  & input[type="date"] {
    width: auto;
    max-width: 100%;
  }
  font-size: 13px;
  border-top-right-radius: 10px;
  z-index: 1;
  height: 100%;
`;

interface Props {
  id: string;
  active?: boolean;
  children: React.ReactNode;
}

const TabsContent: FunctionComponent<Props> = ({
  children,
  active,
  id,
}: Props) => (
  <StyledTabsContent
    id={id}
    className={active ? "active tab-content" : "tab-content"}
  >
    {children}
  </StyledTabsContent>
);

export default TabsContent;
