import type { AppConfig } from "./interfaces/config.interface";

const config: AppConfig = {
  version: process.env.REACT_APP_VERSION || "",
  name: process.env.REACT_APP_NAME || "",

  identityServerURL: process.env.REACT_APP_IDENTITY_SERVER_URL || "",

  theme: process.env.REACT_APP_THEME || "ads",

  serverURL: process.env.REACT_APP_SERVER_URL || "",

  jwtPUBLIC: process.env.REACT_APP_JWT_PUBLIC || "",

  localStorageIdentityKey: process.env.REACT_APP_IDENTITY_KEY || "token",

  signatureClassiqueMultiple:
    process.env.REACT_APP_SIGNATURE_CLASSIQUE_MULTIPLE === "true" || false,

  oodriveSignatureActivated:
    process.env.REACT_APP_OODRIVE_SIGNATURE === "true" || false,

  oodriveDelaiPremierRefreshFinalisation:
    parseInt(process.env.REACT_APP_PREMIER_REFRESH_FINALISATION as string) ||
    30000,

  oodriveDelaiPremierRefreshAnnulation:
    parseInt(process.env.REACT_APP_PREMIER_REFRESH_ANNULATION as string) ||
    10000,

  oodriveDelaiRefreshSignatureEchoue:
    parseInt(process.env.REACT_APP_DELAI_REFRESH_ECHOUE as string) || 900000,
};

export default config;
