import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Navigation } from "../../molecules/navigation/navigation.element";

const Head = styled.header`
  position: fixed;
  top: 0;
  z-index: 50;
  padding: 24px 0;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  &.filter-is-open {
    filter: blur(5px);
    pointer-events: none;
  }
  background: ${(props) => props.theme.colors.gradient[1]};
`;

const WrapperHeader = styled("div")`
  width: 1530px;
  max-width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1530px) {
    max-width: 95%;
  }
`;

interface HeaderProps {
  header?: JSX.Element | null;
  navigation?: boolean;
  isFilterOpen?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({
  header,
  isFilterOpen = false,
  navigation = true,
}) => {
  if (!navigation) return null;
  return (
    <Head id="header" className={isFilterOpen ? "filter-is-open" : ""}>
      <WrapperHeader>
        <Navigation />
        {header && header}
      </WrapperHeader>
    </Head>
  );
};

export default Header;
