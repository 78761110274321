import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleModal } from "../../../redux/modal/modal.slice";

const StyledModal = styled("div")`
  z-index: 900;
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CloseModal = styled.div`
  z-index: 890;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface Props {
  isModalOpen?: boolean;
  modalContent: JSX.Element | undefined;
}

const Modal: FunctionComponent<Props> = ({
  modalContent,
  isModalOpen,
}: Props) => {
  const dispatch = useDispatch();
  const handlleClick = () => {
    dispatch(toggleModal());
  };
  return (
    <>
      <StyledModal className={isModalOpen ? "modal-is-open" : "modal-is-open"}>
        {modalContent}
      </StyledModal>
      <CloseModal className="close-modal" onClick={() => handlleClick()} />
    </>
  );
};

export default Modal;
