import React, { useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const Wrapper = styled("div")`
  display: block;
  padding: 25px;

  .apexcharts-text tspan {
    color: #fff;
  }

  .apexcharts-tooltip {
    align-items: flex-start;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    padding: 8px 14px;
    border: none;
    background-color: ${(props) => props.theme.colors.neutral[180]} !important;
    background-position: center;
  }

  .apexcharts-tooltip-series-group.apexcharts-active,
  .apexcharts-tooltip-series-group:last-child {
    background: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.primary[100]};
  }

  .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.neutral[100]};
  }

  .bFpNeG .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    margin: 0;
    padding: 0;
  }

  .apexcharts-tooltip-series-group.apexcharts-active,
  .apexcharts-tooltip-series-group:last-child {
    padding: 0;
  }

  .apexcharts-tooltip-title,
  .apexcharts-tooltip-y-group {
    padding: 0;
  }

  .apexcharts-tooltip-text-value,
  .apexcharts-tooltip-text-z-value {
    margin-left: 0;
  }
`;

interface PropsBarChart {
  datas: any[];
}

const BarChart = ({ datas }: PropsBarChart) => {
  const series = datas.map((d) => d.iNBPat);
  const labels = datas.map((d) => d.sLstPresta);

  const defaultStateChart = {
    series: [
      {
        name: "",
        data: series,
      },
    ],
    options: {
      labels: labels,
      dataLabels: {
        enabled: false,
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: false,
        followCursor: true,
        intersect: true,
        inverseOrder: false,
        fillSeriesColor: true,
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        marker: {
          show: true,
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },
      fill: {
        colors: ["#86D654"],
        opacity: 1,
      },
      xaxis: {
        type: "category" as const,
        categories: [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: "#FFF",
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        type: "category" as const,
        categories: [],
        labels: {
          show: true,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: "#FFF",
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
    },
  };

  const [stateChart] = useState(defaultStateChart);
  const options: ApexOptions = stateChart.options;

  return (
    <Wrapper>
      <Chart
        options={options}
        series={stateChart.series}
        type="bar"
        height={250}
      />
    </Wrapper>
  );
};

export default BarChart;
