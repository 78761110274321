import { Input } from "antd";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

import { StyledCheckboxGroup } from "./input.elements";

export const DEPStyledFormItem = styled(FormItem)`
  align-items: baseline;

  .ant-form-item-label {
    flex-grow: unset;
  }

  .ant-form-item-label > label {
    font-size: 12px;
    height: 15px;
    width: 150px;
  }

  .ant-form-item-control-input {
    min-height: unset;
  }
`;

export const DEPStyledFormItemTextArea = styled(DEPStyledFormItem)`
  margin-bottom: 16px;
  margin-top: 5px;

  .ant-form-item-label > label {
    width: unset;
  }
`;

export const DEPStyledFormItemWithoutWidth = styled(DEPStyledFormItem)`
  .ant-form-item-label > label {
    font-size: 12px;
    height: 15px;
    width: unset;
  }
`;

export const DEPStyledFormItemWithoutWidthC = styled(
  DEPStyledFormItemWithoutWidth
)`
  .ant-form-item-label {
    flex-grow: unset;
  }
`;

export const DEPStyledFormItemWithoutHeight = styled(
  DEPStyledFormItemWithoutWidth
)`
  height: unset;

  .ant-form-item-label > label {
    font-size: 12px;
    height: 15px;
    width: unset;
  }
`;

export const DefaultInput = styled(Input)`
  font-size: 12px;
  padding: 0;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const DefaultBigInput = styled(TextArea)`
  font-size: 12px;
  padding: 0;
  height: 100px;
`;
export const CheckboxComplete = styled.div`
  align-items: center;
  display: flex;

  .ant-input {
    width: 100px;
  }
`;

export const CheckboxCompleteDate = styled.div`
  flex-grow: 1;
  padding-left: 15px;
`;

export const CheckboxCompleteInput = styled(DefaultInput)`
  width: 345px !important;
`;

export const CheckboxCompleteInputDate = styled(DefaultInput)`
  width: 150px !important;
`;

export const CheckboxCompleteLabel = styled.div`
  padding-left: 8px;
  padding-right: 15px;
`;

export const CheckboxGroup = styled(StyledCheckboxGroup)`
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`;

export const CheckboxGroupRow = styled(StyledCheckboxGroup)`
  display: grid;
  grid-template-columns: 0.5fr 1fr;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`;

export const CheckboxGroupRowAlign = styled(StyledCheckboxGroup)`
  display: flex;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }

  .ant-checkbox-wrapper {
    display: inline-flex;
    padding-top: 10px;
  }
`;

export const SmallInput = styled(DefaultInput)`
  height: 20px !important;
  width: 60px !important;
`;

export const StyledSpan = styled.span`
  padding-right: 5px;
`;
