import FormItem from "antd/lib/form/FormItem";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

import {
  BarcodeDiv,
  CerfaInfo,
  IdentificationDiv as IdentificationPrescripteur,
  IdentificationDiv as IdentificationStructure,
  IdentificationDivTitle,
  mainColor,
} from "./ordonnance.style";
import type { IOrdonnanceBizone } from "../../../interfaces/ordonnance.interface";
import { displayFormatImmatriculation } from "../../../utils/display";
import Barcode from "./barcode.elements";
import { StyledInputNeutral } from "../../../components/atoms/input/InputStyled";
import { IPrescriptionRenouvellement } from "../../../interfaces/prescription.interface";
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";

const BarcodeDivTitle = styled.div`
  color: ${mainColor};
  margin-left: 10px;
`;

const Birthdate = styled(FormItem)`
  margin: auto;
  margin-top: 5px;
  width: max-content;

  .ant-form-item-label > label {
    color: ${mainColor};
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const H1 = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IdentificationPatient = styled.div`
  border: 2px solid ${mainColor};
  grid-column: span 2;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
`;

const MainTitle = styled.div`
  align-items: center;
  display: flex;
`;

const PatientId = styled.span`
  position: absolute;
  right: 0;
  top: 0;
`;

const PEC = styled.div`
  text-align: right;
`;

const Precision = styled.div`
  color: ${mainColor};
  font-size: 10px;
  font-style: italic;
  margin-bottom: 10px;
  text-align: center;
`;

const Section = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

const Title = styled.div`
  color: ${mainColor};
  flex-grow: 1;
  text-align: center;
`;

interface FooterProps {
  dDateNaissancePatient?: IOrdonnanceBizone["dDateNaissancePatient"];
  sAdeliPrescripteur?: IOrdonnanceBizone["sAdeliPrescripteur"];
  sAdressePrescripteur?: IOrdonnanceBizone["sAdressePrescripteur"];
  sCiviliteNomPrenomPatient?: IOrdonnanceBizone["sCiviliteNomPrenomPatient"];
  sCiviliteNomPrenomPrescripteur?: IOrdonnanceBizone["sCiviliteNomPrenomPrescripteur"];
  sCodePatient?: IOrdonnanceBizone["sCodePatient"];
  sNoSecuriteSociale?: IOrdonnanceBizone["sNoSecuriteSociale"];
  sRPPSPrescripteur?: IOrdonnanceBizone["sRPPSPrescripteur"];
}
interface HeaderEditableProps {
  ordonnance: IOrdonnanceBizone;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}

export function HeaderBizone({ ...ordonnance }: FooterProps) {
  return (
    <>
      <PEC>PEC : {ordonnance.sCodePatient}</PEC>

      <Header>
        <MainTitle>
          <CerfaInfo />

          <Title>
            <H1>Ordonnance bizone</H1>
            <p>
              Articles L. 322-3, 3° et 4°, L 324-1 et R. 161-45 du code de la
              sécurité sociale
            </p>
          </Title>
        </MainTitle>

        <Section>
          <IdentificationPrescripteur>
            <IdentificationDivTitle>
              Identification du prescripteur
            </IdentificationDivTitle>

            <Precision>(nom, prénom et identifiant)</Precision>

            <Center>{ordonnance.sCiviliteNomPrenomPrescripteur}</Center>

            {ordonnance.sRPPSPrescripteur && (
              <BarcodeDiv>
                <div>
                  <BarcodeDivTitle>N° RPPS</BarcodeDivTitle>
                  <Barcode value={ordonnance.sRPPSPrescripteur} />
                </div>
              </BarcodeDiv>
            )}
          </IdentificationPrescripteur>

          <IdentificationStructure>
            <IdentificationDivTitle>
              Identification de la structure
            </IdentificationDivTitle>

            <Precision>
              (raison sociale du cabinet, de l'établissement et n°AM, FINESS ou
              SIRET)
            </Precision>

            <BarcodeDiv>
              <p>{ordonnance.sAdressePrescripteur}</p>

              {ordonnance.sAdeliPrescripteur && (
                <div>
                  <BarcodeDivTitle>N° FINESS</BarcodeDivTitle>
                  <Barcode value={ordonnance.sAdeliPrescripteur} />
                </div>
              )}
            </BarcodeDiv>
          </IdentificationStructure>

          <IdentificationPatient>
            <IdentificationDivTitle>
              Identification du patient
              <PatientId>{ordonnance.sCodePatient}</PatientId>
            </IdentificationDivTitle>

            <Precision>
              (nom de famille (de naissance), suivi du nom d'usage (facultatif
              et si il y a lieu)) (à compléter par le prescripteur)
            </Precision>

            {ordonnance.sCiviliteNomPrenomPatient}
            {ordonnance.dDateNaissancePatient && (
              <Birthdate label="Né(e) le">
                {new Date(
                  ordonnance.dDateNaissancePatient
                ).toLocaleDateString()}
              </Birthdate>
            )}

            <Precision>
              n° d'immatriculation (à compléter par l'assuré(e))
            </Precision>

            {displayFormatImmatriculation(ordonnance.sNoSecuriteSociale)}
          </IdentificationPatient>
        </Section>
      </Header>
    </>
  );
}

export function HeaderBizoneEditable({
  ordonnance,
  onChange,
  prescription,
  setPrescription,
}: HeaderEditableProps) {
  return (
    <>
      <PEC>PEC : {ordonnance.sCodePatient}</PEC>

      <Header>
        <MainTitle>
          <CerfaInfo />

          <Title>
            <H1>Ordonnance bizone</H1>
            <p>
              Articles L. 322-3, 3° et 4°, L 324-1 et R. 161-45 du code de la
              sécurité sociale
            </p>
          </Title>
        </MainTitle>

        <Section>
          <IdentificationPrescripteur>
            <IdentificationDivTitle>
              Identification du prescripteur
            </IdentificationDivTitle>

            <Precision>(nom, prénom et identifiant)</Precision>

            <Center>
              <StyledInputNeutral
                name="sCiviliteNomPrenomPrescripteur"
                id="sCiviliteNomPrenomPrescripteur"
                type="text"
                placeholder="Identité du prescripteur"
                value={ordonnance.sCiviliteNomPrenomPrescripteur}
                onChange={onChange}
              />
            </Center>

            {ordonnance.sRPPSPrescripteur && (
              <BarcodeDiv>
                <div>
                  <BarcodeDivTitle>N° RPPS</BarcodeDivTitle>
                  <Barcode value={ordonnance.sRPPSPrescripteur} />
                </div>
              </BarcodeDiv>
            )}
          </IdentificationPrescripteur>

          <IdentificationStructure>
            <IdentificationDivTitle>
              Identification de la structure
            </IdentificationDivTitle>

            <Precision>
              (raison sociale du cabinet, de l'établissement et n°AM, FINESS ou
              SIRET)
            </Precision>

            <p>
              <StyledInputNeutral
                name="sAdressePrescripteur"
                id="sAdressePrescripteur"
                type="text"
                placeholder="Adresse du prescripteur"
                value={ordonnance.sAdressePrescripteur}
                onChange={onChange}
              />
            </p>

            <StyledInputNeutral
              name="sAdeliPrescripteur"
              id="sAdeliPrescripteur"
              type="text"
              placeholder="N° FINESS"
              value={ordonnance.sAdeliPrescripteur}
              onChange={onChange}
            />
            {ordonnance.sAdeliPrescripteur && (
              <div>
                <BarcodeDivTitle>N° FINESS</BarcodeDivTitle>
                <Barcode value={ordonnance.sAdeliPrescripteur} />
              </div>
            )}
          </IdentificationStructure>

          <IdentificationPatient>
            <IdentificationDivTitle>
              Identification du patient
              <PatientId>{ordonnance.sCodePatient}</PatientId>
            </IdentificationDivTitle>
            <Precision>
              (nom de famille (de naissance), suivi du nom d'usage (facultatif
              et si il y a lieu)) (à compléter par le prescripteur)
            </Precision>
            <StyledInputNeutral
              name="sCiviliteNomPrenomPatient"
              id="sCiviliteNomPrenomPatient"
              type="text"
              placeholder="Civilité, Nom, Prénom Patient"
              value={ordonnance.sCiviliteNomPrenomPatient}
              onChange={onChange}
            />
            <FormItem>
              <DatePicker
                value={
                  ordonnance.dDateNaissancePatient
                    ? moment(ordonnance.dDateNaissancePatient)
                    : moment(new Date())
                }
                placeholder="jj/mm/aaaa"
                name="dDateNaissancePatient"
                format="DD/MM/YYYY"
                locale={locale}
                onChange={(momentDate) => {
                  if (
                    momentDate &&
                    prescription &&
                    prescription.oJustificatifs &&
                    prescription.oJustificatifs.ordonnance
                  ) {
                    setPrescription({
                      ...prescription,
                      oJustificatifs: {
                        ...prescription.oJustificatifs,
                        ordonnance: {
                          ...prescription.oJustificatifs.ordonnance,
                          dDateNaissancePatient: new Date(
                            momentDate.format("YYYY-MM-DD")
                          ),
                        },
                      },
                      bUpdated: true,
                    });
                  }
                }}
              />
              {ordonnance.dDateNaissancePatient &&
                new Date(ordonnance.dDateNaissancePatient).toLocaleDateString()}
            </FormItem>
            <Precision>
              n° d'immatriculation (à compléter par l'assuré(e))
            </Precision>
            <FormItem>
              <StyledInputNeutral
                name="sNoSecuriteSociale"
                id="sNoSecuriteSociale"
                type={"number"}
                maxLength={15}
                placeholder="Numéro de Sécurité Sociale"
                value={ordonnance.sNoSecuriteSociale}
                onChange={onChange}
              />
            </FormItem>{" "}
          </IdentificationPatient>
        </Section>
      </Header>
    </>
  );
}
