import axios, { AxiosResponse } from "axios";
import config from "../config";

import type {
  IPrescription,
  IPrescriptionDetail,
} from "../interfaces/prescription.interface";
import type { DocumentDetails } from "../interfaces/document.interface";

interface FilteredPrescriptionsOption {
  filter:
    | "sFKCodesPrescription"
    | "sJoursRestants"
    | "sNomPatient"
    | "sPeriodeDebut"
    | "sPeriodeFin"
    | "sPeriodeProchainRdvDebut"
    | "sPeriodeProchainRdvFin"
    | "tLieuxExercice";
  value: string | string[];
}

export async function getDocumentsByPrescription(
  iPKAnnuaire: number,
  iPKPrescription: number
): Promise<DocumentDetails[]> {
  const { data } = await axios.get(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iPKPrescription}/documents`,
    { withCredentials: true }
  );
  return data;
}

export const getDocumentLink = (
  iPKAnnuaire: number,
  iPKPrescription: number,
  iPKTmpPprDocument: number
) => {
  return `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iPKPrescription}/documents/${iPKTmpPprDocument}`;
};

export async function getPrescription(
  iPKAnnuaire: number,
  iFKPrescription: number,
  reload: boolean = false
) {
  const { data } = await axios.get(
    `${
      config.serverURL
    }/medecins/${iPKAnnuaire}/prescriptions/${iFKPrescription}${
      reload ? "/reload" : ""
    }`,
    { withCredentials: true }
  );
  console.log({ data });

  return data as IPrescriptionDetail;
}

export async function getPrescriptions(
  iPKAnnuaire: number,
  options?: FilteredPrescriptionsOption[]
) {
  const { data } = await axios.get(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions`,
    {
      params: options?.reduce(
        (acc, curr) => ({ ...acc, [curr.filter]: curr.value }),
        {}
      ),
      withCredentials: true,
    }
  );

  return data as IPrescription[];
}

export async function getPrescriptionsASigner(
  iPKAnnuaire: number,
  options?: FilteredPrescriptionsOption[]
) {
  const { data } = await axios.get(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/a-signer`,
    {
      params: options?.reduce(
        (acc, curr) => ({ ...acc, [curr.filter]: curr.value }),
        {}
      ),
      withCredentials: true,
    }
  );
  return data as IPrescription[];
}

export async function sendValidRenouvellement(
  iPKAnnuaire: number,
  iFKPrescription: string,
  form: IPrescriptionDetail
): Promise<AxiosResponse> {
  return await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iFKPrescription}/renouvellement`,
    form,
    { withCredentials: true }
  );
}

// Méthode pour lancer le process d'ajout de données à Isadom "Classique"
export async function sendIsadomRenouvellement(
  iPKAnnuaire: number,
  iFKPrescription: string,
  form: IPrescriptionDetail
): Promise<AxiosResponse> {
  return await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iFKPrescription}/renouvellement-isadom`,
    form,
    { withCredentials: true }
  );
}

export async function sendValidMultiRenouvellementClassique(
  iPKAnnuaire: number,
  iFKPrescriptions: string[]
): Promise<AxiosResponse> {
  return await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/renouvellement-multiple-classique`,
    iFKPrescriptions,
    { withCredentials: true }
  );
}

/* AJOUT POUR SIGNATURE MULTIPLE - PARAMS (IPKAnnuaire, IFKPrescription[]) - APPELE DEPUIS src/pages/renouvellement/service/renouvellement.multi.service.tsx */
export async function sendValidMultiRenouvellement(
  iPKAnnuaire: number,
  iFKPrescriptions: string[]
): Promise<AxiosResponse> {
  return await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/renouvellement-multiple`,
    iFKPrescriptions,
    { withCredentials: true }
  );
}

export async function getPrescriptionAdvancedProgress(
  iPKAnnuaire: number,
  iFKPrescriptions: string[]
): Promise<AxiosResponse> {
  return await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/renouvellement-multiple-progress-status`,
    iFKPrescriptions,
    { withCredentials: true }
  );
}

export async function getPrescriptionAdvancedProgressClassicMulti(
  iPKAnnuaire: number,
  iFKPrescriptions: string[]
): Promise<AxiosResponse> {
  return await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/renouvellement-multiple-progress-status-classic-multi`,
    iFKPrescriptions,
    { withCredentials: true }
  );
}

export async function uploadDocument(
  iPKAnnuaire: number,
  iFKPrescription: number,
  args: any
) {
  await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iFKPrescription}/documents`,
    {
      sDocument: args.dataUrlDocument,
      name: args.name,
      iFKAnnuairePatient: args.iFKAnnuairePatient,
    },
    { withCredentials: true }
  );
}

export async function saveRenouvellement(
  iPKAnnuaire: number,
  iFKPrescription: string,
  form: IPrescriptionDetail
) {
  await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iFKPrescription}/renouvellement/save`,
    form,
    { withCredentials: true }
  );
}

export async function deletePrescription(
  iPKAnnuaire: number,
  iPKPrescription: number,
  form: any
) {
  await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iPKPrescription}/delete`,
    form,
    { withCredentials: true }
  );
}

// ADD PRESCRIPTION TO BASKET
export async function addPrescriptionToBasket(
  iPKAnnuaire: number,
  iPKPrescription: number,
  form: any
) {
  await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iPKPrescription}/basket/add`,
    form,
    { withCredentials: true }
  );
}

export async function deletePrescriptionFromBasket(
  iPKAnnuaire: number,
  iPKPrescription: number,
  form: any
) {
  await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${iPKPrescription}/basket/delete`,
    form,
    { withCredentials: true }
  );
}

export async function annulationSignaturePrescription(
  iPKAnnuaire: number,
  bundleId: number,
  form: any
) {
  await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/${bundleId}/annulation-signature`,
    form,
    { withCredentials: true }
  );
}
/** Appelle d'API pour les prescriptions vierges */
export async function sendNewPrescription(
  iPKAnnuaire: number,
  form: IPrescriptionDetail
): Promise<AxiosResponse> {
  return await axios.post(
    `${config.serverURL}/medecins/${iPKAnnuaire}/prescriptions/nouvelle`,
    form,
    { withCredentials: true }
  );
}
