import { Checkbox, Form, Input } from "antd";
import type { ChangeEvent as ReactChangeEvent } from "react";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SendOutlined } from "@ant-design/icons";
import FormItem from "antd/lib/form/FormItem";
// import TextArea from 'antd/lib/input/TextArea';
import styled from "styled-components";

import {
  CheckboxItem,
  CheckboxList,
  StyledCheckboxGroup as LabeledCheckboxGroup,
} from "../../../elements/input.elements";
import type { IOrdonnancePPCRenouvellement } from "../../../../../interfaces/ordonnance.interface";
import { SectionHeader } from "../sectionHeader.elements";

const Body = styled.div``;

export const CheckboxForm = styled(Form)``;

const Checkboxgroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 75%;
`;

const CheckboxLabel = styled.div`
  width: 25%;
`;

const Icon = styled.div``;

const Section = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 30px auto;
  padding: 5px 0px 5px 0px;
`;

interface TraitementSectionProps {
  ordonnance: IOrdonnancePPCRenouvellement;
  onChange: (e: ChangeEvent) => void;
}

type ChangeEvent = CheckboxChangeEvent | ReactChangeEvent<HTMLInputElement>; //| HTMLTextAreaElement

export default function TraitementSection({
  onChange,
  ordonnance,
}: TraitementSectionProps) {
  return (
    <>
      <SectionHeader>Traitement prescrit</SectionHeader>

      <Body>
        <Section>
          <Icon>
            <SendOutlined />
          </Icon>

          <Form>
            <FormItem>
              <LabeledCheckboxGroup>
                <Checkbox
                  checked={ordonnance.bPrescriptionInitiale}
                  name="bPrescriptionInitiale"
                  onChange={onChange}
                >
                  Prescription initiale
                </Checkbox>

                <Checkbox
                  checked={ordonnance.b1erRenouvellement4Mois}
                  name="b1erRenouvellement4Mois"
                  onChange={onChange}
                >
                  1er renouvellement à 4 mois
                </Checkbox>

                <Checkbox
                  checked={ordonnance.bRenouvellementAnnuel}
                  name="bRenouvellementAnnuel"
                  onChange={onChange}
                >
                  Renouvellement annuel
                </Checkbox>
              </LabeledCheckboxGroup>
            </FormItem>

            <CheckboxForm layout="vertical">
              <FormItem label="Type d'appareil :">
                <LabeledCheckboxGroup>
                  <CheckboxList>
                    <CheckboxItem>
                      <Checkbox
                        checked={ordonnance.bPPCModeAuto}
                        name="bPPCModeAuto"
                        onChange={onChange}
                      >
                        PPC autopilotée
                      </Checkbox>
                    </CheckboxItem>

                    <CheckboxItem>
                      <Checkbox
                        checked={ordonnance.bPPCModeFixe}
                        name="bPPCModeFixe"
                        onChange={onChange}
                      >
                        PPC à pression fixe
                      </Checkbox>
                    </CheckboxItem>

                    <CheckboxItem>
                      <Checkbox
                        checked={ordonnance.bPPCModeDouble}
                        name="bPPCModeDouble"
                        onChange={onChange}
                      >
                        à double niveau de pression
                      </Checkbox>
                    </CheckboxItem>
                  </CheckboxList>
                </LabeledCheckboxGroup>
              </FormItem>
            </CheckboxForm>

            <FormItem label="Marque et modèle de l'appareil :">
              <Input
                value={ordonnance.sAppareil}
                name="sAppareil"
                onChange={onChange}
              />
            </FormItem>

            <FormItem label="Pression ou intervalle de pression (cm H2O) :">
              <Input
                value={ordonnance.sReglagePression}
                name="sReglagePression"
                onChange={onChange}
              />
            </FormItem>

            <FormItem label="Interface (type, marque et modèle) :">
              <Input
                value={ordonnance.sConsommable}
                // disabled={!ordonnance.bPPC} // FIXME
                name="sConsommable"
                onChange={onChange}
              />
            </FormItem>

            <FormItem>
              <LabeledCheckboxGroup>
                <CheckboxLabel>Rampe&nbsp;:</CheckboxLabel>

                <Checkboxgroup>
                  <Checkbox
                    checked={ordonnance.bRampeVrai}
                    name="bRampeVrai"
                    onChange={onChange}
                  >
                    Oui
                  </Checkbox>

                  <Checkbox
                    checked={ordonnance.bRampeFaux}
                    name="bRampeFaux"
                    onChange={onChange}
                  >
                    Non
                  </Checkbox>
                </Checkboxgroup>
              </LabeledCheckboxGroup>
            </FormItem>

            <FormItem>
              <LabeledCheckboxGroup>
                <CheckboxLabel>Mode confort&nbsp;:</CheckboxLabel>

                <Checkboxgroup>
                  <Checkbox
                    checked={ordonnance.bModeConfortVrai}
                    name="bModeConfortVrai"
                    onChange={onChange}
                  >
                    Oui
                  </Checkbox>

                  <Checkbox
                    checked={ordonnance.bModeConfortFaux}
                    name="bModeConfortFaux"
                    onChange={onChange}
                  >
                    Non
                  </Checkbox>
                </Checkboxgroup>
              </LabeledCheckboxGroup>
            </FormItem>

            <FormItem>
              <LabeledCheckboxGroup>
                <CheckboxLabel>Humidificateur chauffant&nbsp;:</CheckboxLabel>

                <Checkboxgroup>
                  <Checkbox
                    checked={ordonnance.bHumidificateurVrai}
                    name="bHumidificateurVrai"
                    onChange={onChange}
                  >
                    Oui
                  </Checkbox>

                  <Checkbox
                    checked={ordonnance.bHumidificateurFaux}
                    name="bHumidificateurFaux"
                    onChange={onChange}
                  >
                    Non
                  </Checkbox>
                </Checkboxgroup>
              </LabeledCheckboxGroup>
            </FormItem>

            <FormItem>
              <LabeledCheckboxGroup>
                <CheckboxLabel>Circuit chauffant&nbsp;:</CheckboxLabel>

                <Checkboxgroup>
                  <Checkbox
                    checked={ordonnance.bCircuitVrai}
                    name="bCircuitVrai"
                    onChange={onChange}
                  >
                    Oui
                  </Checkbox>

                  <Checkbox
                    checked={ordonnance.bCircuitFaux}
                    name="bCircuitFaux"
                    onChange={onChange}
                  >
                    Non
                  </Checkbox>
                </Checkboxgroup>
              </LabeledCheckboxGroup>
            </FormItem>
          </Form>
        </Section>
      </Body>
    </>
  );
}
