import styled from "styled-components";
import { Label, PrimaryButton } from "@ads/ui";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import { motion } from "framer-motion";
import { Form, DatePicker } from "antd";
import FormSelectWrapper from "../../atoms/form/FormSelectWrapper";

import moment from "moment";
import StyleDatePicker from "../../atoms/style/StyleDatePicker";
import { SelectPatientPrescription } from "./components/SelectPatientPrescription";
import { FiltersPrescriptionsService } from "./hooks/useFiltersPrescriptions";
import { SelectForfaitPrescription } from "./components/SelectForfaitPrescription";
import { SelectLieuxExercice } from "./components/SelectLieuxExercice";

const FilterWrappers = styled(motion.div)`
  width: 100%;
  background: ${(props) => props.theme.colors.neutral[190]};
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  z-index: 100;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% 40% 30%;
  padding: 12px 0;
`;

const Col = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 43px 0;
  &:first-child {
    border-right: 1px solid ${(props) => props.theme.colors.neutral[190]};
  }
`;

const WrapperCol = styled.div`
  width: 315px;
  &.date {
    width: 100%;
    padding: 0 65px;
  }
`;

const DateLine = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const GridDate = styled.div`
  display: grid;
  grid-template-columns: auto 68px auto;

  .separator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    text-align: center;
    color: ${(props) => props.theme.colors.neutral[100]};
  }
`;
export const ButtonWrapper = styled.div`
  background-color: "green";
`;

const variants = {
  visible: {
    opacity: 1,
    display: "inherit",
    y: "-97%",
    transition: { duration: 0.3 },
  },
  hidden: {
    opacity: 1,
    display: "none",
    y: 0,
    transition: { duration: 0.3 },
  },
};

const FilterPrescriptions = (controler: FiltersPrescriptionsService) => {
  return (
    <FilterWrappers
      variants={variants}
      animate={controler.isFilterOpen ? "visible" : "hidden"}
    >
      <Form form={controler.form}>
        <Grid>
          <Col className="col">
            <WrapperCol>
              <FormSelectWrapper name="Patient">
                <SelectPatientPrescription {...controler} />
              </FormSelectWrapper>

              <FormSelectWrapper name="Forfait">
                <SelectForfaitPrescription {...controler} />
              </FormSelectWrapper>

              <FormSelectWrapper name="tLieuxExercice">
                {controler.medecin?.tLieuxExercice && (
                  <SelectLieuxExercice {...controler} />
                )}
              </FormSelectWrapper>
            </WrapperCol>
          </Col>
          <Col>
            <WrapperCol className="date">
              <DateLine>
                <Label htmlFor="">Fin de prescription entre le </Label>
                <GridDate>
                  <StyleDatePicker>
                    <DatePicker
                      onChange={(date, dateString) =>
                        controler.addFilter(
                          "sPeriodeDebut",
                          dateString.split("/").reverse().join("-")
                        )
                      }
                      placeholder="jj/mm/aaaa"
                      format="DD/MM/YYYY"
                      value={
                        controler.options.find(
                          (o) => o.filter === "sPeriodeDebut"
                        )?.value
                          ? moment(
                              controler.options.find(
                                (o) => o.filter === "sPeriodeDebut"
                              )?.value
                            )
                          : undefined
                      }
                      locale={locale}
                    />
                  </StyleDatePicker>
                  <p className="separator">et le </p>
                  <StyleDatePicker>
                    <DatePicker
                      onChange={(date, dateString) =>
                        controler.addFilter(
                          "sPeriodeFin",
                          dateString.split("/").reverse().join("-")
                        )
                      }
                      placeholder="jj/mm/aaaa"
                      format="DD/MM/YYYY"
                      value={
                        controler.options.find(
                          (o) => o.filter === "sPeriodeFin"
                        )?.value
                          ? moment(
                              controler.options.find(
                                (o) => o.filter === "sPeriodeFin"
                              )?.value
                            )
                          : undefined
                      }
                      locale={locale}
                    />
                  </StyleDatePicker>
                </GridDate>
              </DateLine>
              <DateLine>
                <Label htmlFor="">Prochain rendez-vous entre le </Label>
                <GridDate>
                  <StyleDatePicker>
                    <DatePicker
                      onChange={(date, dateString) =>
                        controler.addFilter(
                          "sPeriodeProchainRdvDebut",
                          dateString.split("/").reverse().join("-")
                        )
                      }
                      placeholder="jj/mm/aaaa"
                      format="DD/MM/YYYY"
                      value={
                        controler.options.find(
                          (o) => o.filter === "sPeriodeProchainRdvDebut"
                        )?.value
                          ? moment(
                              controler.options.find(
                                (o) => o.filter === "sPeriodeProchainRdvDebut"
                              )?.value
                            )
                          : undefined
                      }
                      locale={locale}
                    />
                  </StyleDatePicker>
                  <p className="separator">et le </p>
                  <StyleDatePicker>
                    <DatePicker
                      onChange={(date, dateString) =>
                        controler.addFilter(
                          "sPeriodeProchainRdvFin",
                          dateString.split("/").reverse().join("-")
                        )
                      }
                      placeholder="jj/mm/aaaa"
                      format="DD/MM/YYYY"
                      value={
                        controler.options.find(
                          (o) => o.filter === "sPeriodeProchainRdvFin"
                        )?.value
                          ? moment(
                              controler.options.find(
                                (o) => o.filter === "sPeriodeProchainRdvFin"
                              )?.value
                            )
                          : undefined
                      }
                      locale={locale}
                    />
                  </StyleDatePicker>
                </GridDate>
              </DateLine>
            </WrapperCol>
          </Col>
          <Col>
            <WrapperCol>
              <ButtonWrapper>
                <PrimaryButton
                  as="button"
                  size="S"
                  label="Appliquer"
                  buttonStyle="primary"
                  type="submit"
                  minWidth="200px"
                  onClick={() => controler.search(controler.options)}
                />
              </ButtonWrapper>
              <div>
                <PrimaryButton
                  as="button"
                  size="S"
                  label="Réinitialiser"
                  buttonStyle="danger"
                  type="reset"
                  minWidth="200px"
                  onClick={() => controler.handleReset()}
                />
              </div>
            </WrapperCol>
          </Col>
        </Grid>
      </Form>
    </FilterWrappers>
  );
};

export default FilterPrescriptions;
