import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

// Slices
import {
  toggleModal,
  selectContentModal,
} from "../../../../redux/modal/modal.slice";

// Components
import EditButton from "../../../atoms/buttons/EditButton";
import TitleIcon from "../../../atoms/text/TitleIcon";
import InfosPersos from "../InfosPersos";
import FormInfosPerso from "../../FormInfosPerso";

const Section = styled("div")`
  margin-bottom: 24px;
  padding: 24px 7px 24px 49px;
  border-bottom: 1px solid #f4f4f4;
  &:last-child {
    border: none;
  }
`;

const HeaderSection = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const ProfilItemInfos = () => {
  const dispatch = useDispatch();

  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);

  const handleClick = () => {
    dispatch(toggleModal());
    setContentModal(<FormInfosPerso />);
  };

  return (
    <Section>
      <HeaderSection>
        <TitleIcon title="Informations personnelles" icon="infoPerso" />
        <EditButton label="Modifier" handleClick={handleClick} />
      </HeaderSection>
      <InfosPersos />
    </Section>
  );
};

export default ProfilItemInfos;
