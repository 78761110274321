import { Divider, Input } from "antd";
import React from "react";
import styled from "styled-components";

export const mainColor = "#3084bd";

export const BarcodeDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Cerfa = styled.div`
  align-items: center;
  color: ${mainColor};
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
`;

export const CerfaInfo = () => {
  return (
    <Cerfa>
      <CerfaLogo>cerfa</CerfaLogo>
      N° 60-3937
    </Cerfa>
  );
};

const CerfaLogo = styled.div`
  align-items: center;
  background-color: ${mainColor};
  border-radius: 45%;
  color: #fff;
  display: flex;
  font-family: "Times New Roman";
  font-size: 16px;
  font-style: italic;
  line-height: 1.5715;
  margin: 1px;
  padding: 0px 5px 0px 5px;
  width: fit-content;
`;

export const IdentificationDiv = styled.div`
  border: 2px solid ${mainColor};
  margin-top: 18px;
  padding: 5px;
`;

export const IdentificationDivTitle = styled.div`
  color: ${mainColor};
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
`;

export const Separator = styled(Divider)`
  background-color: ${mainColor};
  height: 2px;
  margin: 10px 0;
`;

export const TextArea = styled(Input.TextArea)`
  display: block;
  white-space: pre;
  width: 100%;

  :focus,
  :hover {
    border: 1px solid ${mainColor};
    box-shadow: 0px 0px 4px ${mainColor};
  }
`;

export const Wrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: unset;
  margin-left: auto;
  margin-right: auto;
  width: 850px;
`;
