import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ProtectedRoute from "./components/core/routes/ProtectedRoute";
import Signature from "./components/organisms/Signature";
import { notification } from "antd";
import AnimatedSwitch from "./components/core/routes/AnimatedSwitch";

import { history } from "./redux/configureStore";

import "./App.css";
import "antd/dist/antd.css";

import { getConfig } from "./api/config.api";
import { getMedecin } from "./api/medecins.api";

import { setConfig } from "./redux/config/config.slice";
import {
  setMedecinInfos,
  selectUtilisateur,
} from "./redux/utilisateur/utilisateur.slice";

import type { Identite } from "./interfaces/utilisateur.interface";

import IndexView from "./pages/index/index.view";
import InfosView from "./pages/infos/infos.view";
import ConnexionView from "./pages/utilisateur/connexion/connexion.view";
import DeconnexionView from "./pages/utilisateur/deconnexion/deconnexion.view";
import RenouvellementView from "./pages/prescription/renouvellement.view";
import { authentificationAction } from "./redux/utilisateur/actions/authentification.action";
import { Prescription } from "./pages/prescription/prescription.view";
import theme from "./assets/theme/theme.adene";

const initBeforeUnLoad = () => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (history.location.pathname.indexOf("renouvellement") > 0) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      alert("exit");
      return "";
    }
  };
};

function App() {
  const dispatch = useDispatch();
  const identity = useSelector(selectUtilisateur);

  window.onload = function () {
    initBeforeUnLoad();
  };

  const getMedecinFromIdentity = useCallback(
    async (identity: Identite) => {
      try {
        const medecin = await getMedecin(identity.iFKAnnuaire!); // FIXME: iFKAnnuaire!
        dispatch(setMedecinInfos({ medecinInfos: medecin }));
        const config = await getConfig();
        dispatch(setConfig(config));
      } catch (error) {
        notification.error({
          description:
            "Nous rencontrons une erreur de chargement de vos informations, veuillez contacter votre administrateur",
          message: "Echec du chargement de vos informations",
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(authentificationAction());
  }, [dispatch]);

  useEffect(() => {
    identity && getMedecinFromIdentity(identity);
    initBeforeUnLoad();
  }, [getMedecinFromIdentity, identity]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AnimatedSwitch>
          <ProtectedRoute path="/" exact component={IndexView} />
          <ProtectedRoute
            path="/mes-informations"
            exact
            component={InfosView}
          />

          <Route
            path="/utilisateur/connexion"
            exact
            component={ConnexionView}
          />
          <Route
            path="/utilisateur/deconnexion"
            exact
            component={DeconnexionView}
          />
          <Route
            path="/renouvellement/:iPKPrescription"
            exact
            component={RenouvellementView}
          />
          <Route path="/prescription" exact component={Prescription} />
          <Route
            path="/signature-manuelle/:iPKAnnuaire/:otp"
            component={Signature}
          />
        </AnimatedSwitch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
