import styled from "styled-components";
import { useSelector } from "react-redux";

import { selectMedecinInfos } from "../../../redux/utilisateur/utilisateur.slice";

import profilButton from "../../../assets/icons/profil_bouton.svg";
import numAdeli from "../../../assets/icons/num_adeli.svg";
import contactPhone from "../../../assets/icons/phone.svg";
import contactEmail from "../../../assets/icons/email.svg";

const Wrapper = styled("div")`
  display: block;
  padding: 25px 0 0 55px;
  border-left: 1px solid #fff;
`;

const TitleProfil = styled("h2")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  color: ${(props) => props.theme.colors.neutral[100]};
  font-size: 1.5rem;
  font-weight: 600;

  gap: 7px;

  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 19px;
    background-image: url(${profilButton});
    background-size: cover;
  }
`;

const SubTitle = styled("h3")`
  color: ${(props) => props.theme.colors.neutral[100]};
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 1.7rem;
`;

const ItemProfil = styled("dl")`
  margin-bottom: 25px;
`;

const Label = styled("dt")`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 3px;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1;
  color: ${(props) => props.theme.colors.neutral[100]};
  &:before {
    content: "";
    display: block;
  }
  &.adeli {
    &:before {
      width: 11px;
      height: 14px;
      background-image: url(${numAdeli});
    }
  }
  &.phone {
    &:before {
      width: 11px;
      height: 15px;
      background-image: url(${contactPhone});
    }
  }
  &.email {
    &:before {
      width: 17px;
      height: 12px;
      background-image: url(${contactEmail});
    }
  }
`;
const ItemProfilContent = styled("dd")`
  margin-bottom: 3px;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1;
  color: ${(props) => props.theme.colors.neutral[100]};
  font-size: 1.3rem;
  line-height: 1.8rem;
  &:hover {
    color: ${(props) => props.theme.colors.neutral[100]};
  }
`;

const IndexProfil = () => {
  const medecinInfos: any = useSelector(selectMedecinInfos);
  return medecinInfos ? (
    <Wrapper>
      <TitleProfil>Profil</TitleProfil>
      <SubTitle>
        {medecinInfos.sCodeCivilite} {medecinInfos.sNom} {medecinInfos.sPrenom}
      </SubTitle>

      <ItemProfil>
        <Label className="adeli">Numéro Adeli</Label>
        <ItemProfilContent>{medecinInfos.sNumAdeli}</ItemProfilContent>
      </ItemProfil>

      <ItemProfil>
        <Label className="email">Email</Label>
        <ItemProfilContent
          as="a"
          className="link"
          href={`mailto:${medecinInfos.sMail}`}
        >
          {medecinInfos.sMail}
        </ItemProfilContent>
      </ItemProfil>

      {medecinInfos.sTelGsm && (
        <ItemProfil>
          <Label className="phone">Téléphone</Label>
          <ItemProfilContent>{medecinInfos.sTelGsm}</ItemProfilContent>
        </ItemProfil>
      )}
    </Wrapper>
  ) : null;
};

export default IndexProfil;
