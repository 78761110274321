import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";
import { DatePicker } from "antd";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import moment from "moment";
import { mainColor } from "./ordonnance.style";
import { IPrescriptionRenouvellement } from "../../../interfaces/prescription.interface";

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
  margin: 0;
  padding: 0;
`;

const FooterText = styled.div`
  color: ${mainColor};
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const Label = styled.span``;

const Left = styled.div`
  font-size: 8px;
`;

const Right = styled.div``;

const SignatureDiv = styled.div`
  border: 2px dotted ${mainColor};
  display: flex;
  flex-direction: column;
  padding: 5px;

  * {
    margin-bottom: 2px;
  }
`;

const Space = styled.div``;

const StampDiv = styled.div`
  border: 2px dotted ${mainColor};
  padding: 5px;
`;

interface FooterProps {
  bDateSignature: boolean;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}

export default function Footer({
  bDateSignature,
  prescription,
  setPrescription,
}: FooterProps) {
  return (
    <>
      <Content>
        <StampDiv>
          <Label>CACHET</Label>
        </StampDiv>

        <Space />

        <SignatureDiv>
          <FormItem label="DATE">
            <DatePicker
              disabled={!bDateSignature}
              value={
                prescription
                  ? moment(prescription.dDateSignature)
                  : moment(new Date())
              }
              name="dDateSignature"
              format="DD/MM/YYYY"
              locale={locale}
              onChange={(momentDate) => {
                if (momentDate)
                  setPrescription({
                    ...prescription,
                    dDateSignature: new Date(momentDate.format("YYYY-MM-DD")),
                    bUpdated: true,
                  });
              }}
            />
          </FormItem>
          <Label>SIGNATURE</Label>
        </SignatureDiv>
      </Content>

      <FooterText>
        <Left>UCANSS - 03/03</Left>
        <Right>S 3321 a</Right>
      </FooterText>
    </>
  );
}
