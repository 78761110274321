export function computeRemainingDays(date: Date | string) {
  const fin = new Date(date).getTime();
  const today = new Date().getTime();
  return (fin - today) / 86400000;
}

export function frenchDateStringToISODateString(localDateString: string) {
  const [d, m, y] = localDateString.split('/');
  return new Date(`${y}-${m}-${d}`).toISOString().slice(0, 10);
}
