import styled from "styled-components";

export const Title = styled.h5`
  text-align: center;
  text-transform: uppercase;
`;

export const Notice = styled.div`
  padding: 0 0 1rem 1rem;
`;

export const Wrapper = styled.div`
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-radius: 12px;
  border-right: 2px solid black;
  font-size: 12px !important;
`;
