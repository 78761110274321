import { MultiRollingPanel } from "@ads/ui";
import styled from "styled-components";
import toggleLeft from "@ads/ui/lib/esm/assets/img/btn-toggle-left-dark.svg";
import toggleRight from "@ads/ui/lib/esm/assets/img/btn-toggle-right-dark.svg";

export const MultiRollingPanelStyled = styled(MultiRollingPanel)`
  z-index: 10;
  background-color: ${(props) => props.theme.colors.neutral[190]};
  height: 100%;

  .multi-rolling-panel-toggle {
    color: white;
    background-image: ${(props) => {
      if (props.side === "left" || !props.side) {
        return `url(${toggleLeft})`;
      }
      return `url(${toggleRight})`;
    }};
  }
`;
