import styled from "styled-components";
import { NavLink } from "react-router-dom";
import dashboard from "../../../assets/icons/dashboard.svg";
import profil from "../../../assets/icons/profil.svg";
import logout from "../../../assets/icons/logout.svg";

export const NavWrapper = styled("ul")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;
export const StyleNavLogo = styled(NavLink)`
  display: block;
  ${parseInt(process.env.REACT_APP_ADD_LOGO_WHITE_BOX || "", 10) === 1
    ? "padding: 5px 15px;"
    : ""}
  ${parseInt(process.env.REACT_APP_ADD_LOGO_WHITE_BOX || "", 10) === 1
    ? "background: #fff;"
    : ""}
  ${parseInt(process.env.REACT_APP_ADD_LOGO_WHITE_BOX || "", 10) === 1
    ? "border-radius: 13px;"
    : ""}
`;

export const NavItem = styled.li`
  margin-right: 20px;
  list-style: none;
`;

export const CustomNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 400;
  transition: all 0.2s linear;
  &.profil {
    &:before {
      content: "";
      display: block;
      background-image: url(${profil});
      width: 22px;
      height: 21px;
    }
  }
  &.dashboard {
    &:before {
      content: "";
      display: block;
      background-image: url(${dashboard});
      width: 30px;
      height: 25px;
    }
  }

  &.active,
  &:hover {
    color: ${(props) => props.theme.colors.neutral[100]};
    background-color: ${(props) => props.theme.colors.primary[100]};
    border: 1px solid ${(props) => props.theme.colors.primary[100]};
  }
`;

export const Logout = styled(NavLink)`
  display: block;
  background-image: url(${logout});
  width: 24px;
  height: 27px;
`;

interface PropsNavLogo {
  children: React.ReactNode;
  to: string;
}

export const NavLogo = ({ children, to }: PropsNavLogo) => (
  <StyleNavLogo to={to}>{children}</StyleNavLogo>
);
