import React from "react";
import styled from "styled-components";
import iconContactCommercial from "../../assets/icons/contact_commercial.svg";

const Bubble = styled("button")`
  position: relative;
  z-index: 999;
  display: block;
  width: 71px;
  height: 71px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: none;
  background-color: ${(props) => props.theme.colors.secondary[100]};
  background-image: url(${iconContactCommercial});
  background-size: 35px 30px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  pointer-events: auto;
  transition: all 0.3s linear;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary[90]};
  }
`;

interface PropsContactBubble {
  onClick: () => void;
}

const ContactBubble = ({ onClick }: PropsContactBubble) => (
  <Bubble onClick={onClick} />
);

export default ContactBubble;
