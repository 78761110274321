import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ModeleTexte from "../../../interfaces/modeleTexte.interface";
import {
  selectContentModal,
  toggleModal,
} from "../../../redux/modal/modal.slice";

// Components

import AddButton from "../../atoms/buttons/AddButton";
import TitleIcon from "../../atoms/text/TitleIcon";
import { FormNewModele } from "../FormModele";

const Section = styled("div")`
  padding: 24px 7px 18px 49px;
  border-bottom: 1px solid #f4f4f4;
`;

const HeaderSection = styled("div")`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  title: string;
  icon: "infoPerso" | "signature" | "place" | "place" | "modeles";
  setModeles: React.Dispatch<React.SetStateAction<ModeleTexte[]>>;
}

const ModeleHeader = ({ title, icon, setModeles }: Props) => {
  const dispatch = useDispatch();
  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);

  const handleClick = () => {
    dispatch(toggleModal());
    setContentModal(<FormNewModele setModeles={setModeles} />);
  };

  return (
    <Section>
      <HeaderSection>
        <TitleIcon title={title} icon={icon} />
        <AddButton label="Ajouter" handleClick={handleClick} />
      </HeaderSection>
    </Section>
  );
};

export default ModeleHeader;
