import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

import { connexionAction } from "../../../redux/utilisateur/actions/connexion.action";
import { selectConnecte } from "../../../redux/utilisateur/utilisateur.slice";

import config from "../../../config";
import Layout from "../../../components/templates/Layout";
import AdsPng from "../../../assets/icons/logo-ADS.png";

const logoSrc = require(`../../../assets/logo-${process.env.REACT_APP_THEME}.${process.env.REACT_APP_LOGO_FORMAT}`);

const Wrapper = styled.div`
  max-width: 1600px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: block;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    padding: 2rem;
  }
`;

const WrapperBackground = styled("div")`
  background: ${(props) => props.theme.colors.gradient[1]};
`;

const Content = styled(motion.div)`
  background-color: rgb(58, 61, 75);
  border-radius: 20px;
  height: 55rem;
  opacity: 1;
  width: fit-content;
  height: fit-content;
  float: left;
  z-index: 2;
  margin: 10px 0;
  margin-left: 25rem;
  position: relative;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    float: none;
    margin: 0 auto;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

const AdsLogo = styled.div`
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  border-radius: 13px;
  background-color: white;
  padding: 1rem 2rem;
  z-index: 2;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    bottom: 2rem;
    right: 2rem;
  }
`;

const ContentLogo = styled.div`
  z-index: 2;
  position: relative;
  left: -10rem;
  ${parseInt(process.env.REACT_APP_ADD_LOGO_WHITE_BOX || "", 10) === 1
    ? "padding: 20px;"
    : ""}
  ${parseInt(process.env.REACT_APP_ADD_LOGO_WHITE_BOX || "", 10) === 1
    ? "background: #fff;"
    : ""}
  ${parseInt(process.env.REACT_APP_ADD_LOGO_WHITE_BOX || "", 10) === 1
    ? "border-radius: 13px;"
    : ""}
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin: 0 auto;
    width: fit-content;
  }
`;

const Logo = styled(motion.img)`
  height: 100px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    left: -2rem;
    width: 40rem;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    position: inherit;
    float: none;
    left: -5rem;
    width: 30rem;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 20rem;
    margin-bottom: 2rem;
  }
`;

const IFrame = styled.iframe`
  border: 0;
  height: 40rem;
  padding-top: 60px;
  width: 50rem;
  background-color: rgb(58, 61, 75);
  border-radius: 20px;
`;

export default function ConnexionView() {
  const dispatch = useDispatch();
  const bConnecte = useSelector(selectConnecte);

  useEffect(() => {
    const getToken = (evt: MessageEvent) => {
      const {
        origin,
        data: { token },
      } = evt;

      if (origin === config.identityServerURL) {
        dispatch(connexionAction(token));
      }
    };

    window.addEventListener("message", getToken, false);

    return () => window.removeEventListener("message", getToken);
  });

  const variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  if (bConnecte) {
    return <Redirect to="/" />;
  }

  return (
    <Layout
      navigation={false}
      main={
        <WrapperBackground>
          <Wrapper>
            <ContentLogo>
              <Logo
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
                transition={{ duration: 1 }}
                src={logoSrc.default}
                alt=""
                title=""
              />
            </ContentLogo>
            <Content
              initial="initial"
              animate="enter"
              exit="exit"
              variants={variants}
              transition={{ duration: 1 }}
            >
              <IFrame
                // Config ads
                src={`${config.identityServerURL}/login?src=${window.location.origin}${window.location.pathname}&theme=adene`}
              />
            </Content>
          </Wrapper>
          <AdsLogo>
            <img src={AdsPng} alt="ads" />
          </AdsLogo>
        </WrapperBackground>
      }
    />
  );
}
