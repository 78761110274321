import axios from 'axios';
import config from '../config';

import type { Config } from '../interfaces/config.interface';

export async function getConfig(): Promise<Config> {
  const { data } = await axios.get(
    `${config.serverURL}/config`,
    { withCredentials: true }
  );
  return data;
}
