import type { KeyboardEvent } from "react";
import { useEffect, useRef } from "react";
import type { TextAreaRef } from "antd/lib/input/TextArea";
import { Input } from "antd";

import { Separator, Wrapper } from "./ordonnance.style";
import Footer from "./footer.elements";
import { HeaderEditable, HeaderStandard } from "./header.standard.elements";
import { OrdonnanceStandardProps } from "./ordonnanceInterface";

export default function OrdonnanceStandard({
  bDateSignature,
  onChange,
  ordonnance,
  resetSelectedModele,
  selectedModele,
  prescription,
  setPrescription,
  isEditable,
}: OrdonnanceStandardProps) {
  const textAreaEl = useRef<TextAreaRef>(null);

  const handleTab = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { textArea } = textAreaEl.current!.resizableTextArea!;

    if (e.key === "Tab") {
      const { selectionStart, selectionEnd } = textArea;
      const contentAfter = textArea.value.slice(selectionEnd);
      const contentBefore = textArea.value.slice(0, selectionStart);
      const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        "value"
      )?.set;

      e.preventDefault();

      // React library overrides input value setter
      nativeTextAreaValueSetter?.call(
        textArea,
        `${contentBefore}\t${contentAfter}`
      );
      textArea.dispatchEvent(new Event("input", { bubbles: true }));

      textArea.selectionEnd = selectionStart + 1;
      textArea.selectionStart = selectionStart + 1;
    }
  };

  useEffect(() => {
    const textArea = textAreaEl.current?.resizableTextArea?.textArea;

    if (!selectedModele || !textArea) return;

    const { selectionStart, selectionEnd } = textArea;
    const contentAfter = textArea.value.slice(selectionEnd);
    const contentBefore = textArea.value.slice(0, selectionStart);
    const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      "value"
    )?.set;

    textAreaEl.current!.focus();

    // React library overrides input value setter
    nativeTextAreaValueSetter?.call(
      textArea,
      `${contentBefore}\n${selectedModele}\n${contentAfter}`
    );
    textArea.dispatchEvent(new Event("input", { bubbles: true }));

    textArea.selectionEnd = contentBefore.length + selectedModele.length + 2;
    textArea.selectionStart = selectionStart;

    resetSelectedModele();
  }, [selectedModele, resetSelectedModele]);

  return (
    <Wrapper>
      {isEditable && (
        <HeaderEditable
          ordonnance={ordonnance}
          onChange={onChange}
          prescription={prescription}
          setPrescription={setPrescription}
        />
      )}
      {!isEditable && (
        <HeaderStandard
          dDateNaissancePatient={ordonnance.dDateNaissancePatient}
          sAdeliPrescripteur={ordonnance.sAdeliPrescripteur}
          sAdressePrescripteur={ordonnance.sAdressePrescripteur}
          sCiviliteNomPrenomPatient={ordonnance.sCiviliteNomPrenomPatient}
          sCiviliteNomPrenomPrescripteur={
            ordonnance.sCiviliteNomPrenomPrescripteur
          }
          sCodePatient={ordonnance.sCodePatient}
          sNoSecuriteSociale={ordonnance.sNoSecuriteSociale}
          sRPPSPrescripteur={ordonnance.sRPPSPrescripteur}
        />
      )}

      <Separator />

      <Input.TextArea
        value={ordonnance.sContenu}
        name="sContenu"
        onChange={onChange}
        onKeyDown={handleTab}
        ref={textAreaEl}
        rows={25}
        cols={5}
        wrap={"hard"}
      />

      <Separator />

      <Footer
        bDateSignature={bDateSignature}
        prescription={prescription}
        setPrescription={setPrescription}
      />
    </Wrapper>
  );
}
