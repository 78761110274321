import { Checkbox, DatePicker, Form, Input } from "antd";
import React from "react";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Header } from "../../elements/header.elements";
import { SendOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";

import {
  CheckboxItem,
  CheckboxList,
  ListBoldFormItem,
  StyledCheckboxGroup,
  WeightFormItem,
} from "../../elements/input.elements";
import type IDAP from "../../../../interfaces/dap.interface";
import moment from "moment";
import { IPrescriptionRenouvellement } from "../../../../interfaces/prescription.interface";

const Body = styled.div``;

const CheckboxForm = styled(Form)``;

const Icon = styled.div``;

const Section = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 30px auto;
  padding: 5px 0px 5px 0px;
`;

interface TraitementSectionProps {
  justificatif: IDAP;
  onChange: (e: ChangeEvent) => void;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}

type ChangeEvent =
  | CheckboxChangeEvent
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export default function TraitementSection({
  justificatif,
  onChange,
  prescription,
  setPrescription,
}: TraitementSectionProps) {
  return (
    <>
      <Header>Traitement prescrit</Header>
      <Body>
        <Section>
          <Icon>
            <SendOutlined />
          </Icon>

          <Form>
            <WeightFormItem>
              <Checkbox
                checked={justificatif.bPPC}
                name="bPPC"
                onChange={onChange}
              >
                Traitement par pression positive continue (PPC)
              </Checkbox>
            </WeightFormItem>

            <WeightFormItem>
              <StyledCheckboxGroup>
                <Checkbox
                  checked={justificatif.sCochePPRInitiale === "X"}
                  disabled={!justificatif.bPPC}
                  name="sCochePPRInitiale"
                  onChange={onChange}
                >
                  Prescription initiale
                </Checkbox>

                <Checkbox
                  checked={justificatif.sCochePPR4Mois === "X"}
                  disabled={!justificatif.bPPC}
                  name="sCochePPR4Mois"
                  onChange={onChange}
                >
                  1er renouvellement à 4 mois
                </Checkbox>

                <Checkbox
                  checked={justificatif.sCochePPRRenouv === "X"}
                  disabled={!justificatif.bPPC}
                  name="sCochePPRRenouv"
                  onChange={onChange}
                >
                  Renouvellement annuel
                </Checkbox>
              </StyledCheckboxGroup>
            </WeightFormItem>

            <CheckboxForm layout="vertical">
              <ListBoldFormItem label="Type d'appareil de PPC :">
                <StyledCheckboxGroup>
                  <CheckboxList>
                    <CheckboxItem>
                      <Checkbox
                        checked={justificatif.sCochePPCModeAuto === "X"}
                        disabled={!justificatif.bPPC}
                        name="sCochePPCModeAuto"
                        onChange={onChange}
                      >
                        autopilotée
                      </Checkbox>
                    </CheckboxItem>

                    <CheckboxItem>
                      <Checkbox
                        checked={justificatif.sCochePPCModeFixe === "X"}
                        disabled={!justificatif.bPPC}
                        name="sCochePPCModeFixe"
                        onChange={onChange}
                      >
                        à pression fixe
                      </Checkbox>
                    </CheckboxItem>

                    <CheckboxItem>
                      <Checkbox
                        checked={justificatif.sCochePPCModeDouble === "X"}
                        disabled={!justificatif.bPPC}
                        name="sCochePPCModeDouble"
                        onChange={onChange}
                      >
                        à double niveau de pression
                      </Checkbox>
                    </CheckboxItem>
                  </CheckboxList>
                </StyledCheckboxGroup>
              </ListBoldFormItem>
            </CheckboxForm>

            <WeightFormItem label="Pression ou intervalle de pression (cm H2O) :">
              <Input
                value={justificatif.sReglagePression}
                disabled={!justificatif.bPPC}
                name="sReglagePression"
                onChange={onChange}
              />
            </WeightFormItem>

            <WeightFormItem label="Réglage particulier :">
              <Input
                value={justificatif.sReglageParticulier}
                disabled={!justificatif.bPPC}
                name="sReglageParticulier"
                onChange={onChange}
              />
            </WeightFormItem>

            <WeightFormItem label="Type d’interface :">
              <TextArea
                cols={115}
                value={justificatif.sLstInterface}
                disabled={!justificatif.bPPC}
                name="sLstInterface"
                onChange={onChange}
                rows={3}
                wrap={"hard"}
              />
            </WeightFormItem>

            <WeightFormItem label="Marque et modèle :">
              <Input
                value={justificatif.sDAPAppareil}
                disabled={!justificatif.bPPC}
                name="sDAPAppareil"
                onChange={onChange}
              />
            </WeightFormItem>
          </Form>
        </Section>

        <Section>
          <Icon>
            <SendOutlined />
          </Icon>

          <Form>
            <WeightFormItem>
              <Checkbox
                checked={justificatif.bOAM}
                name="bOAM"
                onChange={onChange}
              >
                Traitement par orthèse d’avancée mandibulaire (OAM)
              </Checkbox>
            </WeightFormItem>

            <WeightFormItem>
              <StyledCheckboxGroup>
                <Checkbox
                  checked={justificatif.bOAMExamen}
                  disabled={!justificatif.bOAM}
                  name="bOAMExamen"
                  onChange={onChange}
                >
                  Examen dentaire réalisé
                </Checkbox>

                <WeightFormItem label="date">
                  <DatePicker
                    value={
                      justificatif.dOAMDateExamen
                        ? moment(justificatif.dOAMDateExamen)
                        : undefined
                    }
                    disabled={!justificatif.bOAM}
                    placeholder="jj/mm/aaaa"
                    name="dOAMDateExamen"
                    format="DD/MM/YYYY"
                    locale={locale}
                    onChange={(momentDate) => {
                      if (
                        momentDate &&
                        prescription &&
                        prescription.oJustificatifs &&
                        prescription.oJustificatifs.dap
                      ) {
                        setPrescription({
                          ...prescription,
                          oJustificatifs: {
                            ...prescription.oJustificatifs,
                            dap: {
                              ...prescription.oJustificatifs.dap,
                              dOAMDateExamen: new Date(
                                momentDate.format("YYYY-MM-DD")
                              ),
                            },
                          },
                          bUpdated: true,
                        });
                      }
                    }}
                  />
                </WeightFormItem>
              </StyledCheckboxGroup>
            </WeightFormItem>

            <WeightFormItem>
              <StyledCheckboxGroup>
                <Checkbox
                  disabled={!justificatif.bOAM}
                  checked={justificatif.bOAMPrescriptionInitiale}
                  name="bOAMPrescriptionInitiale"
                  onChange={onChange}
                >
                  Prescription initiale
                </Checkbox>

                <Checkbox
                  disabled={!justificatif.bOAM}
                  checked={justificatif.bOAMRenouvellement}
                  name="bOAMRenouvellement"
                  onChange={onChange}
                >
                  Renouvellement
                </Checkbox>
              </StyledCheckboxGroup>
            </WeightFormItem>

            <WeightFormItem label="Marque et type de modèle :">
              <Input
                disabled={!justificatif.bOAM}
                value={justificatif.sOAMModele}
                name="sOAMModele"
                onChange={onChange}
              />
            </WeightFormItem>
          </Form>
        </Section>
      </Body>
    </>
  );
}
