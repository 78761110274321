import ModeleItem from "./ModeleItem";
import ModeleTexte from "../../../interfaces/modeleTexte.interface";

interface Props {
  modeles: ModeleTexte[];
  setModeles: React.Dispatch<React.SetStateAction<ModeleTexte[]>>;
}

const ModeleItemsWrapper = ({ modeles, setModeles }: Props) => {
  return (
    <>
      {modeles.map((modele: ModeleTexte) => (
        <ModeleItem
          key={modele.sLibelle}
          modele={modele}
          setModeles={setModeles}
        />
      ))}
    </>
  );
};

export default ModeleItemsWrapper;
