import { ChangeEvent } from "react";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import styled from "styled-components";

import { CheckboxGroupRowAlign } from "../elements/DEPinput.elements";
import { Title, Notice } from "../elements/sections.elements";
import AssureSection from "./sections/assureSection.elements";
import ControleMedicalSection from "./sections/controleMedicalSection.elements";
import DecisionOrganismeSection from "./sections/decisionOrganismeSection.elements";

import MedecinTraitantSection from "./sections/medecinTraitantSection.elements";

import { DatePicker } from "antd";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import moment from "moment";
import { IPrescriptionRenouvellement } from "../../../interfaces/prescription.interface";

const ACompterDe = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Body = styled.div`
  background-color: black;
  border-radius: 12px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterLeft = styled.div`
  font-size: 8px;
`;

const FooterRight = styled.div``;

const Header = styled.div`
  color: white;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

const MainHeader = styled.div`
  display: flex;
  height: max-content;
`;

const MainHeaderMiddle = styled.div`
  background-color: lightgray;
  border-left: 2px solid black;
  border-right: 2px solid black;
  flex-grow: 1;
  text-align: center;
`;

const MainHeaderSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95px;
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const StyledCheckGroup = styled(CheckboxGroupRowAlign)`
  justify-content: center;
`;

const Wrapper = styled.div`
  background-color: white;
  height: max-content;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 1050px;
`;

interface DEPProps {
  bDateSignature: boolean;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
  onChange: (
    event:
      | CheckboxChangeEvent
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export default function DEP({
  bDateSignature,
  onChange,
  prescription,
  setPrescription,
}: DEPProps) {
  const justificatif = prescription?.oJustificatifs?.dep;
  return (
    <Wrapper>
      <MainHeader>
        <MainHeaderSide>
          <span>DATE DE RÉCEPTION</span>
          <div>{justificatif?.sCodePatient}</div>
        </MainHeaderSide>

        <MainHeaderMiddle>
          <Title>
            DEMANDE D’ENTENTE PRÉALABLE POUR UN TRAITEMENT D’ASSISTANCE
            RESPIRATOIRE DE LONGUE DURÉE, A DOMICILE
          </Title>

          <>
            (Art. R 165-23 du Code de la Sécurité Sociale - arrêté du 30.08.89)
          </>

          <StyledCheckGroup>
            <Checkbox
              checked={justificatif?.sCochePresciInit === "X"}
              name="sCochePresciInit"
              onChange={onChange}
            >
              Demande initiale pour 3 mois (1)
            </Checkbox>

            <Checkbox
              checked={justificatif?.sCochePrescriProlong === "X"}
              name="sCochePrescriProlong"
              onChange={onChange}
            >
              Prolongation (1)
            </Checkbox>

            <Checkbox
              checked={justificatif?.sCochePrescriModif === "X"}
              name="sCochePrescriModif"
              onChange={onChange}
            >
              Prescription modifiée (1)
            </Checkbox>
          </StyledCheckGroup>

          <span>{justificatif?.sCodePatient}</span>
          <ACompterDe>
            A COMPTER DU :
            <DatePicker
              value={
                justificatif?.sDateDebut
                  ? moment(justificatif?.sDateDebut)
                  : undefined
              }
              name="sDateDebut"
              format="DD/MM/YYYY"
              locale={locale}
              onChange={(momentDate) => {
                setPrescription({
                  ...prescription,
                  oJustificatifs: {
                    ...prescription?.oJustificatifs,
                    dep: {
                      ...prescription?.oJustificatifs?.dep,
                      sDateDebut: momentDate?.format("YYYY-MM-DD"),
                    },
                  },
                  bUpdated: true,
                });
              }}
            />
            AU :
            <DatePicker
              value={
                justificatif?.sDateFin
                  ? moment(justificatif?.sDateFin)
                  : undefined
              }
              name="sDateFin"
              format="DD/MM/YYYY"
              locale={locale}
              onChange={(momentDate) => {
                setPrescription({
                  ...prescription,
                  oJustificatifs: {
                    ...prescription?.oJustificatifs,
                    dep: {
                      ...prescription?.oJustificatifs?.dep,
                      sDateFin: momentDate?.format("YYYY-MM-DD"),
                    },
                  },
                  bUpdated: true,
                });
              }}
            />
          </ACompterDe>
        </MainHeaderMiddle>

        <MainHeaderSide>
          <Title>VOLET 1</Title>
          <Notice>A CONSERVER PAR LE CONTRÔLE MÉDICAL</Notice>
        </MainHeaderSide>
      </MainHeader>

      <Body>
        <Header>A remplir par l'assuré(e) (2)</Header>

        <AssureSection
          prescription={prescription}
          setPrescription={setPrescription}
          justificatif={justificatif}
          onChange={onChange}
        />

        <Header>A remplir par le médecin traitant (2)</Header>

        <MedecinTraitantSection
          bDateSignature={bDateSignature}
          prescription={prescription}
          setPrescription={setPrescription}
          justificatif={justificatif}
          onChange={onChange}
        />

        <Section>
          <div>
            <Header>Avis du Contrôle Médical</Header>
            <ControleMedicalSection />
          </div>

          <div>
            <Header>Décision de l'organisme</Header>
            <DecisionOrganismeSection />
          </div>
        </Section>
      </Body>

      <Footer>
        <FooterLeft>
          <p>
            (1) Se reporter à la notice d’utilisation au verso du dernier volet
            <br />
            (2) Cocher la case de la réponse exacte
          </p>
        </FooterLeft>

        <FooterRight>C NAMTS 629 - 01/02</FooterRight>
      </Footer>
    </Wrapper>
  );
}
