import React, { FunctionComponent } from "react";
import styled from "styled-components";

const StyledTabsHeader = styled("div")`
  position: relative;
  z-index: 10; // To cover TabsContent
  display: inline-flex;
  gap: 15px;
  padding: 16px 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -30px 30px #0000000d;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

interface Props {
  children?: React.ReactNode;
}

const TabsHeader: FunctionComponent<Props> = ({ children }: Props) => (
  <StyledTabsHeader>{children}</StyledTabsHeader>
);

export default TabsHeader;
