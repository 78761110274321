import React from "react";
import { Switch, useHistory, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

export interface AnimatedSwitchProps {
  children: JSX.Element[];
}

export default function AnimatedSwitch(props: AnimatedSwitchProps) {
  const history = useHistory();
  const location = useLocation();

  return (
    <AnimatePresence
      // the exit animation needs the updated history to know if it's a push or pop
      custom={{ action: history.action }}
      exitBeforeEnter
      initial={false}
    >
      <Switch key={window.location.pathname} location={location}>
        {props.children}
      </Switch>
    </AnimatePresence>
  );
}
