import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Store } from "../../interfaces/store.interface";
import type { Config } from "../../interfaces/config.interface";

const initialState: Config = {
  bDateSignatureEditable: false,
  codeClient: "99",
  typesVoies: [],
  civilites: [],
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setConfig: (state, { payload }: PayloadAction<Config>) => {
      state.bDateSignatureEditable = payload.bDateSignatureEditable;
      state.codeClient = payload.codeClient;

      state.typesVoies = payload.typesVoies;
      state.civilites = payload.civilites;
    },
  },
});

export const { setConfig } = slice.actions;
export const selectDateSignatureEditable = (state: Store) =>
  state.config.bDateSignatureEditable;
export const selectCodeClient = (state: Store) => state.config.codeClient;

export const selectTypesVoies = (state: Store) => state.config.typesVoies;
export const selectCivilites = (state: Store) => state.config.civilites;

export default slice.reducer;
