import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@ads/ui";
import {
  selectContentModal,
  toggleModal,
} from "../../../redux/modal/modal.slice";
import ModalDocument from "../ModalDocument";
import CheckDocument from "../../atoms/buttons/CheckDocument";
import ModalConfirm from "../ModalConfirm";

import { HeaderProps } from "./headerInterface";
import {
  CustomButon,
  CustomNavLink,
  Header,
  NavWrapper,
  WrapperChild,
} from "./header.elements";

export const HeaderRenouvellement: FunctionComponent<HeaderProps> = ({
  iPKPrescription,
  send,
  isLoading,
  tabOrdonnance,
  ordonnances,
  remove,
  save,
  addToBasket,
  prescription,
}) => {
  const dispatch = useDispatch();
  const [canSave, setCanSave] = useState<null | boolean>(null);
  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);

  const handleClick = (value: string) => {
    if (tabOrdonnance.length < ordonnances.length) {
      setContentModal(
        <ModalDocument
          tabOrdonnance={tabOrdonnance}
          ongletUnchecked={ordonnances[ordonnances.length - 1]}
        />
      );
      dispatch(toggleModal());
    } else {
      if (value === "signer") {
        setContentModal(
          <ModalConfirm
            dDateSignature={prescription?.dDateSignature}
            dDebut={prescription?.dDebut}
            sendContent={send}
            messageGlobal={"La prescription va être signée et envoyée."}
          />
        );
        dispatch(toggleModal());
      } else if (value === "ajouterAuPanier") {
        addToBasket && addToBasket(iPKPrescription);
        dispatch(toggleModal());
      }
    }
  };

  useEffect(() => {
    if (prescription?.bUpdated) setCanSave(true);
  }, [prescription]);

  return (
    <Header>
      <NavWrapper className="nav-wrapper">
        <WrapperChild className="retour">
          <CustomNavLink className="back" exact to="/">
            <Text
              label="Retour"
              sColor={{ style: "neutral", variant: 190 }}
              fontWeight="600"
            />
          </CustomNavLink>
        </WrapperChild>

        <WrapperChild className="renouvellement">
          <Text
            label={`Renouvellement N°${iPKPrescription}`}
            sColor={{ style: "neutral", variant: 190 }}
            fontWeight="600"
          />
        </WrapperChild>
        <WrapperChild className="nav">
          <div style={{ display: "flex" }}>
            <CustomNavLink
              onClick={() => {
                if (remove) remove();
              }}
              className="reset"
              to="#"
            >
              <Text
                label="Réinitialiser"
                sColor={{ style: "neutral", variant: 190 }}
                fontWeight="600"
              />
            </CustomNavLink>
          </div>

          <CustomButon
            disabled={!canSave}
            onClick={() => {
              save && save();
              setCanSave(false);
            }}
            className="save"
          >
            <Text
              label="Sauvegarder"
              sColor={{ style: "neutral", variant: 190 }}
              fontWeight="600"
            />
          </CustomButon>
          <div style={{ display: "flex" }}>
            <CheckDocument
              itemSelected={tabOrdonnance.length}
              ordonnances={ordonnances}
              handleClick={(value) => handleClick(value)}
              isLoading={isLoading}
            />
          </div>
        </WrapperChild>
      </NavWrapper>
    </Header>
  );
};
