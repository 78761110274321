import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";

import {
  BarcodeDiv as BarcodesDiv,
  CerfaInfo,
  IdentificationDiv as IdentificationPatient,
  IdentificationDiv as IdentificationPrescripteur,
  IdentificationDivTitle,
} from "../ordonnance.style";
import type { IOrdonnancePPCRenouvellement } from "../../../../interfaces/ordonnance.interface";
import { displayFormatImmatriculation } from "../../../../utils/display";
import Barcode from "../barcode.elements";

const AmelieNumberDiv = styled.div``;

const BirthDate = styled(FormItem)`
  margin-top: 20px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
`;

const RppsNumberDiv = styled.div``;

type HeaderProps = Pick<
  IOrdonnancePPCRenouvellement,
  | "dDateNaissancePatient"
  | "sAdeliPrescripteur"
  | "sAdressePrescripteur"
  | "sAdressePatient"
  | "sCiviliteNomPrenomPatient"
  | "sCiviliteNomPrenomPrescripteur"
  | "sNoSecuriteSociale"
  | "sRPPSPrescripteur"
>;

export function HeaderAdene({ ...ordonnance }: HeaderProps) {
  return (
    <Content>
      <IdentificationPrescripteur>
        <IdentificationDivTitle>
          Identification du prescripteur
        </IdentificationDivTitle>

        {ordonnance.sCiviliteNomPrenomPrescripteur}

        <p>{ordonnance.sAdressePrescripteur}</p>

        <BarcodesDiv>
          {ordonnance.sRPPSPrescripteur && (
            <RppsNumberDiv>
              <span style={{ marginLeft: 10 }}>N° RPPS</span>
              <Barcode value={ordonnance.sRPPSPrescripteur} />
            </RppsNumberDiv>
          )}

          {ordonnance.sAdeliPrescripteur && (
            <AmelieNumberDiv>
              <span style={{ marginLeft: 10 }}>N° AM</span>
              <Barcode value={ordonnance.sAdeliPrescripteur} />
            </AmelieNumberDiv>
          )}
        </BarcodesDiv>
      </IdentificationPrescripteur>

      <CerfaInfo />

      <IdentificationPatient>
        {ordonnance.sCiviliteNomPrenomPatient}

        <BirthDate label="Né(e) le">
          {ordonnance.dDateNaissancePatient &&
            new Date(ordonnance.dDateNaissancePatient).toLocaleDateString()}
        </BirthDate>

        <FormItem label="N° S.S">
          {displayFormatImmatriculation(ordonnance.sNoSecuriteSociale)}
        </FormItem>
      </IdentificationPatient>
    </Content>
  );
}

export function HeaderAlair({ ...ordonnance }: HeaderProps) {
  return (
    <Content>
      <IdentificationPrescripteur>
        <IdentificationDivTitle>
          Identification du prescripteur
        </IdentificationDivTitle>

        {ordonnance.sCiviliteNomPrenomPrescripteur}

        <p>{ordonnance.sAdressePrescripteur}</p>

        <BarcodesDiv>
          {ordonnance.sRPPSPrescripteur && (
            <RppsNumberDiv>
              <span style={{ marginLeft: 10 }}>N° RPPS</span>
              <Barcode value={ordonnance.sRPPSPrescripteur} />
            </RppsNumberDiv>
          )}

          {ordonnance.sAdeliPrescripteur && (
            <AmelieNumberDiv>
              <span style={{ marginLeft: 10 }}>N° AM</span>
              <Barcode value={ordonnance.sAdeliPrescripteur} />
            </AmelieNumberDiv>
          )}
        </BarcodesDiv>
      </IdentificationPrescripteur>

      <CerfaInfo />

      <IdentificationPatient>
        {ordonnance.sCiviliteNomPrenomPatient}

        <BirthDate label="Né(e) le">
          {ordonnance.dDateNaissancePatient &&
            new Date(ordonnance.dDateNaissancePatient).toLocaleDateString()}
        </BirthDate>
        <p>{ordonnance.sAdressePatient}</p>
      </IdentificationPatient>
    </Content>
  );
}
