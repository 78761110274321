import { Checkbox } from "antd";
import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";

export const CheckboxItem = styled.li`
  &:before {
    content: "-  ";
    padding-right: 20px;
  }
`;

export const CheckboxList = styled.ul`
  grid-row: 3;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 50px;
`;

export const Indication = styled.div`
  font-size: 10px;
  font-style: italic;
`;

export const ListBoldFormItem = styled(FormItem)`
  font-weight: 700;
`;

export const StyledCheckbox = styled(Checkbox)`
  display: inline-flex;
  margin: 2px 0px 2px 0px;

  & > .ant-checkbox {
    order: 1;
    top: 0.2em;
  }
`;

export const StyledCheckboxGroup = styled.div`
  display: flex;
  min-height: 32px;
  position: relative;
`;

export const StyledFormItem = styled(FormItem)`
  border: 1px solid lightgray;
  height: min-content;
  padding: 5px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledInput = styled.div`
  .ant-form-item-label {
    font-weight: 700;
    padding-bottom: 0px;
  }

  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
`;

export const StyledInputSection = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  text-align: left;

  & > .ant-form > .ant-form-item > .ant-form-item-label {
    font-weight: 700;
    padding-bottom: 0px;
    width: 100%;
    text-align: left;
  }

  .ant-item {
    background-color: #40a9ff1a !important;
  }
`;

export const WeightFormItem = styled(FormItem)`
  font-weight: 700;
  height: min-content;

  .ant-checkbox-group {
    display: inline-flex;
  }

  .ant-checkbox-wrapper {
    display: inline-flex;
    padding-right: 80px;

    & > .ant-checkbox {
      order: 1;
    }

    .ant-checkbox + span {
      padding-left: 0px;
      padding-right: 0px;
    }

    .ant-checkbox {
      padding-left: 8px;
      padding-right: 8px;
      top: 0.2em;
    }
  }

  .ant-form-item-control-input {
    min-height: unset;
  }
`;
