import styled from "styled-components";
import back from "../../../assets/icons/back.svg";
import reset from "../../../assets/icons/reset.svg";
import warning from "../../../assets/icons/delete.svg";
import save from "../../../assets/icons/save.svg";
import { NavLink } from "react-router-dom";

export const Header = styled.div`
  z-index: 50;
  position: fixed;
  top: 110px;
  display: flex;
  width: 100%;
  height: 75px;
  padding: 0 20px 0 20px;
  background: ${(props) => props.theme.colors.neutral[120]};
  p {
    margin: 0;
    padding: 0;
  }
`;

export const NavWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const WrapperChild = styled("div")`
  width: 100%;
  display: flex;
  &.renouvellement {
    justify-content: center;
    width: 33%;
  }

  &.nav {
    justify-content: flex-end;
    gap: 20px;
  }

  @media screen and (max-width: 1790px) {
    width: auto;
  }
`;

export const CustomNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 25px;
  gap: 10px;
  border-radius: 20px;
  font-weight: 400;
  transition: all 0.2s linear;
  &:hover {
    background-color: ${(props) => props.theme.colors.neutral[140]};
    cursor: pointer;
  }

  &.back {
    &:before {
      content: "";
      display: block;
      background-image: url(${back});
      width: 20px;
      height: 16px;
    }
  }
  &.save {
    &:before {
      content: "";
      display: block;
      background-image: url(${save});
      width: 22px;
      height: 22px;
    }
  }
  &.reset {
    &:before {
      content: "";
      display: block;
      background-image: url(${reset});
      width: 18px;
      height: 16px;
    }
  }
  &.delete {
    &:before {
      content: "";
      display: block;
      background-image: url(${warning});
      width: 18px;
      height: 16px;
    }
  }
`;

export const CustomButon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 25px;
  gap: 10px;
  border-radius: 20px;
  font-weight: 400;
  transition: all 0.2s linear;
  border: none;
  &:disabled {
    opacity: 0.75;
    background-color: ${(props) => props.theme.colors.neutral[140]};
    cursor: not-allowed !important;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.neutral[140]};
    cursor: pointer;
  }

  &.back {
    &:before {
      content: "";
      display: block;
      background-image: url(${back});
      width: 20px;
      height: 16px;
    }
  }
  &.save {
    &:before {
      content: "";
      display: block;
      background-image: url(${save});
      width: 22px;
      height: 22px;
    }
  }
  &.reset {
    &:before {
      content: "";
      display: block;
      background-image: url(${reset});
      width: 18px;
      height: 16px;
    }
  }
  &.delete {
    background-color: "#d60f0f";
    &:before {
      content: "";
      display: block;
      background-image: url(${warning});
      width: 25px;
      height: 36px;
    }
  }
`;
