import { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import TextIcon from "../atoms/text/TextIcon";
import { Upload, Form, Popconfirm } from "antd";
import QRCode from "react-qr-code";
// Slices
import { toggleModal } from "../../redux/modal/modal.slice";
import { setMedecinInfos } from "../../redux/utilisateur/utilisateur.slice";
import { deleteSignature, upsertSignature } from "../../api/signature.api";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { PrimaryButton } from "@ads/ui";

import { notification } from "antd";

import { getMedecin } from "../../api/medecins.api";
import { getTotp } from "../../api/totp";
import Medecin from "../../interfaces/medecin.interface";
import {
  ButtonCancel,
  ButtonDelete,
} from "../core/layout/button/button.elements";
import BlackModal from "../atoms/modals/BlackModal";

const WrapperTitle = styled("div")`
  margin-top: 55px;
`;

const WrapperForm = styled("div")`
  padding: 55px 150px;
`;

const WrapperFormItems = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .ant-upload-list-item-card-actions .anticon {
    color: ${(props) => props.theme.colors.neutral[100]};
  }
  .ant-upload-list-picture .ant-upload-list-item {
    color: ${(props) => props.theme.colors.neutral[100]};
  }

  .ant-upload.ant-upload-drag {
    background: none;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: ${(props) => props.theme.colors.neutral[100]};
    padding: 0 10px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: ${(props) => props.theme.colors.neutral[130]};
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: ${(props) => props.theme.colors.secondary[100]};
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${(props) => props.theme.colors.secondary[100]};
  }
`;

const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 55px 0 0 0;
`;

interface PropsFormSignature {
  medecin?: Medecin;
}

const FormSignature = ({ medecin }: PropsFormSignature) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();

  const [signatureUrl, setSignatureUrl] = useState("");

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleModal());
  };

  const getSignatureUrl = useCallback(async () => {
    try {
      if (medecin) {
        const { code } = await getTotp(medecin.iFKAnnuaire);

        setSignatureUrl(
          `${window.location.origin}/signature-manuelle/${medecin.iFKAnnuaire}/${code}`
        );
      }
    } catch (error: any) {
      notification.error({
        description: "Veuillez contacter votre administrateur",
        message: "Echec du chargement du lien sécurisé",
      });
    }
  }, [medecin]);

  useEffect(() => {
    getSignatureUrl();
  }, [getSignatureUrl]);

  const handleFileInput = (file: File) => {
    setSelectedFile(file);
    return false;
  };

  const upsertMedecinSignature = useCallback(
    async (newSignature: string) => {
      try {
        if (!medecin) return;
        await upsertSignature(medecin.iFKAnnuaire, newSignature);
        dispatch(
          setMedecinInfos({
            medecinInfos: { ...medecin, sSignature: newSignature },
          })
        );
        notification.success({ message: "Signature sauvegardée" });
        dispatch(toggleModal());
      } catch (error: any) {
        notification.error({ message: error.message });
        dispatch(toggleModal());
      }
    },
    [dispatch, medecin]
  );

  const deleteMedecinSignature = useCallback(async () => {
    try {
      if (!medecin) return;
      await deleteSignature(medecin.iFKAnnuaire);
      dispatch(
        setMedecinInfos({
          medecinInfos: { ...medecin, sSignature: undefined },
        })
      );
      notification.success({ message: "Signature supprimée" });
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  }, [dispatch, medecin]);

  const saveSignature = async () => {
    if (!selectedFile) {
      dispatch(toggleModal());
    }
    const reader = new FileReader();

    reader.readAsDataURL(selectedFile!);
    reader.onerror = (error) => {
      console.error(error);
    };
    reader.onload = async () => {
      setIsSubmiting(true);
      try {
        await upsertMedecinSignature(reader.result as string);
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        // Close modal with dispatch
        setIsSubmiting(false);
      }
    };
  };

  const Btns: any[] = [];

  const handleClose = async () => {
    try {
      if (medecin) {
        const { sSignature } = await getMedecin(medecin.iFKAnnuaire);
        if (sSignature !== medecin.sSignature)
          dispatch(
            setMedecinInfos({ medecinInfos: { ...medecin, sSignature } })
          );
      }
    } catch (error) {
      console.error(error);
      notification.error({
        description: `Nous rencontrons une erreur lors de l'actualisation de l'état de votre signature, veuillez contacter votre administrateur`,
        message: `Echec de l'actualisation de l'état de votre signature`,
      });
    }
    // Toggle modal
  };

  if (medecin?.sSignature && medecin?.sSignature.length > 0) {
    Btns.push(
      <ButtonCancel onClick={handleClose}>
        <CloseOutlined />
        Annuler
      </ButtonCancel>
    );

    Btns.push(
      <Popconfirm
        cancelText="Annuler"
        okButtonProps={{ danger: true }}
        okText="Confirmer"
        onConfirm={() => {
          deleteMedecinSignature();
          // Toggle Modal
        }}
        title="Vous allez supprimer votre signature"
      >
        <ButtonDelete>
          <DeleteOutlined />
          Supprimer
        </ButtonDelete>
      </Popconfirm>
    );
  }

  return (
    <BlackModal>
      <Form name="validate_other" onFinish={saveSignature}>
        <WrapperTitle>
          <TextIcon
            size="medium"
            color="white"
            label="Modifier ma signature"
            icon="signature"
            center
          />
        </WrapperTitle>

        <WrapperForm>
          <WrapperFormItems>
            <QRCode value={signatureUrl} />
            <Form.Item
              getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
              name="dragger"
              noStyle
              valuePropName="fileList"
            >
              <Upload.Dragger
                accept="image/*"
                beforeUpload={handleFileInput}
                listType="picture"
                multiple={false}
                maxCount={1}
                name="signature"
                onRemove={() => setSelectedFile(undefined)}
              >
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  Cliquez ou déposez votre signature ici
                </p>
                <p className="ant-upload-hint">Scan ou photo attendue</p>
              </Upload.Dragger>
            </Form.Item>
          </WrapperFormItems>
          <WrapperButtons>
            <PrimaryButton
              label="Annuler"
              as="button"
              size="S"
              buttonStyle="danger"
              type="reset"
              minWidth="157px"
              onClick={handleCancel}
            />
            <PrimaryButton
              as="button"
              label={isSubmiting ? "Envois en cours" : "Valider"}
              minWidth="157px"
              size="S"
              buttonStyle={isSubmiting ? "neutral" : "primary"}
              type="submit"
              //disabled={isSubmiting || !selectedFile}
            />
          </WrapperButtons>
        </WrapperForm>
      </Form>
    </BlackModal>
  );
};

export default FormSignature;
