import styled from "styled-components";
import FormItem from "antd/lib/form/FormItem";

const FormSelectWrapper = styled(FormItem)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  // Style Label
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-label > label {
    color: ${(props) => props.theme.colors.neutral[100]};
  }
  // Style Select
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: ${(props) => props.theme.colors.neutral[170]};
    color: ${(props) => props.theme.colors.neutral[100]};
    min-height: 50px;
    border-radius: 10px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    border-radius: 13px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
  }

  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    color: ${(props) => props.theme.colors.neutral[130]};
  }

  .ant-select-arrow {
    color: ${(props) => props.theme.colors.neutral[130]};
  }
  .ant-select-clear {
    background: ${(props) => props.theme.colors.neutral[140]};
  }

  .ant-select-multiple .ant-select-selection-item {
    background: ${(props) => props.theme.colors.neutral[160]};
    border-color: ${(props) => props.theme.colors.neutral[160]};
    height: 30px;
    line-height: 30px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${(props) => props.theme.colors.neutral[100]};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: 0 0 0 1px rgb(255 255 255);
  }

  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 50px;
  }
`;

export default FormSelectWrapper;
