import styled from "styled-components";
import { motion } from "framer-motion";

export const Header = styled(motion.div)`
  width: 100%;
  opacity: 1;
`;

export const Wrapper = styled(motion.div)`
  position: relative;
  top: 185px;
  width: 100%;
`;

export const WrapperContent = styled("div")`
  position: relative;
  z-index: 70;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.neutral[110]} 0% 0% no-repeat
    padding-box;
  border-radius: 20px;
`;

export const Content = styled(motion.div)`
  width: 1530px;
  max-width: 100%;
  margin: 0 auto;
  opacity: 1;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  color: #3a3d4b;
  padding: 20px;
`;

export const WrapperPrescriptions = styled("div")`
  width: 1530px;
  max-width: 100%;
  margin: 0 auto;
`;

export const WrapperTitle = styled("div")`
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1530px) {
    margin: 0 0 0 12px;
  }
  //display: flex;
`;

export const WrapperElem = styled("div")`
  display: inline-block;
  margin: 10px;
`;

export const WrapperTable = styled.div`
  position: relative;
`;

export const WrapperSignButton = styled.div`
  text-align: right;
`;

export const WrapperContentLeft = styled.div`
  display: flex;
  align-items: center;
`;