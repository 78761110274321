import { Form } from "antd";
import type { ChangeEvent as ReactChangeEvent } from "react";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";
import { DatePicker } from "antd";
import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import moment from "moment";
import { StyledInput } from "../elements/input.elements";
import AvisSection from "./sections/avisSection.elements";
import ConditionSection from "./sections/conditionSection.elements";
import ElementsSection from "./sections/elementsSections.elements";
import type IDAP from "../../../interfaces/dap.interface";
import IdentificationSection from "./sections/identificationSection.elements";
import PersonSection from "./sections/personSection.elements";
import TraitementSection from "./sections/traitementSection.elements";
import { IPrescriptionRenouvellement } from "../../../interfaces/prescription.interface";

const Body = styled.div``;

const Code = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Consigne = styled.div`
  border: 1px solid black;
  font-style: italic;
  font-weight: 700;
  height: max-content;
`;

const Consignes = styled.h2`
  text-align: center;
`;

const Footer = styled.div`
  font-weight: 700;
  text-align: end;
`;

const Head = styled.div`
  line-height: 1.1;
`;

const RefArticle = styled.h3`
  font-weight: 700;
  text-align: center;
`;

const SubHead = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  color: grey;
  text-align: center;
`;

const Wrapper = styled.div`
  background-color: white;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  width: 850px;

  * {
    font-family: "Times new Roman";
  }
`;

interface DAPProps {
  bDateSignature: boolean;
  justificatif: IDAP;
  onChange: (e: ChangeEvent) => void;
  prescription: IPrescriptionRenouvellement | undefined;
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescriptionRenouvellement | undefined>
  >;
}

type ChangeEvent =
  | CheckboxChangeEvent
  | ReactChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export default function DAP({
  bDateSignature,
  prescription,
  setPrescription,
  justificatif,
  onChange,
}: DAPProps) {
  return (
    <>
      <Wrapper>
        <Code>
          <span>{justificatif.iPKprescription}</span>
          <span>{justificatif.sCodePatient}</span>
        </Code>

        <Head>
          <Title>
            Prescription médicale valant demande d'accord préalable pour le
            traitement du syndrome d'apnées hypopnées du sommeil chez les
            patients de plus de 16 ans
          </Title>

          <Consignes>
            Volet 1 à adresser au contrôle médical, sous enveloppe, à
            l'attention de "M. le Médecin-Conseil"
          </Consignes>

          <RefArticle>
            Article R. 165-1 et . 165-23 du Code de la sécurité sociale
          </RefArticle>
        </Head>

        <SubHead>
          <Consigne>
            Veuillez compléter la rubrique relative aux éléments d'ordre médical
            qui figure au verso de ce volet
          </Consigne>

          <StyledInput>
            <Form layout="vertical">
              <FormItem label="date de réception">
                <DatePicker
                  value={
                    justificatif.dDateReception
                      ? moment(justificatif.dDateReception)
                      : undefined
                  }
                  placeholder="jj/mm/aaaa"
                  name="dDateReception"
                  format="DD/MM/YYYY"
                  locale={locale}
                  onChange={(momentDate) => {
                    if (
                      prescription &&
                      prescription!.oJustificatifs &&
                      prescription!.oJustificatifs!.dap &&
                      momentDate
                    ) {
                      setPrescription({
                        ...prescription,
                        oJustificatifs: {
                          ...prescription.oJustificatifs,
                          dap: {
                            ...prescription.oJustificatifs.dap,
                            dDateReception: new Date(
                              momentDate.format("YYYY-MM-DD")
                            ),
                          },
                        },
                        bUpdated: true,
                      });
                    }
                  }}
                />
              </FormItem>
            </Form>
          </StyledInput>
        </SubHead>

        <Body>
          <PersonSection
            onChange={onChange}
            prescription={prescription}
            setPrescription={setPrescription}
            justificatif={justificatif}
          />

          <ConditionSection onChange={onChange} justificatif={justificatif} />

          <TraitementSection
            onChange={onChange}
            prescription={prescription}
            setPrescription={setPrescription}
            justificatif={justificatif}
          />

          <IdentificationSection
            bDateSignature={bDateSignature}
            prescription={prescription}
            onChange={onChange}
            justificatif={justificatif}
            setPrescription={setPrescription}
          />
          <AvisSection />
        </Body>

        <Footer>Réf. 634 CNAM juin 2018</Footer>
      </Wrapper>

      <Wrapper>
        <Code>
          <span>{justificatif.iPKprescription}</span>
          <span>{justificatif.sCodePatient}</span>
        </Code>

        <Body>
          <ElementsSection onChange={onChange} justificatif={justificatif} />
        </Body>

        <Footer>Réf. 634 CNAM juin 2018</Footer>
      </Wrapper>
    </>
  );
}
