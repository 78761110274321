import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectMedecinInfos } from "../../../redux/utilisateur/utilisateur.slice";
import {
  NavLogo,
  NavItem,
  NavWrapper,
  CustomNavLink,
  Logout,
} from "../../atoms/navigation/navigation.element";

const logo = require(`../../../assets/logo-${process.env.REACT_APP_THEME}.${process.env.REACT_APP_LOGO_FORMAT}`);

const ImgLogo = styled.img`
  ${parseInt(process.env.REACT_APP_ADD_LOGO_WHITE_BOX || "", 10) === 1
    ? "height: 55px;"
    : ""}
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Navigation: FunctionComponent = () => {
  const medecinInfos: any = useSelector(selectMedecinInfos);

  const deconnecte = () => {
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/utilisateur/deconnexion";
  };

  return (
    <Nav>
      <NavLogo to="/">
        <ImgLogo src={logo.default} alt="logo" />
      </NavLogo>
      <NavWrapper>
        <NavItem>
          <CustomNavLink className="dashboard" exact to="/">
            Tableau de bord
          </CustomNavLink>
        </NavItem>
        {medecinInfos && (
          <NavItem>
            <CustomNavLink className="profil" exact to="/mes-informations">
              {`${medecinInfos?.sCodeCivilite} ${medecinInfos?.sNom} ${medecinInfos?.sPrenom}`}
            </CustomNavLink>
          </NavItem>
        )}
        <NavItem>
          <Logout
            exact
            to="/utilisateur/deconnexion"
            onClick={() => deconnecte()}
          />
        </NavItem>
      </NavWrapper>
    </Nav>
  );
};
