import type { ChangeEvent as ReactChangeEvent, KeyboardEvent } from "react";
import { useEffect, useRef } from "react";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type { TextAreaRef } from "antd/lib/input/TextArea";
import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";

import type { IOrdonnancePPCRenouvellement } from "../../../../../interfaces/ordonnance.interface";
import { SectionHeader } from "../sectionHeader.elements";
import { TextArea } from "../../ordonnance.style";

const Content = styled.div`
  margin-bottom: 20px;
`;

interface TelesuiviSectionProps {
  onChange: (event: ChangeEvent) => void;
  ordonnance: IOrdonnancePPCRenouvellement;
  resetSelectedModele: () => void;
  selectedModele?: string;
}

export type ChangeEvent =
  | CheckboxChangeEvent
  | ReactChangeEvent<HTMLTextAreaElement>;

export default function TelesuiviSection({
  onChange,
  ordonnance,
  resetSelectedModele,
  selectedModele,
}: TelesuiviSectionProps) {
  const textAreaEl = useRef<TextAreaRef>(null);

  const handleTab = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { textArea } = textAreaEl.current!.resizableTextArea!;

    if (e.key === "Tab") {
      const { selectionStart, selectionEnd } = textArea;
      const contentAfter = textArea.value.slice(selectionEnd);
      const contentBefore = textArea.value.slice(0, selectionStart);
      const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        "value"
      )?.set;

      e.preventDefault();

      // React library overrides input value setter
      nativeTextAreaValueSetter?.call(
        textArea,
        `${contentBefore}\t${contentAfter}`
      );
      textArea.dispatchEvent(new Event("input", { bubbles: true }));

      textArea.selectionEnd = selectionStart + 1;
      textArea.selectionStart = selectionStart + 1;
    }
  };

  useEffect(() => {
    const textArea = textAreaEl.current?.resizableTextArea?.textArea;

    if (!selectedModele || !textArea) return;

    const { selectionStart, selectionEnd } = textArea;
    const contentAfter = textArea.value.slice(selectionEnd);
    const contentBefore = textArea.value.slice(0, selectionStart);
    const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      "value"
    )?.set;

    textAreaEl.current!.focus();

    // React library overrides input value setter
    nativeTextAreaValueSetter?.call(
      textArea,
      `${contentBefore}\n${selectedModele}\n${contentAfter}`
    );
    textArea.dispatchEvent(new Event("input", { bubbles: true }));

    textArea.selectionEnd = contentBefore.length + selectedModele.length + 2;
    textArea.selectionStart = selectionStart;

    resetSelectedModele();
  }, [selectedModele, resetSelectedModele]);

  return (
    <Content>
      <SectionHeader>Conditions du Télésuivi</SectionHeader>
      <FormItem>
        <Checkbox
          checked={ordonnance.bObservanceJO}
          name="bObservanceJO"
          onChange={onChange}
        >
          Observance selon modalités précisées au JO
        </Checkbox>
      </FormItem>
      <FormItem>
        <Checkbox
          checked={ordonnance.bTelesuiviSFRMS}
          name="bTelesuiviSFRMS"
          onChange={onChange}
        >
          Télésuivi selon l'algorithme proposé par la SFRMS
        </Checkbox>
      </FormItem>
      Autre&nbsp;:
      <TextArea
        name="sAutre"
        onChange={onChange}
        onKeyDown={handleTab}
        rows={25}
        ref={textAreaEl}
        value={ordonnance.sAutre}
      />
    </Content>
  );
}
