import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PrimaryButton } from "@ads/ui";
import RenouvellementMultiple from "../../pages/prescription/service/renouvellement.multi.service";
import RenouvellementMultipleClassique from "../../pages/prescription/service/renouvellement.multi.classique.service";
import { selectUtilisateur } from "../../redux/utilisateur/utilisateur.slice";
import config from "../../config";
import {
  getPrescriptionAdvancedProgress,
  getPrescriptionAdvancedProgressClassicMulti,
} from "../../api/prescriptions.api";
import ModalProgress from "./ModalProgress";
import { selectContentModal, toggleModal } from "../../redux/modal/modal.slice";
import { notification } from "antd";

/* ------ BOUTON POUR SIGNER TOUTES LES PRESCRIPTIONS SELECTIONNEES (AFFICHAGE DANS LE WRAPPER TITLE DE LA TABLE EN HAUT A DROITE) ----- */

const ButtonWrapper = styled.div`
  text-align: right;
  margin-right: 30px;
  display: ${(props) => props.className};
`;

interface Props {
  selectedIPKPrescriptions: any;
}

export default function SignAllButton({ selectedIPKPrescriptions }: Props) {
  let statusShow: string = "";
  let percentProgress: number = 0;
  const identity = useSelector(selectUtilisateur); // identité utilisateur courant

  // Permet d'afficher la modal (progress bar)
  const dispatch = useDispatch();
  const setContentModal: (param: JSX.Element) => void =
    useSelector(selectContentModal);

  // Renouvellement multiple par création de bundle (Oodrive)
  const handleClickRenouvellementMultiple = () => {
    console.log("LCIKC");

    let notificationPayload = {
      description:
        "Les renouvellements ont bien été effectué, ils sont en attente de finalisation",
      message: "Succès !",
    };
    if (config.oodriveSignatureActivated) {
      RenouvellementMultiple(selectedIPKPrescriptions, identity?.iFKAnnuaire)
        .then((response) => {})
        .catch((error) => {});

      // Conversion des ipks en tableau d'identifiants simples -->[iPid]
      let presciptionsIds: number[] = [];
      for (let prescription of selectedIPKPrescriptions) {
        let res = JSON.stringify(prescription);
        presciptionsIds.push(JSON.parse(res)["iPKPrescription"]);
      }

      let actualHandledNbPrescriptions = 0;
      checkAdvancedProgress(
        presciptionsIds,
        identity?.iFKAnnuaire,
        actualHandledNbPrescriptions
      );
    } else if (config.signatureClassiqueMultiple) {
      // Renouvellement multiple classique
      RenouvellementMultipleClassique(
        selectedIPKPrescriptions,
        identity?.iFKAnnuaire
      )
        .then((response) => {})
        .catch((error) => {});

      // Conversion des ipks en tableau d'identifiants simples -->[iPid]
      let presciptionsIds: number[] = [];
      for (let prescription of selectedIPKPrescriptions) {
        let res = JSON.stringify(prescription);
        presciptionsIds.push(JSON.parse(res)["iPKPrescription"]);
      }
      notification.success(notificationPayload);
      let actualHandledNbPrescriptions = 0;
      checkAdvancedProgressForClassicMulti(
        presciptionsIds,
        identity?.iFKAnnuaire,
        actualHandledNbPrescriptions
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  // Envoi une requête d'état des prescriptions traitées toute les 2 secondes et met à jour les données
  const checkAdvancedProgress = (
    selectedIPKPrescriptions: any,
    iFKAnnuaire: any,
    actualHandledNbPrescriptions: number
  ) => {
    statusShow =
      actualHandledNbPrescriptions +
      " prescriptions sur " +
      selectedIPKPrescriptions.length +
      " traitée(s)";
    setTimeout(() => {
      getPrescriptionAdvancedProgress(
        iFKAnnuaire,
        selectedIPKPrescriptions
      ).then((resp) => {
        if (resp.data["nbPrescriptions"] !== undefined) {
          actualHandledNbPrescriptions = parseInt(resp.data["nbPrescriptions"]);
        }

        if (
          actualHandledNbPrescriptions <=
          parseInt(selectedIPKPrescriptions.length)
        ) {
          checkAdvancedProgress(
            selectedIPKPrescriptions,
            iFKAnnuaire,
            actualHandledNbPrescriptions
          );
          percentProgress = convertProgressToPercentBar(
            actualHandledNbPrescriptions,
            parseInt(selectedIPKPrescriptions.length)
          );
        }

        if (
          actualHandledNbPrescriptions ===
          parseInt(selectedIPKPrescriptions.length)
        ) {
          statusShow =
            "Vous allez être redirigé vers Sell And Sign pour finaliser votre signature";
        }
      });
    }, 2000);
    return false;
  };

  const checkAdvancedProgressForClassicMulti = (
    selectedIPKPrescriptions: any,
    iFKAnnuaire: any,
    actualHandledNbPrescriptions: number
  ) => {
    statusShow =
      actualHandledNbPrescriptions +
      " prescriptions sur " +
      selectedIPKPrescriptions.length +
      " traitée(s)";
    setTimeout(() => {
      getPrescriptionAdvancedProgressClassicMulti(
        iFKAnnuaire,
        selectedIPKPrescriptions
      ).then((resp) => {
        if (resp.data["nbPrescriptions"] !== undefined) {
          actualHandledNbPrescriptions = parseInt(resp.data["nbPrescriptions"]);
        }

        if (
          actualHandledNbPrescriptions <=
          parseInt(selectedIPKPrescriptions.length)
        ) {
          checkAdvancedProgressForClassicMulti(
            selectedIPKPrescriptions,
            iFKAnnuaire,
            actualHandledNbPrescriptions
          );
          percentProgress = convertProgressToPercentBarClassicMulti(
            actualHandledNbPrescriptions,
            parseInt(selectedIPKPrescriptions.length)
          );
        }

        if (
          actualHandledNbPrescriptions ===
          parseInt(selectedIPKPrescriptions.length)
        ) {
          statusShow = "Les documents sont finalisés, rechargement de la page";
        }
      });
    }, 2000);
    return false;
  };

  const getUpdatedStatus = () => {
    return statusShow;
  };

  const getUpdatedProgress = () => {
    // Correction d'un petit bug d'affichage des valeurs en pourcent
    // force l'affichage des 2 premiers chiffres quand < 100...
    if (percentProgress < 100) {
      return percentProgress.toString().substring(0, 2);
    } else {
      return percentProgress;
    }
  };

  // converti le nombre de prescriptions traitées en pourcent
  const convertProgressToPercentBar = (value: number, max: number) => {
    const result = parseFloat((value / max).toFixed(2)) * 100;
    return result;
  };

  const convertProgressToPercentBarClassicMulti = (
    value: number,
    max: number
  ) => {
    const result =
      parseFloat("100") - parseFloat((value / max).toFixed(2)) * 100;
    return result;
  };

  // affiche le bouton si la signature Oodrive est activée dans le .ENV
  const diplayBtn =
    config.oodriveSignatureActivated === true ||
    config.signatureClassiqueMultiple
      ? "default"
      : "none";

  return (
    <>
      <ButtonWrapper className={diplayBtn}>
        <PrimaryButton
          as="button"
          size="S"
          label="Signer tous les documents"
          buttonStyle="primary"
          type={selectedIPKPrescriptions.length === 0 ? "button" : "submit"}
          minWidth="200px"
          onClick={() => {
            // Envoi signature en lot
            handleClickRenouvellementMultiple();
            // MODAL de progression
            if (
              config.oodriveSignatureActivated === true &&
              config.signatureClassiqueMultiple === false
            ) {
              setContentModal(
                <ModalProgress
                  sendContent={statusShow}
                  percentProgress={percentProgress}
                  updateStatus={getUpdatedStatus}
                  updateProgress={getUpdatedProgress}
                />
              );
              dispatch(toggleModal());
            } else if (
              config.oodriveSignatureActivated === false &&
              config.signatureClassiqueMultiple === false
            ) {
              setContentModal(
                <ModalProgress
                  sendContent={statusShow}
                  percentProgress={percentProgress}
                  updateStatus={getUpdatedStatus}
                  updateProgress={getUpdatedProgress}
                />
              );
              dispatch(toggleModal());
            }
          }}
          disabled={selectedIPKPrescriptions.length === 0}
        ></PrimaryButton>
      </ButtonWrapper>
    </>
  );
}
